import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'references';

const model = `id
  status
  name
  total
  userCreate {
    fullName
  }
  userUpdate {
    fullName
  }
  updatedAt
  `;

export const ReferenceQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
