import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown } from 'root/components';
import { HPPage } from 'root/components/HPPage';
import { confirmAction, getRole } from 'root/helpers';
import { IUser, Permissions } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../../ProfileStore';
import { ActionTypeUser } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser,
      data: (state: IState) => state.user.data,
      loading: (state: IState) => state.user.loading,
      pagination: (state: IState) => state.user.pagination
    })
  }
})
export class StaffTable extends Vue {
  public data: IUser[];
  public visibleCreateForm: boolean = false;
  public pagination: IPaginationParams;
  public authUser: IUser;
  public listOption: number = 10;
  public listOptions: any[] = [
    {
      value: '10 kết quả mỗi trang',
      key: 10
    },
    {
      value: '20 kết quả mỗi trang',
      key: 20
    },
    {
      value: '50 kết quả mỗi trang',
      key: 50
    }
  ];
  public actionOptions: any[] = [
    {
      value: 'Chi tiết',
      key: 'detail',
      icon: 'el-icon-view'
    },
    {
      value: 'Khoá người dùng',
      key: 'lock',
      icon: 'el-icon-lock'
    },
    {
      value: 'Xóa người dùng',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];
  public actionOptions2: any[] = [
    {
      value: 'Chi tiết',
      key: 'detail',
      icon: 'el-icon-view'
    },
    {
      value: 'Mở khoá người dùng',
      key: 'unlock',
      icon: 'el-icon-unlock'
    },
    {
      value: 'Xóa người dùng',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];

  public roleFormatter(role: string) {

    return getRole(role);
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');

    if (actions[2] === 'filter') {
      this.listOption = parseInt(actions[0], 10);
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          pagingParams: {
            limit: this.listOption,
            page: 1
          }
        }
      });

      return;
    }
    const index = actions[1];
    const id = this.data[index].id;

    switch (actions[0]) {
    case 'detail':
      this.$router.push(`/users/edit/${id}`);
      break;
    case 'lock':
      confirmAction(this, {
        title: 'Khoá người dùng',
        message: `Xác nhận khoá người dùng ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id,
            form: {
              lockedAccess: true
            }
          })
          .then(() => {
            this.$message.success(this.$t('user_locked_successfully').toString());
          })
          .catch(() => {
            this.$message.error(this.$t('cannot_lock_user').toString());
          });
        }
      });
      break;
    case 'unlock':
      confirmAction(this, {
        title: 'Mở khoá người dùng',
        message: `Xác nhận mở khoá người dùng ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id,
            form: {
              lockedAccess: false
            }
          })
          .then(() => {
            this.$message.success(this.$t('user_unlocked_successfully').toString());
          })
          .catch(() => {
            this.$message.error(this.$t('cannot_unlock_user').toString());
          });
        }
      });
      break;
    case 'delete':
      confirmAction(this, {
        title: 'Xoá người dùng',
        message: `Xác nhận xoá người dùng ${this.data[index].fullName}?`,
        handleFunction: () => {
          const profileId = this.data[index].profile;
          this.$store.dispatch(ActionTypeUser.UserDelete, {id})
            .then(() => {
              this.$store.dispatch(ActionTypeProfile.ProfileDelete, {
                id: profileId
              })
              .then(() => {
                this.$message.success(this.$t('user_deleted_successfully').toString());
              })
              .catch(() => {
                this.$message.error(this.$t('cannot_delete_user_profile').toString());
              });
            })
            .catch(() => {
              this.$message.error(this.$t('cannot_delete_user').toString());
            });
        }
      });
      break;
    default:
      break;
    }
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeUser.UserFilterChange, {
        params: {
          _or: [
            { role: { _eq: Permissions.Admin } },
            { role: { _eq: Permissions.Staff } }
          ]
        }
      });
    });
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public sortTable(params: any) {
    switch (params.prop) {
    case 'fullName':
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          orderParams: {
            fullName: params.order === 'descending' ? 'desc' : 'asc'
          }
        }
      });
      break;
    default:
      break;
    }
  }
}
