import gql from 'graphql-tag';
import { hpGqlClient } from 'root/api';
import { HPPublicSidebar } from 'root/components';
import { getFullName } from 'root/helpers';
import { StatusCode } from 'root/models';
import { ActionTypeProfile } from 'root/pages/Users/ProfileStore';
import { ActionTypeUser } from 'root/pages/Users/Store';
import { HPAuth } from 'root/services';
import { MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import './public.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-public-sidebar': HPPublicSidebar
  }
})

export class PublicLayout extends Vue {
  public validated: boolean = false;
  public loading: boolean = false;
  public form = {
    phone: ''
  };
  public validatePhone() {
    this.loading = true;
    this.$store.commit(MutationType.SetToken, this.$route.query.token);
    hpGqlClient.user.validatePhone(`${this.$route.query.token}`, this.form.phone)
      .then((res) => {
        this.loading = false;
        if (res.result === 'failed') {
          this.$message.error(this.$t('invalid_phone').toString());

          return;
        }
        this.validated = true;
        this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
          params: {
            filterParams: {
              publicToken: this.$route.query.token
            },
            onSuccess: (data: any) => {
              this.$store.dispatch(ActionTypeProfile.ProfileFindById, {
                id: data[0].profile,
                onSuccess: (ans) => {
                  document.title = `FE CREDIT - ${getFullName(ans[0])}`;
                },
                opts: {
                  query: gql`
                  query getProfile($id: uuid!) {
                    profiles(where: {id: {_eq: $id}, status: {_neq: "${StatusCode.Deleted}"}}) {
                      id
                      progress
                      avatar
                      firstName
                      middleName
                      lastName
                      email
                      phone
                      dob
                      pob
                      gender
                      maritalStatus
                      nationality
                      ethnic
                      religion
                      homePhone
                      idType
                      idNo
                      idDoi
                      idPoi
                      IdImg
                      oldIdNo
                      oldIdDoi
                      oldIdPoi
                      houseHoldIdNo
                      headOfHHIdNo
                      familyMembers
                      sameAddr
                      tempAddr
                      tCity
                      tDistrict
                      tWard
                      permAddr
                      pCity
                      pDistrict
                      pWard
                      houseHoldImg
                      degrees
                      degreeImg
                      certificates
                      certificateImg
                      workingRelative
                      relativeName
                      relativePosition
                      relativeRelationship
                      pastWorking
                      pastPosition
                      pastStartDate
                      pastEndDate
                      pastReason
                      positionCode
                      academicLevel,
                      localExpat,
                      qualifications,
                      idExpDate,
                      oldIdExpDate,
                      tncn,
                      taxNo,
                      bhxh,
                      bhxhNo,
                      bhxhStatus,
                      bhxhPaperNo
                      healthStatus,
                      hospital,
                      healthImg,
                      dependants,
                      bankName,
                      bankAccNo,
                      bankAcc,
                      positionLevel,
                      urgentContactName,
                      urgentContactRelationship,
                      urgentContactPhone,
                      bornCity,
                      bornDistrict,
                      bornWard,
                      jobs,
                      referrers,
                      checkedTerm1,
                      checkedTerm2
                    }
                  }`
                }
              });
            },
            onFailure: () => {
              this.$message.error(this.$t('connection_failed').toString());
            }
          },
          opts: {
            query: gql`
            query filterUsers($limit: Int!, $offset: Int!, $filter: [users_bool_exp],
              $distinctOn: [users_select_column!],
              $orderParams: [users_order_by!] = { updatedAt: desc }) {
              users(distinct_on: $distinctOn, limit: $limit, offset: $offset,
                where: {status: {_neq: "deleted"}, _and: $filter}, order_by: $orderParams) {
                id
                fullName
                email
                role
                profile
                profileOfUser {
                  firstName
                  middleName
                  lastName
                  phone
                  positionCode
                  startDate
                }
              }
              users_aggregate(distinct_on: $distinctOn, where: {status: {_neq: "deleted"}, _and: $filter}) {
                aggregate {
                  count
                }
              }
            }`
          }
        });
      })
      .catch(() => {
        this.loading = false;
        this.$message.error(this.$t('invalid_token').toString());
      });
  }
  public created() {
    this.$nextTick(() => {
      HPAuth.getLogInState().then((state) => {
        if (!state || state === 'false') {
          return;
        }
        this.validated = true;
        this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
          params: {
            filterParams: {
              publicToken: this.$route.query.token
            },
            onSuccess: (data: any) => {
              this.$store.dispatch(ActionTypeProfile.ProfileFilterNoCache, {
                params: {
                  filterParams: {
                    userId: data[0].id
                  },
                  onSuccess: (res) => {
                    document.title = `FE CREDIT - ${getFullName(res[0])}`;
                  }
                }
              });
            },
            onFailure: () => {
              this.$message.error(this.$t('connection_failed').toString());
            }
          }
        });
      });
    });
  }
}
