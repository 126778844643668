import readXlsxFile from 'read-excel-file';
import { HPPage } from 'root/components';
import { IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeReferenceRecord } from '../../RecordStore';
import { ActionTypeReference } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-page': HPPage
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  }
})

export class ReferenceCreateForm extends Vue {
  public loading: boolean = false;
  public authUser: IUser;
  public type: number = 1;
  public rawFile: any;

  public async submitForm() {
    const dataList: any = [];
    let from = 0;
    switch (this.type) {
    case 1:
      this.loading = true;
      readXlsxFile(this.rawFile).then((rows) => {
        rows.forEach((row, num) => {
          if (num === 0) {
            row.forEach((item, index) => {
              // tslint:disable-next-line: early-exit
              if (index % 2 === 0) {
                dataList.push({
                  name: item,
                  data: []
                });
              }
            });
          } else {
            for (let i = 0; i < row.length; i += 2) {
              // tslint:disable-next-line: early-exit
              if (row[i]) {
                dataList[i / 2].data.push({
                  name: row[i].toString(),
                  code: row[i + 1].toString(),
                  tableName: dataList[i / 2].name,
                  superior: 'None',
                  createdBy: this.authUser.id,
                  updatedBy: this.authUser.id
                });
              }
            }
          }
        });
        dataList.forEach(async (table) => {
          await this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
            params: {
              constraint: 'referenceRecords_name_tableName_superior_key',
              update_columns: 'code'
            },
            form: table.data
          });
          await this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: table.name,
              total: table.data.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            }
          });
        });
        this.loading = false;
      });
      break;
    case 2:
      this.loading = true;
      const cities: any = {};
      const citiData: any[] = [];
      const districts: any = {};
      const districtData: any[] = [];
      const wards: any = {};
      const wardData: any[] = [];
      await readXlsxFile(this.rawFile).then((rows) => {
        for (let i = 1; i < rows.length; ++i) {
          if (!rows[i][10]) {
            continue;
          }
          if (!cities[rows[i][1]]) {
            cities[rows[i][1]] = {
              name: rows[i][2],
              code: rows[i][1],
              tableName: 'Tỉnh / Thành',
              superior: 'None',
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            };
          }
          if (!districts[rows[i][3]]) {
            districts[rows[i][3]] = {
              name: rows[i][4],
              code: rows[i][3],
              tableName: 'Quận / Huyện',
              superior: rows[i][1],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            };
          }
          let count = i;
          while (count < rows.length && rows[count][3] === rows[i][3]) {
            if (rows[i][10]) {
              wards[`${rows[count][1]}${rows[count][3]}${rows[count][5]}`] = {
                name: rows[count][7],
                code: rows[count][5],
                tableName: 'Phường / Xã',
                superior: rows[i][3],
                createdBy: this.authUser.id,
                updatedBy: this.authUser.id
              };
            }
            count++;
          }
          i = count - 1 > i ? count - 1 : i;
        }
      });
      // console.log(cities, districts, wards);
      // console.log(Object.keys(cities).length, Object.keys(districts).length, Object.keys(wards).length);
      for (const key of Object.keys(cities)) {
        citiData.push(cities[key]);
      }
      await this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
        params: {
          constraint: 'referenceRecords_name_tableName_superior_key',
          update_columns: 'code'
        },
        form: citiData,
        onSuccess: (results) => {
          results.forEach((re) => {
            cities[re.code]['id'] = re.id;
          });
        }
      });
      for (const key of Object.keys(districts)) {
        districts[key].superior = cities[districts[key].superior].id;
      }
      for (const key of Object.keys(districts)) {
        districtData.push(districts[key]);
      }
      from = 0;
      while (from < Object.keys(districts).length) {
        const to = from + 199 < Object.keys(districts).length ? from + 200 : Object.keys(districts).length;
        await this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
          params: {
            constraint: 'referenceRecords_name_tableName_superior_key',
            update_columns: 'code'
          },
          form: districtData.slice(from, to),
          onSuccess: (results) => {
            results.forEach((re) => {
              districts[re.code]['id'] = re.id;
            });
          }
        });
        from = to;
      }
      for (const key of Object.keys(wards)) {
        wards[key].superior = districts[wards[key].superior].id;
      }
      for (const key of Object.keys(wards)) {
        wardData.push(wards[key]);
      }
      from = 0;
      while (from < Object.keys(wards).length) {
        const to = from + 199 < Object.keys(wards).length ? from + 200 : Object.keys(wards).length;
        await this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
          params: {
            constraint: 'referenceRecords_name_tableName_superior_key',
            update_columns: 'code'
          },
          form: wardData.slice(from, to)
        });
        from = to;
      }
      await this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
        params: {
          constraint: 'references_name_key',
          update_columns: ['total', 'updatedBy', 'updatedAt']
        },
        form: {
          name: 'Tỉnh / Thành',
          total: Object.keys(cities).length,
          createdBy: this.authUser.id,
          updatedBy: this.authUser.id
        }
      });
      await this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
        params: {
          constraint: 'references_name_key',
          update_columns: ['total', 'updatedBy', 'updatedAt']
        },
        form: {
          name: 'Quận / Huyện',
          total: Object.keys(districts).length,
          createdBy: this.authUser.id,
          updatedBy: this.authUser.id
        }
      });
      await this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
        params: {
          constraint: 'references_name_key',
          update_columns: ['total', 'updatedBy', 'updatedAt']
        },
        form: {
          name: 'Phường / Xã',
          total: Object.keys(wards).length,
          createdBy: this.authUser.id,
          updatedBy: this.authUser.id
        }
      });
      this.loading = false;
      break;
    case 3:
      this.loading = true;
      const tracking: any = {};
      const hrisCompanies: any[] = [];
      const divisions: any[] = [];
      const centers: any[] = [];
      const departments: any[] = [];
      const units: any[] = [];
      const hrisTeams: any[] = [];
      const positions: any[] = [];
      const levels: any[] = [];
      readXlsxFile(this.rawFile).then(async (rows: any[]) => {
        for (let i = 4; i < rows.length; ++i) {
          if (!this.check(rows[i][0])) {
            continue;
          }
          if (this.check(rows[i][18]) && !tracking[`company_${rows[i][18]}`]) {
            tracking[`company_${rows[i][18]}`] = 'checked';
            hrisCompanies.push({
              name: rows[i][1],
              code: rows[i][18].toString(),
              tableName: rows[2][18],
              superior: 'None',
              note: rows[i][2],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          if (this.check(rows[i][19]) && !tracking[`division_${rows[i][19]}`]) {
            tracking[`division_${rows[i][19]}`] = 'checked';
            divisions.push({
              name: rows[i][3],
              code: rows[i][19].toString(),
              tableName: rows[2][19],
              superior: rows[i][1],
              note: rows[i][4],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          const centerSuperior = this.check(rows[i][19]) ? rows[i][3] : rows[i][1];
          if (this.check(rows[i][20]) && !tracking[`center_${rows[i][5]}_${centerSuperior}`]) {
            tracking[`center_${rows[i][5]}_${centerSuperior}`] = 'checked';
            centers.push({
              name: rows[i][5],
              code: rows[i][20].toString(),
              tableName: rows[2][20],
              superior: centerSuperior,
              note: rows[i][6],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          const departmentSuperior = this.check(rows[i][20]) ? rows[i][5] :
            (this.check(rows[i][19]) ? rows[i][3] : rows[i][1]);
          if (this.check(rows[i][21]) && !tracking[`department_${rows[i][7]}_${departmentSuperior}`]) {
            tracking[`department_${rows[i][7]}_${departmentSuperior}`] = 'checked';
            departments.push({
              name: rows[i][7],
              code: rows[i][21].toString(),
              tableName: rows[2][21],
              superior: departmentSuperior,
              note: rows[i][8],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          const unitSuperior = this.check(rows[i][21]) ? rows[i][7] :
            (this.check(rows[i][20]) ? rows[i][5] : (this.check(rows[i][19]) ?
            rows[i][3] : rows[i][1]));
          if (this.check(rows[i][22]) && !tracking[`unit_${rows[i][9]}_${unitSuperior}`]) {
            tracking[`unit_${rows[i][9]}_${unitSuperior}`] = 'checked';
            units.push({
              name: rows[i][9],
              code: rows[i][22].toString(),
              tableName: rows[2][22],
              superior: unitSuperior,
              note: rows[i][10],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          const teamSuperior = this.check(rows[i][22]) ? rows[i][9] :
            (this.check(rows[i][21]) ? rows[i][7] : (this.check(rows[i][20]) ?
            rows[i][5] : (this.check(rows[i][19]) ? rows[i][3] : rows[i][1])));
          if (this.check(rows[i][23]) && !tracking[`team_${rows[i][13]}_${teamSuperior}`]) {
            tracking[`team_${rows[i][13]}_${teamSuperior}`] = 'checked';
            hrisTeams.push({
              name: rows[i][13],
              code: rows[i][23].toString(),
              tableName: rows[2][23],
              superior: teamSuperior,
              note: rows[i][14],
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          const levelSuperior = this.check(rows[i][23]) ? rows[i][13] : (this.check(rows[i][22]) ?
            rows[i][9] : (this.check(rows[i][21]) ? rows[i][7] : (this.check(rows[i][20]) ?
            rows[i][5] : (this.check(rows[i][19]) ? rows[i][3] : rows[i][1]))));
          if (this.check(rows[i][25]) && !tracking[`level_${rows[i][25]}_${levelSuperior}`]) {
            tracking[`level_${rows[i][25]}_${levelSuperior}`] = 'checked';
            levels.push({
              name: rows[i][25],
              code: rows[i][25].toString(),
              tableName: 'Level',
              superior: levelSuperior,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            });
          }
          if (!this.check(rows[i][24]) || tracking[`position_${rows[i][15]}_${rows[i][25]}_${levelSuperior}`]) {
            continue;
          }
          tracking[`position_${rows[i][15]}_${rows[i][25]}_${levelSuperior}`] = 'checked';
          positions.push({
            name: rows[i][15],
            code: rows[i][24].toString(),
            tableName: rows[2][24],
            superior: `${rows[i][25]}_${levelSuperior}`,
            note: `${rows[i][26].toString()}_${rows[i][16]}`,
            createdBy: this.authUser.id,
            updatedBy: this.authUser.id
          });
        }
        console.log(hrisTeams.length, levels.length, positions.length);
        await this.uploadForm(hrisCompanies);
        await this.uploadForm(divisions);
        await this.uploadForm(centers);
        await this.uploadForm(departments);
        from = 0;
        while (from < units.length) {
          const to = from + 199 < units.length ? from + 200 : units.length;
          await this.uploadForm(units.slice(from, to));
          from = to;
        }
        from = 0;
        while (from < hrisTeams.length) {
          const to = from + 199 < hrisTeams.length ? from + 200 : hrisTeams.length;
          await this.uploadForm(hrisTeams.slice(from, to));
          from = to;
        }
        from = 0;
        while (from < levels.length) {
          const to = from + 199 < levels.length ? from + 200 : levels.length;
          await this.uploadForm(levels.slice(from, to));
          from = to;
        }
        from = 0;
        while (from < positions.length) {
          const to = from + 199 < positions.length ? from + 200 : positions.length;
          await this.uploadForm(positions.slice(from, to));
          from = to;
        }
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][18],
              total: hrisCompanies.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][19],
              total: divisions.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][20],
              total: centers.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][21],
              total: departments.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][22],
              total: units.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][23],
              total: hrisTeams.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: rows[2][24],
              total: positions.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        await new Promise((resolve) => {
          this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
            params: {
              constraint: 'references_name_key',
              update_columns: ['total', 'updatedBy', 'updatedAt']
            },
            form: {
              name: 'MÃ CHỨC VỤ',
              total: levels.length,
              createdBy: this.authUser.id,
              updatedBy: this.authUser.id
            },
            onSuccess: () => {
              resolve();
            }
          });
        });
        this.loading = false;
      });
      break;
    case 4:
      this.loading = true;
      const bonusTypes: any[] = [];
      readXlsxFile(this.rawFile).then(async (rows: any[]) => {
        for (let i = 1; i < rows.length; ++i) {
          bonusTypes.push({
            name: rows[i][3],
            code: rows[i][1],
            tableName: rows[0][1],
            superior: rows[i][5],
            createdBy: this.authUser.id,
            updatedBy: this.authUser.id
          });
        }
        await this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
          params: {
            constraint: 'referenceRecords_name_tableName_superior_key',
            update_columns: 'code'
          },
          form: bonusTypes
        });
        await this.$store.dispatch(ActionTypeReference.ReferenceUpsert, {
          params: {
            constraint: 'references_name_key',
            update_columns: ['total', 'updatedBy', 'updatedAt']
          },
          form: {
            name: 'MÃ KHOẢN THÊM LƯƠNG',
            total: bonusTypes.length,
            createdBy: this.authUser.id,
            updatedBy: this.authUser.id
          }
        });
        this.loading = false;
      });
      break;
    default:
      break;
    }
  }

  public cancelForm() {
    this.$router.push('/users');
  }

  public beforeRemove(file: any) {
    return this.$confirm(`Hủy chọn file ${ file.name }?`);
  }

  public onChange(file: any) {
    this.rawFile = file.raw;
  }

  private async uploadForm(input: any) {
    await new Promise((resolve) => {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordUpsertMany, {
        params: {
          constraint: 'referenceRecords_name_tableName_superior_key',
          update_columns: ['code', 'note']
        },
        form: input,
        onSuccess: () => {
          resolve();
        }
      });
    });
  }

  private check(str: string) {
    return (str && str !== '*' && str !== '#N/A' && str !== '000'
      && str !== '0000' && str !== '00000' && str !== '000000');
  }
}
