
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/vi';
// import { initNotification } from 'root/services';
import Vue from 'vue';
// import VueCookies from 'vue-cookies';
import VueLogger from 'vuejs-logger';
import { mapState } from 'vuex';
import { sync } from 'vuex-router-sync';
import { i18n } from './locales';

// layouts
import {
  HomeLayout,
  LoginLayout,
  PublicLayout
} from './layouts';

// components
import {
  SplashScreenComponent, TopAlertComponent,
} from './components';

// import { cmaHttpClient } from './api/http';
import { configLogger } from './helpers';
import router from './router';
// import { SFRAuth } from './services';
import store, { ActionType, IState, Layout } from './store';

// import IdleVue from 'idle-vue';
// const eventsHub = new Vue();
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   idleTime: 900000
// });

sync(store, router);

// Vue.use(VueCookies);
Vue.use(ElementUI, { locale });

import VueLazyload, { VueLazyloadOptions } from 'vue-lazyload';
const optionLazy: VueLazyloadOptions = {
  listenEvents: ['scroll'],
  observer: true,
  dispatchEvent: true,
  silent: true,
  preLoad: 0.1,
  error: '/assets/img/image-placeholder.svg',
  loading: '/assets/img/image-placeholder.svg'
};
Vue.use(VueLazyload, optionLazy);
// import scss
import './main.scss';
import { HPAuth } from './services';
// import { SFRAuth } from './services';

Vue.use(<any> VueLogger, configLogger());

// tslint:disable
new Vue({
  el: '#app',
  router,
  store,
  i18n: i18n(),
  async created() {
    this.$store.dispatch(ActionType.InitializeSettings);
    this.$root._i18n.locale = 'vi';

    // SFRAuth.onAuthStateChanged(user => {
    //   this.$store.dispatch(ActionType.ChangeStateAuth, { router: this.$router, user });
    // });

    // cmaHttpClient.global.getConfiguration().then(data => {
    //   this.$store.dispatch(ActionType.SetGlobalConfig, data);
    // });
    // if (!this.$cookies.get("session") && router.currentRoute.path !== '/public') {
    //   router.replace(RouteDictionary.Login);
    //   HPAuth.logOut();
    //   return;
    // }
    await HPAuth.getAccessToken()
      .then(async (accessToken: string) => {
        await this.$store.dispatch(ActionType.ChangeStateAuth, { accessToken, router: this.$router });
      });

  },
  computed: {
    layout() {
      return this.$route.meta.layout || Layout.Login;
    },
    ...mapState({
      // layout: (state: IState) => {
      //   return state.global.layout;
      // },
      // isLoading: (state: IState) => {
      //   return state.global.isLoading;
      // },
      topAlertOpened: (state: IState) => {
        const topAlert = state.global.topAlert;
        if (topAlert.opened) {
          return topAlert;
        }
        return false;
      }
    })
  },
  components: {
    'home-layout': HomeLayout,
    'login-layout': LoginLayout,
    'public-layout' : PublicLayout,
    'splash-screen': SplashScreenComponent,
    'top-alert': TopAlertComponent
  }
});

