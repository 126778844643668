import { hpGqlClient } from 'root/api';
import { crudActions } from 'root/store/helpers';
import { ReferenceRecordQuery } from './query';

const { create, filter, fetchMany, getById, update, createMany, upsert, upsertMany } = hpGqlClient.referenceRecord;

export const actions = {
  ...crudActions('referenceRecord', {
    create, filter, fetchMany, getById, update, createMany, upsert, upsertMany
  }, {
    queries: ReferenceRecordQuery,
  }),
};
