import axios from 'axios';
import { hpGqlClient } from 'root/api';
import { getFullName } from 'root/helpers';
import { IProfile } from 'root/models';
import { getFirebaseApp } from 'root/services';
import { IState } from 'root/store';
import { saveAs } from 'save-as';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.profile.data[0]
    })
  },
})
export class ProfileDecisionViewer extends Vue {
  public data: IProfile;
  public loading: boolean = false;

  public createDecision() {
    this.loading = true;
    this.data.decisionDocx = '';
    this.data.decisionPdf = '';
    this.$message.success(this.$t('creating_decision').toString());
    hpGqlClient.user.generateDecision(this.$route.params.id)
      .then((res) => {
        this.data.decisionDocx = res.docx;
        this.data.decisionPdf = res.pdf;
        this.$message.success(this.$t('candidate_decision_created_successfully').toString());
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.$message.error(this.$t('error_creating_decision').toString());
        this.loading = false;
      });
  }

  public async download(url: string) {
    const metadata = await getFirebaseApp().storage().refFromURL(url).getMetadata();
    axios({
      url,
      method: 'get',
      responseType: 'arraybuffer'
    })
    .then((response) => {
      saveAs(new Blob([response.data]), `${getFullName(this.data, '_')}_${metadata.name}`);
    })
    .catch(() => this.$message.error(this.$t('something_went_wrong').toString()));
  }
}
