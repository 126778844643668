import { ISignatory } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface ISignatoryGqlService
  extends ICRUDGqlService<ISignatory> {
    //
}
export function signatoryGqlService(): ISignatoryGqlService {
  return {
    ...CRUDGqlService<ISignatory>('signatories'),
  };
}
