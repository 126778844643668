import { hpGqlClient } from 'root/api';
import { ruleRequired } from 'root/helpers';
import { ReferenceTableName } from 'root/models';
import { contractTemplateFormDefault, IContractTemplate } from 'root/models/Contract';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { getFirebaseApp, uploadToFirebase } from 'root/services';
import { IState } from 'root/store';
// import saveAs from 'save-as';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeContractTemplate } from '../../Store';
import { ShortcutTable } from '../ContractManager/ShortcutTable';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'shortcut-table': ShortcutTable
  },
  beforeRouteLeave(_, __, next) {
    if (this.noWarning) {
      window.removeEventListener('beforeunload', this.listener);
      next();

      return;
    }
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  }
})

export class ContractCreateForm extends Vue {
  public loading: boolean = false;
  public noWarning: boolean = false;
  public openForm: boolean = false;
  public authUser: any;
  public form: IContractTemplate = contractTemplateFormDefault();
  public storageRef = getFirebaseApp().storage().ref();
  public oldContent: string = '';
  public oldDocFile: string = '';
  public label: any = {
    contract: 'TÊN HỢP ĐỒNG',
    decision: 'TÊN QUYẾT ĐỊNH',
    commitment: 'TÊN CAM KẾT'
  };
  public $refs: {
    editable: any,
    toolbar: any
  };
  public editorConfig = {
    fullPage: true,
    allowedContent: true,
    language: 'vi',
    extraPlugins: 'font, find'
  };
  public tcOptions = [
    {
      label: 'test template',
      value: 'test'
    },
    {
      label: 'a very longggggggggg and longgggg option name',
      value: 'test1'
    }
  ];
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };
  public get rules() {
    return {
      required: ruleRequired()
    };
  }
  public referenceGroups: any = {
    [ReferenceTableName.ContractTemplate]: 'contractOptions'
  };
  public contractOptions: any[] = [];

  public mounted() {
    this.$nextTick(async () => {
      window.addEventListener('beforeunload', this.listener);
      if (this.$route.path !== '/contracts/new/template') {
        await this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateFindById, {
          id: this.$route.params.id,
          onSuccess: (data) => {
            if (!data[0]) {
              return;
            }
            const { name, typeId, note, content, docFile, paperType } = data[0];
            this.form = {
              name,
              typeId,
              note,
              content,
              docFile,
              paperType
            };
          }
        });
      }
      const filter = [];
      Object.keys(this.referenceGroups).forEach((key) => {
        filter.push({
          tableName: { _eq: key }
        });
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: filter
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this[`${this.referenceGroups[record.tableName]}`].push({
                label: record.code,
                value: record.id
              });
            });
          }
        }
      });
    });
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public cancelForm() {
    if (!this.form.content) {
      this.$router.push('/contracts');
    }
    getFirebaseApp().storage().refFromURL(this.form.content).delete()
      .then(() => {
        getFirebaseApp().storage().refFromURL(this.form.docFile).delete()
          .then(() => {
            this.$router.push('/contracts');
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  public submitForm() {
    for (const key of Object.keys(this.form)) {
      if (this.form[key] || key === 'note' || key === 'typeId' && this.form.paperType !== 'contract') {
        continue;
      }
      this.$set(this.form, key, '');
      this.$message.error(this.$t('required_fields_cannot_be_empty').toString());

      return;
    }
    if (this.$route.path === '/contracts/new/template') {
      this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateCreate, {
        form: {
          ...this.form,
          updatedBy: this.authUser.id
        },
        onSuccess: () => {
          this.$message.success(this.$t('contract_created_successfully').toString());
          this.noWarning = true;
          this.$router.push('/contracts');
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    } else {
      this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateUpdate, {
        id: this.$route.params.id,
        form: {
          ...this.form
        },
        onSuccess: () => {
          this.$message.success(this.$t('contract_updated_successfully').toString());
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    }
  }

  public deleteTemplates() {
    getFirebaseApp().storage().refFromURL(this.form.content).delete()
      .then(() => {
        this.form.content = '';
        getFirebaseApp().storage().refFromURL(this.form.docFile).delete()
          .then(() => {
            this.form.docFile = '';
            this.$message.success(this.$t('template_deleted_successfully').toString());
          })
          .catch((error) => {
            this.$message.error(this.$t(error.message.substring(15)).toString());
          });
      })
      .catch((err) => {
        this.$message.error(this.$t(err.message.substring(15)).toString());
      });
  }

  public async onChange(file: any, fileList: any[]) {
    this.$message.success(this.$t('file_being_uploaded').toString());
    fileList[0] = file;
    fileList.pop();
    try {
      this.form.docFile = await uploadToFirebase(file.raw, 'contract-templates/', null);
      const metadata = await getFirebaseApp().storage().refFromURL(this.form.docFile).getMetadata();
      this.form.content = await hpGqlClient.contractTemplate.generatePdf(this.form.docFile, metadata.name);
      this.$message.success(this.$t('file_uploaded_successfully').toString());
    } catch (error) {
      this.$message.error(this.$t(error.message.substring(15)).toString());
    }
  }
}
