import { IReference } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IReferenceState extends ICRUDState<IReference> {
  loading: boolean;
}

export const defaultState: IReferenceState = {
  ...CRUDState(),
  loading: false
};

export const mutations = {
  ...crudMutations('reference')
};
