export const enum EmployeeStatus {
  Active = 'active',
  New = 'new',
  Rejected = 'rejected',
  Quit = 'quit',
  Rehire = 'rehire'
}
export interface IPublicCandidateForm {
  firstName: string;
  middleName: string;
  lastName: string;
  avatar: string;
  email: string;
  phone: string;
  dob: Date;
  pob: string;
  gender: string;
  maritalStatus: string;
  nationality: string;
  ethnic: string;
  religion: string;
  homePhone: string;
  idType: string;
  idNo: string;
  idDoi: Date;
  idPoi: string;
  IdImg: string[];
  oldIdNo: string;
  oldIdDoi: Date;
  oldIdPoi: string;
  houseHoldIdNo: string;
  headOfHHIdNo: string;
  familyMembers: IMember[];
  sameAddr: boolean;
  tempAddr: string;
  tCity: string;
  tDistrict: string;
  tWard: string;
  permAddr: string;
  pCity: string;
  pDistrict: string;
  pWard: string;
  houseHoldImg: string[];
  degrees: IDegree[];
  degreeImg: string[];
  certificates: ICertificate[];
  certificateImg: string[];
  workingRelative: boolean;
  relativeName: string;
  relativePosition: string;
  relativeRelationship: string;
  pastWorking: boolean;
  pastPosition: string;
  pastStartDate: Date;
  pastEndDate: Date;
  pastReason: string;
  academicLevel: string;
  localExpat: string;
  qualifications: string;
  idExpDate: string;
  oldIdExpDate: string;
  tncn: boolean;
  taxNo: string;
  bhxh: boolean;
  bhxhNo: string;
  bhxhStatus: string;
  healthStatus: string;
  hospital: string;
  healthImg: string[];
  dependants: boolean;
  bankName: string;
  bankAccNo: string;
  bankAcc: boolean;
  progress: IProgress;
  urgentContactName: string;
  urgentContactRelationship: string;
  urgentContactPhone: string;
  bornCity: string;
  bornDistrict: string;
  bornWard: string;
  jobs: IJob[];
  referrers: IReferrer[];
  positionLevel: string;
  bhxhPaperNo: string;
  checkedTerm1: boolean;
  checkedTerm2: boolean;
}

export const publicCandidateFormDefault = (): IPublicCandidateForm => {
  return {
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    avatar: null,
    dob: null,
    pob: null,
    gender: null,
    maritalStatus: null,
    nationality: null,
    ethnic: null,
    religion: null,
    homePhone: null,
    idType: 'CMND',
    idNo: null,
    idDoi: null,
    idPoi: null,
    IdImg: [],
    oldIdNo: null,
    oldIdDoi: null,
    oldIdPoi: null,
    houseHoldIdNo: null,
    headOfHHIdNo: null,
    familyMembers: [],
    sameAddr: false,
    tempAddr: null,
    tCity: null,
    tDistrict: null,
    tWard: null,
    permAddr: null,
    pCity: null,
    pDistrict: null,
    pWard: null,
    houseHoldImg: [],
    degrees: [],
    degreeImg: [],
    certificates: [],
    certificateImg: [],
    workingRelative: false,
    relativeName: null,
    relativePosition: null,
    relativeRelationship: null,
    pastWorking: false,
    pastPosition: null,
    pastStartDate: null,
    pastEndDate: null,
    pastReason: null,
    academicLevel: null,
    localExpat: null,
    qualifications: null,
    idExpDate: null,
    oldIdExpDate: null,
    tncn: false,
    taxNo: null,
    bhxh: false,
    bhxhNo: null,
    bhxhStatus: null,
    healthStatus: null,
    hospital: null,
    healthImg: [],
    dependants: false,
    bankName: null,
    bankAccNo: null,
    bankAcc: false,
    progress: {
      personalInfo: false,
      workingPosition: false,
      contract: false,
      salary: false,
      bonus: false,
      hrisImported: false,
      contractSigned: false
    },
    urgentContactName: null,
    urgentContactRelationship: null,
    urgentContactPhone: null,
    bornCity: null,
    bornDistrict: null,
    bornWard: null,
    jobs: [],
    referrers: [],
    positionLevel: null,
    bhxhPaperNo: null,
    checkedTerm1: false,
    checkedTerm2: false
  };
};

export interface IStaffForm {
  fullName: string;
  email: string;
  password: string;
  phone: string;
  role: string;
  department: string;
  unit: string;
  team: string;
}

export const staffFormDefault = (): IStaffForm => {
  return {
    fullName: null,
    email: null,
    password: '123456', // Math.random().toString(36).slice(-8),
    phone: null,
    role: null,
    department: null,
    unit: null,
    team: null
  };
};

export interface ICandidateForm {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  startDate: string;
  company: string;
  division: string;
  center: string;
  department: string;
  unit: string;
  team: string;
  positionCode: string;
  positionLevel: string;
  contactPerson: string;
  contactNumber: string;
  startLocation: string;
  workingLocation: string;
  startTime: string;
  emailCc: string[];
}

export const candidateFormDefault = (): ICandidateForm => {
  return {
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    startDate: null,
    password: '123456',
    company: null,
    division: null,
    center: null,
    department: null,
    unit: null,
    team: null,
    positionCode: null,
    positionLevel: null,
    contactPerson: null,
    contactNumber: null,
    startLocation: null,
    workingLocation: null,
    startTime: null,
    emailCc: []
  };
};

export interface IChangePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
}

export const changePasswordFormDefault = (): IChangePasswordForm => {
  return {
    oldPassword: null,
    newPassword: null,
    confirmedPassword: null
  };
};

export interface IWorkingPositionForm {
  startDate: string;
  changeType: string;
  company: string;
  division: string;
  center: string;
  department: string;
  unit: string;
  team: string;
  pow: string;
  positionCode: string;
  employeeType: string;
  paperIssued: boolean;
  positionLevel: string;
}

export const workingPositionFormDefault = (): IWorkingPositionForm => {
  return {
    startDate: null,
    changeType: null,
    company: null,
    division: null,
    center: null,
    department: null,
    unit: null,
    team: null,
    pow: null,
    positionCode: null,
    employeeType: null,
    paperIssued: false,
    positionLevel: null
  };
};

export interface IContractForm {
  contractType: string;
  contractNo: string;
  effectiveDate: Date;
  expiryDate: Date;
  dop: Date;
  probationPeriod: number;
  eop: Date;
  workingTime: string;
  signerName: string;
  signerIdNo: string;
  signerIdDoi: Date;
  signerIdPoi: string;
  signerPosition: string;
  signerDob: Date;
  signerPAddr: string;
  authLetter: string;
  positionLevel: string;
  contractTemplate: string;
}

export const contractFormDefault = (): IContractForm => {
  return {
    contractType: null,
    contractNo: null,
    effectiveDate: null,
    expiryDate: null,
    dop: null,
    probationPeriod: null,
    eop: null,
    workingTime: null,
    signerName: null,
    signerIdNo: null,
    signerIdDoi: null,
    signerIdPoi: null,
    signerPosition: null,
    signerDob: null,
    signerPAddr: null,
    authLetter: null,
    positionLevel: null,
    contractTemplate: null
  };
};

export interface ISalaryForm {
  effDate: Date;
  actualDate: Date;
  salaryUnit: string;
  isGrossSalary: boolean;
  salaryTotal: string;
  salaryCurrency: string;
  salaryHrisTotal: string;
  salaryHrisBase: string;
  bonusMax: string;
  salaryNote: string;
  positionCode: string;
}

export const salaryFormDefault = (): ISalaryForm => {
  return {
    effDate: null,
    actualDate: null,
    salaryUnit: null,
    isGrossSalary: true,
    salaryTotal: null,
    salaryCurrency: null,
    salaryHrisTotal: null,
    salaryHrisBase: null,
    bonusMax: null,
    salaryNote: null,
    positionCode: null
  };
};

export interface IBonusForm {
  bonuses: IBonus[]; // Tro cap - phu cap
  positionCode: string;
  salaryTotal: string;
}

export const bonusFormDefault = (): IBonusForm => {
  return {
    bonuses: [],
    salaryTotal: null,
    positionCode: null
  };
};

export interface IProgress {
  personalInfo: boolean;
  workingPosition: boolean;
  contract: boolean;
  salary: boolean;
  bonus: boolean;
  hrisImported: boolean;
  contractSigned: boolean;
}

export interface IMember {
  familyHead: boolean;
  fullName: string; // Ho va ten
  relationship: string; // Quan he
  gender: string; // Gioi tinh
  phone: string; // So dien thoai
  bhxhNo: string; // Ma so BHXH
  idNo: string; // Ma so giay to
  dob: Date; // Ngay sinh
  pob: string; // Noi cap giay khai sinh
  idExpDate: string; // Ngay het han
  profession: string; // Nghe nghiep
}

export interface IDegree {
  startDate: Date; // Tu ngay
  endDate: Date; // Den ngay
  organization: string; // Ten truong/don vi dao tao
  degree: string; // Bang cap/hinh thuc dao tao
  level: string; // Trinh do chuyen mon
  major: string; // Chuyen nganh
}

export interface ICertificate {
  startDate: Date; // Tu ngay
  endDate: Date; // Den ngay
  name: string; // Ten chung chi
  organization: string; // Don vi cap
}

export interface IBonus {
  group: string; // Nhom khoan them
  signDate: Date; // Ngay ky
  expiryDate: Date; // Ngay den han
  effectiveDate: Date; // Ngay hieu luc
  type: string; // Loai tro cap - phu cap
  value: string; // Gia tri tro cap
  currency: string; // Don vi
}

export interface IJob {
  startDate: Date;
  endDate: Date;
  companyName: string;
  position: string;
  income: string;
  reason: string;
  responsibility: string;
}

export interface IReferrer {
  name: string;
  position: string;
  pow: string;
  contact: string;
  relationship: string;
}

export interface IProfile {
  id: string;
  userId: string;
  progress: IProgress;
  avatar: string; // Anh dai dien
  company: string; // Cong ty
  division: string; // Khoi
  center: string; // Trung tam
  department: string; // Phong
  unit: string; // Bo phan
  team: string; // Nhom
  firstName: string; // Ten goi
  email: string; // Email
  phone: string; // Dien thoai
  dob: Date; // Ngay sinh
  pob: string; // Noi sinh
  gender: string; // Gioi tinh
  maritalStatus: string; // Tinh trang hon nhan
  nationality: string; // Quoc tich
  ethnic: string; // Dan toc
  religion: string; // Ton giao
  homePhone: string; // Dien thoai nha
  idType: string; // Loai giay to
  idNo: string; // CMND so
  idDoi: Date; // Ngay cap CMND
  idPoi: string; // Noi cap CMND text
  IdImg: string[]; // Hinh anh CMND
  oldIdNo: string;
  oldIdDoi: Date;
  oldIdPoi: string;
  houseHoldIdNo: string; // Ma so ho khau
  headOfHHIdNo: string; // So so chu ho
  familyMembers: IMember[]; // Thanh vien gia dinh
  sameAddr: boolean; // Sao chep Dia chi tam tru = thuong tru
  tempAddr: string; // Dia chi tam tru
  tCity: string; // Tinh/TP tam tru
  tDistrict: string; // Quan/Huyen tam tru
  tWard: string; // Phuong/Xa tam tru
  permAddr: string; // Dia chi thuong tru
  pCity: string; // Tinh/TP thuong tru
  pDistrict: string; // Quan/Huyen thuong tru
  pWard: string; // Phuong/Xa thuong tru
  houseHoldImg: string[]; // Hinh anh ho khau
  degrees: IDegree[]; // Bang cap
  degreeImg: string[]; // Hinh anh bang cap
  certificates: ICertificate[]; // Chung chi
  certificateImg: string[]; // Hinh anh chung chi
  workingRelative: boolean; // Nguoi than dang lam viec
  relativeName: string; // Ten nguoi than
  relativePosition: string; // Chuc danh nguoi than
  relativeRelationship: string; // Quan he nguoi than
  pastWorking: boolean; // Tung lam viec
  pastPosition: string; // Vi tri tung lam
  pastStartDate: Date; // Thoi gian bat dau
  pastEndDate: Date; // Thoi gian nghi viec
  pastReason: string; // Ly do nghi viec
  positionCode: string; // Ma chuc vu
  startDate: Date; // Tu ngay
  changeType: string; // Hinh thuc thay doi
  paperIssued: boolean; // Co quyet dinh
  pow: string; // Noi lam viec
  positionName: string; // Chuc danh
  employeeType: string; // Loai nhan vien
  contractType: string; // Loai hop dong
  contractNo: string; // So hop dong
  effectiveDate: Date; // Ngay hieu luc
  expiryDate: Date; // Ngay het hieu luc
  dop: Date; // Thu viec tu ngay
  probationPeriod: number; // So ngay thu viec
  eop: Date; // Thu viec den ngay
  workingTime: string; // Thoi gian lam viec
  signerName: string; // Ten nguoi ky
  signerIdNo: string; // So CMND nguoi ky
  signerIdDoi: Date; // Ngay cap CMND nguoi ky
  signerIdPoi: string; // Noi cap CMND nguoi ky
  signerPosition: string; // Chuc danh nguoi ky
  signerDob: Date; // Noi sinh nguoi ky
  signerPAddr: string; // Dia chi thuong tru nguoi ky
  authLetter: string; // Theo giay uy quyen
  signDate: Date; // Ngay ky
  salaryUnit: string; // Don vi tinh luong
  isGrossSalary: string; // Luong gross
  salaryTotal: string; // Tong luong
  salaryCurrency: string; // Loai tien te
  salaryHrisTotal: string; // Tong luong HRIS
  salaryHrisBase: string; // Luong co ban HRIS
  bonusMax: string; // Max cua thuong bo sung
  salaryNote: string; // Ghi chu luong
  bonuses: IBonus[]; // Tro cap - phu cap
  academicLevel: string; // Trinh do hoc van
  localExpat: string; // Local / Expat
  qualifications: string; // Trinh do chuyen mon
  idExpDate: Date; // Ngay het han CMND/CCCD
  oldIdExpDate: string; // Ngay het han CMND/CCCD cu
  tncn: boolean; // Da dang ky thue thu nhap ca nhan
  taxNo: string; // Ma so thue
  bhxh: boolean; // Da co bao hiem xa hoi
  bhxhNo: string; // Ma so BHXH
  bhxhStatus: string; // Trang thai BHXH
  healthStatus: string; // Thong tin suc khoe
  hospital: string; // Nguyen quan
  healthImg: string[]; // Hinh anh giay kham suc khoe
  dependants: boolean; // Co nguoi phu thuoc
  bankName: string; // Ten ngan hang
  bankAccNo: string; // So tai khoan
  bankAcc: boolean; // Da co tai khoan ngan hang
  workingLocation: string; // Dia diem tiep nhan
  middleName: string; // Ten dem
  lastName: string; // Ho
  positionLevel: string; // Chuc vu
  directManager: string; // Quan ly truc tiep
  urgentContactName: string; // Ho va ten nguoi lien he khan cap
  urgentContactRelationship: string; // Moi lien he voi nguoi lien he khan cap
  urgentContactPhone: string; // So lien he nguoi lien he khan cap
  bornCity: string; // Noi khai sinh tinh/ thanh pho
  bornDistrict: string; // Noi khai sinh quan/ huyen
  bornWard: string; // Noi khai sinh phuong/ xa
  jobs: IJob[]; // Qua trinh lam viec
  referrers: IReferrer[]; // Nguoi tham chieu
  bhxhPaperNo: string; // So so BHXH
  issuePaperNo: string; // Ma so quyet dinh
  contactNumber: string; // So dien thoai lien he
  startLocation: string; // Noi lam viec - tao ung vien
  actualDate: string;
  effDate: string;
  startTime: Date; // Ngay gio nhan viec
  emailCc: string[];
  employeeStatus: string; // Trang thai nhan vien
  oldProfile: string; // Lien ket ho so cu
  contractDocx: string; // Hop dong file docx
  contractPdf: string; // Hop dong file pdf
  decisionDocx: string; // Quyet dinh file docx
  decisionPdf: string; // Quyet dinh file pdf
  commitmentDocx: string; // Cam ket file docx
  commitmentPdf: string; // Cam ket file pdf
  contractTemplate: string;
  checkedTerm1: boolean;
  checkedTerm2: boolean;
}

export const profileFormDefault = (): IProfile => {
  return {
    id: null,
    userId: null,
    progress: {
      personalInfo: false,
      workingPosition: false,
      contract: false,
      salary: false,
      bonus: false,
      hrisImported: false,
      contractSigned: false
    },
    avatar: null,
    company: null,
    division: null,
    center: null,
    department: null,
    unit: null,
    team: null,
    firstName: null,
    email: null,
    phone: null,
    dob: null,
    pob: null,
    gender: null,
    maritalStatus: null,
    nationality: null,
    ethnic: null,
    religion: null,
    homePhone: null,
    idType: null,
    idNo: null,
    idDoi: null,
    idPoi: null,
    IdImg: [],
    oldIdNo: null,
    oldIdDoi: null,
    oldIdPoi: null,
    houseHoldIdNo: null,
    headOfHHIdNo: null,
    familyMembers: [],
    sameAddr: false,
    tempAddr: null,
    tCity: null,
    tDistrict: null,
    tWard: null,
    permAddr: null,
    pCity: null,
    pDistrict: null,
    pWard: null,
    houseHoldImg: [],
    degrees: [],
    degreeImg: [],
    certificates: [],
    certificateImg: [],
    workingRelative: false,
    relativeName: null,
    relativePosition: null,
    relativeRelationship: null,
    pastWorking: false,
    pastPosition: null,
    pastStartDate: null,
    pastEndDate: null,
    pastReason: null,
    positionCode: null,
    startDate: null,
    changeType: null,
    paperIssued: true,
    pow: null,
    positionName: null,
    employeeType: null,
    contractType: null,
    contractNo: null,
    effectiveDate: null,
    expiryDate: null,
    dop: null,
    probationPeriod: 30,
    eop: null,
    workingTime: null,
    signerName: null,
    signerIdNo: null,
    signerIdDoi: null,
    signerIdPoi: null,
    signerPosition: null,
    signerDob: null,
    signerPAddr: null,
    authLetter: null,
    signDate: null,
    salaryUnit: null,
    isGrossSalary: 'true',
    salaryTotal: null,
    salaryCurrency: null,
    salaryHrisTotal: null,
    salaryHrisBase: null,
    bonusMax: null,
    salaryNote: null,
    bonuses: [],
    academicLevel: null,
    localExpat: null,
    qualifications: null,
    idExpDate: null,
    oldIdExpDate: null,
    tncn: false,
    taxNo: null,
    bhxh: false,
    bhxhNo: null,
    bhxhStatus: null,
    healthStatus: null,
    hospital: null,
    healthImg: [],
    dependants: false,
    bankName: null,
    bankAccNo: null,
    bankAcc: false,
    workingLocation: null,
    middleName: null,
    lastName: null,
    positionLevel: null,
    directManager: null,
    urgentContactName: null,
    urgentContactRelationship: null,
    urgentContactPhone: null,
    bornCity: null,
    bornDistrict: null,
    bornWard: null,
    jobs: [],
    referrers: [],
    bhxhPaperNo: null,
    issuePaperNo: null,
    contactNumber: null,
    startLocation: null,
    actualDate: null,
    effDate: null,
    startTime: null,
    emailCc: [],
    employeeStatus: null,
    oldProfile: null,
    contractDocx: null,
    contractPdf: null,
    decisionDocx: null,
    decisionPdf: null,
    commitmentDocx: null,
    commitmentPdf: null,
    contractTemplate: null,
    checkedTerm1: false,
    checkedTerm2: false
  };
};

export interface IUser {
  fullName: string;
  email: string;
  role: string;
  profile: IProfile;
  id: string;
  token: string;
}

export enum Permissions {
  SuperAdmin = 'admin',
  Admin = 'adminuser',
  Staff = 'user',
  Candidate = 'guest'
}

export enum MembersPermissions {
  Admin = 'admin',
  Hr = 'hr',
  Employee = 'employee'
}
