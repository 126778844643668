export enum MutationTypeContractTemplate {
  ContractTemplateAppendModel = 'contractTemplateAppendModel',
  ContractTemplateLoading = 'contractTemplateLoading',
  ContractTemplateLoaded = 'contractTemplateLoaded',
  ContractTemplateResetModels = 'contractTemplateResetModels'
}

export enum ActionTypeContractTemplate {
  ContractTemplatePaginationChange = 'contractTemplatePaginationChange',
  ContractTemplateFilterNoCache = 'contractTemplateFilterNoCache',
  ContractTemplateFilterChange = 'contractTemplateFilterChange',
  ContractTemplateUpsert = 'contractTemplateUpsert',
  ContractTemplateCreate = 'contractTemplateCreate',
  ContractTemplateDelete = 'contractTemplateDelete',
  ContractTemplateUpdate = 'contractTemplateUpdate',
  ContractTemplateFetchMany = 'contractTemplateFetchMany',
  ContractTemplateFindById = 'contractTemplateFindById'
}
