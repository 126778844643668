import { getRole } from 'root/helpers';
import { IUser, Permissions } from 'root/models';
import { UserInfo, UserPasswordForm } from 'root/pages/Users/Components';
import { ActionType, IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { HPDropdown } from '../HPDropdown';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-dropdown': HPDropdown,
    'user-info': UserInfo,
    'user-password-form': UserPasswordForm
  },
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  },
  mounted() {
    const path = this.$route.path.split('/');
    if (path[1] === 'export-pdf') {
      return;
    }
    this.$refs[`nav-${path[1]}`].$el.classList.add('router-link-exact-active');
  }
})
export class HPNavigator extends Vue {
  public authUser: IUser;
  public visibleCreateForm: boolean = false;
  public visiblePasswordForm: boolean = false;
  public userActions: any[] = [
    {
      value: 'Xem thông tin',
      key: 'info',
      icon: 'el-icon-view'
    },
    {
      value: 'Đổi mật khẩu',
      key: 'edit',
      icon: 'el-icon-edit'
    },
    {
      value: 'Đăng xuất',
      key: 'logout',
      icon: 'el-icon-switch-button'
    }
  ];
  public $refs: {
    dbttn: any
  };

  public roleFormatter(role: string) {

    return getRole(role);
  }

  public handleClick() {
    this.$refs.dbttn.$el.children[0].click();
  }

  public checkPermission(user: IUser) {
    return user && (user.role === Permissions.SuperAdmin ||
      user.role === Permissions.Admin);
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');

    switch (actions[0]) {
    case 'info':
      this.visibleCreateForm = true;
      break;

    case 'edit':
      this.visiblePasswordForm = true;
      break;

    case 'logout':
      const router = this.$router;
      this.$store.dispatch(ActionType.Unauthenticated, router);
      break;

    default:
      break;
    }
  }
}
