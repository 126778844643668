// import { IUserRegisterForm } from 'root/models';
import { IUser } from 'root/models';
import { IHttpServiceClientOptions } from '../Core';

export interface IProfileHttpService {
  getProfile(): Promise<IUser>;
  // updateProfile(form: IUserForm): Promise<IProfile>;
  logOut(): Promise<any>;
  login(email: string, password: string): Promise<IUser>;
  // register(form: IUserRegisterForm): Promise<IUser>;
  // updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any>;
}

export function ProfileHttpService(options: IHttpServiceClientOptions): IProfileHttpService {

  const baseUrl = '/me',
    // url = options.httpService.getUrl(baseUrl, options.mock),
    urlLogout = options.httpService.getUrl(`${baseUrl}/logout`, options.mock);
    // urlUserNotificationSetting = options.httpService.getUrl(`${baseUrl}/notification`, options.mock);

  // function register(form: IUserRegisterForm): Promise<IUser> {
  //   const registerPath = options.httpService.getUrl('/profile');

  //   return options.httpService.post(registerPath, form);
  // }

  function logOut(): Promise<any> {
    return options.httpService.put(urlLogout, null);
  }

  function login(email: string, password: string): Promise<IUser> {
    const loginUrl = options.httpService.getUrl('/login', options.mock);

    return options.httpService.post(loginUrl, {email, password});
  }

  function getProfile(): Promise<IUser> {
    const profilePath = options.httpService.getUrl('/me');

    return options.httpService.get(profilePath);
  }

  // function updateProfile(form: IUserForm): Promise<IProfile> {
  //   return options.httpService.put(url, form);
  // }

  // function updateUserNotificationSetting(form: IUserNotificationSettingForm): Promise<any> {
  //   return options.httpService.put(urlUserNotificationSetting, form);
  // }

  return {
    getProfile,
    // updateProfile,
    logOut,
    login
    // register
    // updateUserNotificationSetting
  };
}
