import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'profiles';

const model = `
id
userId
progress
avatar
company
division
center
department
unit
team
firstName
middleName
lastName
email
phone
dob
pob
gender
maritalStatus
nationality
ethnic
religion
homePhone
idType
idNo
idDoi
idPoi
IdImg
oldIdNo
oldIdDoi
oldIdPoi
houseHoldIdNo
headOfHHIdNo
familyMembers
sameAddr
tempAddr
tCity
tDistrict
tWard
permAddr
pCity
pDistrict
pWard
houseHoldImg
degrees
degreeImg
certificates
certificateImg
workingRelative
relativeName
relativePosition
relativeRelationship
pastWorking
pastPosition
pastStartDate
pastEndDate
pastReason
positionCode
startDate
changeType
paperIssued
pow
positionName
employeeType
contractType
contractNo
effectiveDate
expiryDate
dop
probationPeriod
eop
workingTime
signerName
signerIdNo
signerIdDoi
signerIdPoi
signerPosition
signerDob
signerPAddr
authLetter
signDate
salaryUnit
isGrossSalary
salaryTotal
salaryCurrency
salaryHrisTotal
salaryHrisBase
bonusMax
salaryNote
bonuses
academicLevel,
localExpat,
qualifications,
idExpDate,
oldIdExpDate,
tncn,
taxNo,
bhxh,
bhxhNo,
bhxhStatus,
bhxhPaperNo
healthStatus,
hospital,
healthImg,
dependants,
bankName,
bankAccNo,
bankAcc,
positionLevel,
updatedAt,
urgentContactName,
urgentContactRelationship,
urgentContactPhone,
bornCity,
bornDistrict,
bornWard,
jobs,
referrers,
staffId,
effDate,
actualDate,
contactPerson,
contactNumber,
startLocation,
workingLocation,
startTime,
startLocation,
emailCc,
employeeStatus,
contractDocx,
contractPdf,
decisionDocx,
decisionPdf,
commitmentDocx,
commitmentPdf,
contractTemplate,
isRehired,
checkedTerm1,
checkedTerm2
`;

export const ProfileQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
