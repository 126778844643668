import { IUser } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IUserHttpService
  extends ICRUDHttpService<IUser> {
  getUsersList(filter: any): Promise<any>;
}

export function UserHttpService(options: IHttpServiceClientOptions): IUserHttpService {

  const basePath = '/users';
  const baseURL = options.httpService.getUrl(basePath, options.mock);

  function getUsersList(filter: any) {
    return options.httpService.get(baseURL, {
      params: filter
    });
  }

  return {
    ...CRUDHttpService<IUser>({
      ...options,
      basePath
    }),
    getUsersList
  };
}
