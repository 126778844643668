import gql from 'graphql-tag';
import { IContractTemplate } from 'root/models';
import { gqlClient, CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IContractTemplateGqlService
  extends ICRUDGqlService<IContractTemplate> {
  generatePdf(link: string, fileName: string): Promise<string>;
}
export function contractTemplateGqlService(): IContractTemplateGqlService {
  return {
    ...CRUDGqlService<IContractTemplate>('contract_templates'),
    generatePdf
  };
}

async function generatePdf(link: string, fileName: string): Promise<string> {
  const query = gql`
  query generatePdf($link: String!, $fileName: String!) {
    generatePdf(input: {link: $link, fileName: $fileName})
  }
  `;
  const result = await gqlClient.query({
    query,
    variables: {
      link,
      fileName
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['generatePdf'];
}
