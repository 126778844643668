import { IReferenceRecord } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IReferenceRecordState extends ICRUDState<IReferenceRecord> {
  loading: boolean;
}

export const defaultState: IReferenceRecordState = {
  ...CRUDState(),
  loading: false
};

export const mutations = {
  ...crudMutations('referenceRecord')
};
