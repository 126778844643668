import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    placeholder: {
      type: String,
      default: 'Tìm kiếm'
    }
  }
})
export class HPSearch extends Vue {
  public keyWord: string = '';

  public getValue(keyWord: string) {
    this.$emit('getValue', keyWord);
  }
}
