import { hpGqlClient } from 'root/api';
import { crudActions } from 'root/store/helpers';
import { ReferenceQuery } from './query';

const { create, filter, fetchMany, getById, update, upsert, upsertMany } = hpGqlClient.reference;

export const actions = {
  ...crudActions('reference', {
    create, filter, fetchMany, getById, update, upsert, upsertMany
  }, {
    queries: ReferenceQuery,
  }),
};

// const { filter, create, deleteById, update, getReferenceList } = hpHttpClient.reference;
// export const actions = {
//   ...crudActions('reference', {
//     filter,
//     create,
//     deleteById,
//     update
//   }),
//   async [ActionTypeReference.GetReferenceList](
//     { commit, dispatch}: ActionContext<IReferenceState, IState>, _filter
//   ) {
//     commit(MutationTypeReference.ReferenceLoading);

//     try {
//       const data = await getReferenceList(_filter);
//       commit(MutationTypeReference.SetReferenceList, data);
//       commit(MutationTypeReference.ReferenceLoaded);
//     } catch (error) {
//       dispatch(ActionType.CatchException, error);
//       commit(MutationTypeReference.ReferenceLoaded);
//     }
//   }
// };
