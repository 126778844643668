export interface IContractTemplate {
  name: string;
  typeId: string;
  note: string;
  content: string;
  docFile: string;
  paperType: string;
}
export const contractTemplateFormDefault = (): IContractTemplate => {
  return {
    name: null,
    typeId: null,
    note: null,
    content: null,
    docFile: null,
    paperType: 'contract'
  };
};
export interface ISignatory {
  fullName: string;
  positionLevel: string;
  idNo: string;
  gender: string;
  positionName: string;
  idDoi: Date;
  dob: Date;
  idPoi: string;
  permAddr: string;
  authLetter: any;
  candidateLevels: string[];
}
export const signatoryFormDefault = (): ISignatory => {
  return {
    fullName: null,
    idNo: null,
    positionLevel: null,
    positionName: null,
    gender: null,
    idDoi: null,
    dob: null,
    idPoi: null,
    permAddr: null,
    authLetter: {
      number: null,
      effectiveDate: null,
      gender: null,
      fullName: null,
      positionLevel: null
    },
    candidateLevels: []
  };
};
export interface IShortcut {
  name: string;
  description: string;
  content: string;
}
