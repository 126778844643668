import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown } from 'root/components';
import { HPPage } from 'root/components/HPPage';
import { IGuideline } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeGuideline } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.guideline.data,
      loading: (state: IState) => state.guideline.loading,
      pagination: (state: IState) => state.guideline.pagination,
      authUser: (state: IState) => state.global.authUser
    })
  },
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  }
})

export class GuidelineTable extends Vue {
  public authUser: any;
  public pagination: IPaginationParams;
  public data: IGuideline[];
  public listOption: number = 10;
  public listOptions: any[] = [
    {
      value: '10 kết quả mỗi trang',
      key: 10
    },
    {
      value: '20 kết quả mỗi trang',
      key: 20
    },
    {
      value: '50 kết quả mỗi trang',
      key: 50
    }
  ];

  public mounted() {
    this.$store.dispatch(ActionTypeGuideline.GuidelineFilterNoCache, {
      params: {
        pagingParams: {
          limit: 10,
          page: 1
        }
      }
    });
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public async handleCommand(model: string) {
    const actions: string[] = model.split('_');
    this.listOption = parseInt(actions[0], 10);
    this.$store.dispatch(ActionTypeGuideline.GuidelineFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.listOption,
          page: 1
        }
      }
    });
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeGuideline.GuidelineFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }

  public sortTable(params: any) {
    switch (params.prop) {
    case 'updatedAt':
      this.$store.dispatch(ActionTypeGuideline.GuidelineOrderChange, {
        params: {
          updatedAt: params.order === 'descending' ? 'desc' : 'asc'
        }
      });
      break;
    default:
      break;
    }
  }

  public convertTime(time: string) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };

    return new Date(time).toLocaleDateString('vi-VN', options);
  }
}
