import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

export interface IAuthenticator {
  getAccessToken(): Promise<string>;
}

export interface IGraphQLClientOptions {
  httpURL: string;
  wsURL: string;
  authenticator: IAuthenticator;
}

// function getOsversion() {
//   if (navigator.platform) {
//     return navigator.platform;
//   }

//   return '';
// }

// function getModel() {
//   const ua= navigator.userAgent;
//   let tem,
//     M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
//   if (/trident/i.test(M[1])) {
//     tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

//     return 'IE ' + (tem[1] || '');
//   }
//   if (M[1] === 'Chrome') {
//     tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
//     if (tem !== null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
//   }
//   M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
//   // tslint:disable-next-line:no-conditional-assignment
//   if ((tem = ua.match(/version\/(\d+)/i)) !== null) { M.splice(1, 1, tem[1]); }

//   return M.join(' ');
// }

export function GraphQLClient(
  options: IGraphQLClientOptions
): ApolloClient<InMemoryCache> {

  const authLink = setContext(async (_, { headers }) => {
    const authorization = await options.authenticator.getAccessToken();

    return {
      headers: {
        ...headers,
        authorization: authorization === null ? '' : authorization
        // 'x-hasura-admin-secret': process.env.HASURA_ADMIN_SECRET
      }
    };
  });

  // Create an http link:
  const httpLink = createHttpLink({
    uri: options.httpURL,
  });

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: options.wsURL,
    options: {
      lazy: true,
      connectionParams: () => options.authenticator.getAccessToken()
        .then((authorization) => {
          return ({
            headers: {
              authorization,
              // 'x-hasura-access-token': authorization
            }
          });
        }),
      reconnect: true
    }
  });

  const link = split(
    ({ query }) => {
      const { kind, operation } = <any> getMainDefinition(query);

      return kind === 'OperationDefinition'
        && operation === 'subscription';
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new ApolloClient(<any> {
    link,
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV !== 'production'
  });

}
