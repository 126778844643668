import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown, HPPage } from 'root/components';
import { IShortcut } from 'root/models';
import { ActionTypeShortcut } from 'root/pages/Contracts/ShortcutStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.shortcut.data,
      loading: (state: IState) => state.shortcut.loading,
      pagination: (state: IState) => state.shortcut.pagination
    })
  },
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  }
})
export class ShortcutTable extends Vue {
  public authUser: any;
  public pagination: IPaginationParams;
  public data: IShortcut[];
  public listOption: number = 10;
  public listOptions: any[] = [
    { value: '10 kết quả mỗi trang', key: 10 },
    { value: '20 kết quả mỗi trang', key: 20 },
    { value: '50 kết quả mỗi trang', key: 50 }
  ];

  public mounted() {
    this.$store.dispatch(ActionTypeShortcut.ShortcutFilterNoCache, {
      params: {
        pagingParams: {
          limit: 10,
          page: 1
        }
      }
    });
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    this.listOption = parseInt(actions[0], 10);
    this.$store.dispatch(ActionTypeShortcut.ShortcutFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.listOption,
          page: 1
        }
      }
    });
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeShortcut.ShortcutFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }
}
