import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'referenceRecords';

const model = `id
  status
  name
  code
  tableName
  superior
  note
  `;

export const ReferenceRecordQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
