// import { hpHttpClient } from 'root/api/http';
import { hpGqlClient } from 'root/api/';
import { crudActions } from 'root/store/helpers';
import { SignatoryQuery } from './query';

const { create, filter, fetchMany, getById, update, deleteById, upsert } = hpGqlClient.signatory;

export const actions = {
  ...crudActions('signatory', {
    create, filter, fetchMany, getById, update, deleteById, upsert
  }, {
    queries: SignatoryQuery,
  }),
};
