import router from 'root/router';
import { HPToken } from 'root/store';
import { IAuthService } from './types';

export function AmplifyAuthService(): IAuthService {
  return {
    getAccessToken: async () => {
      if (router.currentRoute.path === '/public') {
        return <string> router.currentRoute.query.token;
      }

      return localStorage.getItem(HPToken.accessToken);
    },
    logOut: (callback?: any) => {
      localStorage.removeItem(HPToken.accessToken);
      localStorage.removeItem('loggedIn');

      callback();
    },
    getLogInState: async () => {
      return localStorage.getItem('loggedIn');
    }
  };
}
