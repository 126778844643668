import { ruleEmail, ruleRequired } from 'root/helpers';
import { ReferenceTableName } from 'root/models';
import { ActionTypeSignatory } from 'root/pages/Contracts/SignatoryStore';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    form: Object
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading
    })
  },
  watch: {
    'form.dop'(val) {
      if (!val) {
        this.$props.form.eop = null;

        return;
      }
      // tslint:disable-next-line: prefer-conditional-expression
      if (!this.$props.form.probationPeriod) {
        this.$props.form.eop = null;
      } else {
        this.$props.form.eop = this.endDate(val, this.$props.form.probationPeriod.toString());
      }
    },
    'form.contractTemplate'(val) {
      this.$props.form.noExpiryDate = this.contractTemplateNames[val] === 'KXĐ';
    },
    'form.contractType'(val, oldVal) {
      if (this.contractTypeNames.includes(this.contractName[val])) {
        this.$props.form.noExpiryDate = true;
        if (!this.contractTypeNames.includes(this.contractName[oldVal])) {
          this.tempDop = this.$props.form.dop;
        }
        this.$props.form.dop = null;
        this.$props.form.probationPeriod = null;

        return;
      }
      this.$props.form.noExpiryDate = false;
      if (this.contractTypeNames.includes(this.contractName[oldVal])) {
        this.$props.form.dop = this.tempDop;
      }
    },
    'form.probationPeriod'(val) {
      this.$props.form.eop = val ? this.endDate(this.$props.form.dop, val) : null;
    },
  }
})
export class ProfileContractForm extends Vue {
  public signatoryGender: string = '';
  public tempDop: string = '';
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }
  public contractTemplateNames= {};
  public contractTypeNames: string[] = [
    'TT_HDLDCTH_Sales_LamCa_KTV',
    'TT_HDLDKXD_KTV',
    'TT_HDLDTV',
    'TT_HDLDTV_Sales_Lamca',
    'TT_HDLDXD_Expat_KTV',
    'TT_HDLDXD_KTV',
    'TT_HDLDXD_Sales_LamCa_KTV',
    'TT_HDDV_VanPhong_CongNhat',
    'TT_HDDV_VanPhong',
    'TT_HDDVBH_CoDinh',
    'TT_HDDVBH_KhaBien'
  ];

  public referenceGroups: any = {
    [ReferenceTableName.ContractTemplate]: 'templateOptions',
    [ReferenceTableName.CurrentContractType]: 'contractOptions',
    [ReferenceTableName.WorkingTime]: 'wokingTimeOptions',
    [ReferenceTableName.IDLocation]: 'poiOptions'
  };
  public templateOptions: any[] = [];
  public contractOptions: any[] = [];
  public wokingTimeOptions: any[] = [];
  public poiOptions: any[] = [];
  public contractName: any = {};

  public mounted() {
    this.$nextTick(() => {
      const filter = [];
      Object.keys(this.referenceGroups).forEach((key) => {
        filter.push({
          tableName: { _eq: key }
        });
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: filter
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              if (record.tableName === ReferenceTableName.ContractTemplate) {
                this.contractTemplateNames[record.id] = record.code;
              }
              if (record.tableName === ReferenceTableName.CurrentContractType) {
                this.contractName[record.id] = record.name;
              }
              this[`${this.referenceGroups[record.tableName]}`].push({
                label: record.tableName === ReferenceTableName.ContractTemplate ?
                  record.code : record.name,
                value: record.id
              });
            });
            this.$props.form.noExpiryDate = this.contractTemplateNames[this.$props.form.contractTemplate] === 'KXĐ';
          }
        }
      });
      if (!this.$props.form.positionLevel) {
        return;
      }
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: this.$props.form.positionLevel,
        onSuccess: (ans) => {
          this.$store.dispatch(ActionTypeSignatory.SignatoryFilterNoCache, {
            params: {
              filterParams: {
                _and: [
                  {
                    candidateLevels: {
                      _contains: ans[0].name.toLowerCase()
                    }
                  }
                ]
              },
              onSuccess: (res) => {
                this.$props.form.signerName = res[0].fullName;
                this.$props.form.signerIdNo = res[0].idNo;
                this.$props.form.signerPosition = res[0].positionLevel;
                this.$props.form.signerIdDoi = res[0].idDoi;
                this.$props.form.signerIdPoi = res[0].idPoi;
                this.$props.form.signerDob = res[0].dob;
                this.$props.form.signerPAddr = res[0].permAddr;
                this.$props.form.authLetter = res[0].authLetter.content || '';
                this.signatoryGender = res[0].gender;
              }
            }
          });
        }
      });
    });
  }

  public endDate(from: string, period: string) {
    if (!from) {
      return '';
    }
    const date = new Date(from);
    const num = Number(period) > 0 ? Number(period) - 1 : 0;

    return new Date(date.setDate(date.getDate() + num));
  }
}
