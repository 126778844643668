export enum MutationTypeUser {
  UserAppendModel = 'userAppendModel',
  UserLoading = 'userLoading',
  UserLoaded = 'userLoaded',
  UserResetModels = 'userResetModels'
}

export enum ActionTypeUser {
  UserPaginationChange = 'userPaginationChange',
  UserFilterNoCache = 'userFilterNoCache',
  UserFilterChange = 'userFilterChange',
  UserOrderChange = 'userOrderChange',
  UserCreate = 'userCreate',
  UserDelete = 'userDelete',
  UserUpdate = 'userUpdate',
  UserFetchMany = 'userFetchMany',
  UserFindById = 'userFindById'
}
