export enum MutationTypeReferenceRecord {
  ReferenceRecordLoading = 'referenceRecordLoading',
  ReferenceRecordLoaded = 'referenceRecordLoaded',
  ReferenceRecordResetModels = 'ReferenceRecordResetModels'
}

export enum ActionTypeReferenceRecord {
  ReferenceRecordPaginationChange = 'referenceRecordPaginationChange',
  ReferenceRecordFilterNoCache = 'referenceRecordFilterNoCache',
  ReferenceRecordFindById = 'referenceRecordFindById',
  ReferenceRecordFilter = 'referenceRecordFilter',
  ReferenceRecordCreate = 'referenceRecordCreate',
  ReferenceRecordCreateMany = 'referenceRecordCreateMany',
  ReferenceRecordUpsert = 'referenceRecordUpsert',
  ReferenceRecordUpsertMany = 'referenceRecordUpsertMany',
  ReferenceRecordDelete = 'referenceRecordDelete',
  ReferenceRecordUpdate = 'referenceRecordUpdate',
  ReferenceRecordFetchMany = 'referenceRecordFetchMany'
}
