import Vue from 'vue';
import Vuex from 'vuex';

import { actions } from './actions';
import { getters } from './getters';
import { globalState, mutations } from './mutations';

// module
import * as shortcut from 'root/pages/Contracts/ShortcutStore';
import * as signatory from 'root/pages/Contracts/SignatoryStore';
import * as contractTemplate from 'root/pages/Contracts/Store';
import * as guideline from 'root/pages/Guidelines/Store';
import * as referenceRecord from 'root/pages/Reference/RecordStore';
import * as reference from 'root/pages/Reference/Store';
import * as profile from 'root/pages/Users/ProfileStore';
import * as user from 'root/pages/Users/Store';

export * from './types';

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  mutations,
  state: {
    global: globalState,
    route: null,
    user: null,
    reference: null,
    referenceRecord: null,
    profile: null,
    guideline: null,
    contractTemplate: null,
    signatory: null,
    shortcut: null
  },
  modules: {
    user,
    reference,
    referenceRecord,
    profile,
    guideline,
    contractTemplate,
    signatory,
    shortcut
  }
});

export * from './types';
