import { signatoryGqlService, ISignatoryGqlService } from './ContractSignatory';
import { contractTemplateGqlService, IContractTemplateGqlService } from './ContractTemplate';
import { GuidelineGqlService, IGuidelineGqlService } from './Guideline';
import { IProfileGqlService, ProfileGqlService } from './Profile';
import { IReferenceGqlService, ReferenceGqlService } from './Reference';
import { IReferenceRecordGqlService, ReferenceRecordGqlService } from './ReferenceRecord';
import { shortcutGqlService, IShortcutGqlService } from './Shortcut';
import { IUserGqlService, UserGqlService } from './User';

export * from './User';

export interface IGqlClient {
  user: IUserGqlService;
  reference: IReferenceGqlService;
  referenceRecord: IReferenceRecordGqlService;
  profile: IProfileGqlService;
  guideline: IGuidelineGqlService;
  contractTemplate: IContractTemplateGqlService;
  signatory: ISignatoryGqlService;
  shortcut: IShortcutGqlService;
}
export function DKGqlClient(): IGqlClient {
  return {
    user: UserGqlService(),
    reference: ReferenceGqlService(),
    referenceRecord: ReferenceRecordGqlService(),
    profile: ProfileGqlService(),
    guideline: GuidelineGqlService(),
    contractTemplate: contractTemplateGqlService(),
    signatory: signatoryGqlService(),
    shortcut: shortcutGqlService()
  };
}
