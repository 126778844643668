import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';
import { getFullName } from 'root/helpers';
import { publicCandidateFormDefault, IProfile, IUser } from 'root/models';
import { getFirebaseApp, uploadToFirebase } from 'root/services';
import { IState, MutationType } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { PublicCandidateForm } from '../Components/PublicCandidateForm';
import { ActionTypeProfile } from '../ProfileStore';
import { ActionTypeUser } from '../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  data() {
    return {
      form: publicCandidateFormDefault()
    };
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data[0],
      formUpdate: (state: IState) => state.profile.data[0]
    })
  },
  components: {
    'public-user-info-form': PublicCandidateForm
  },
  watch: {
    data(val) {
      if (!val || this.user) {
        return;
      }

      this.user = val;
    },
    formUpdate(val) {
      val.sameAddr = false;
      this.profileId = val.id;
      Object.keys(this.$data.form).forEach((key) => {
        if ((val[key] || typeof val[key] === 'boolean')
          && val[key] !== this.$data.form[key]) {
          this.$data.form[key] = val[key];
        }
      });
    }
  }
})

export class PublicPageContainer extends Vue {
  public formUpdate: IProfile;
  public data: IUser;
  public user: IUser = null;
  public profileId: string = '';
  public loading: boolean = false;
  public storageRef = getFirebaseApp().storage().ref();
  public $refs: {
    fixedTop: any
  };

  public mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('beforeunload', (e) => {
        const confirmationMessage = 'You have unsaved changes';

        (e || window.event).returnValue = confirmationMessage;

        return confirmationMessage;
      });
    });
  }

  public handleScroll() {
    if (window.scrollY > 250 && window.innerWidth <= 1024) {
      this.$refs.fixedTop.style.display = 'block';
    }
    if (window.scrollY < 250 && window.innerWidth <= 1024) {
      this.$refs.fixedTop.style.display = 'none';
    }
  }

  public async submitForm(isComplete: boolean) {
    try {
      if (!this.user || !this.formUpdate) {
        this.$message.error(this.$t('error_loading_user').toString());

        return;
      }
      for (const rec of this.$data.form.familyMembers) {
        let pattern = /^[0-9]*$/;
        if (!pattern.test(rec['dob'].trim())) {
          this.$message.error(this.$t('invalid_family_member_dob').toString());

          return;
        }
        pattern = /^[0-9]+\s*$/;
        // tslint:disable-next-line: early-exit
        if (rec['phone'].trim().length !== 10 || !pattern.test(rec['phone'].trim())) {
          this.$message.error(this.$t('invalid_family_member_phone').toString());

          return;
        }
      }
      if (this.$data.form.sameAddr) {
        this.$data.form.tCity = this.$data.form.pCity;
        this.$data.form.tDistrict = this.$data.form.pDistrict;
        this.$data.form.tWard = this.$data.form.pWard;
        this.$data.form.tempAddr = this.$data.form.permAddr;
      }
      if (isComplete) {
        if (!this.$data.form.avatar) {
          this.$message.error(this.$t('require_avatar').toString());

          return;
        }
        // tslint:disable-next-line: max-line-length
        let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.$data.form.email && !pattern.test(this.$data.form.email)) {
          this.$message.error(this.$t('invalid_email').toString());

          return;
        }
        pattern = /^[0-9]*$/;
        if (this.$data.form.idNo && this.$data.form.idNo.length !== 9 && this.$data.form.idNo.length !== 12
          || this.$data.form.oldIdNo && this.$data.form.oldIdNo.length !== 9 && this.$data.form.oldIdNo.length !== 12
          || this.$data.form.idNo && !pattern.test(this.$data.form.idNo)
          || this.$data.form.oldIdNo && !pattern.test(this.$data.form.oldIdNo)) {
          this.$message.error(this.$t('invalid_id').toString());

          return;
        }
        if (this.$data.form.bhxhPaperNo && !pattern.test(this.$data.form.bhxhPaperNo)) {
          this.$message.error(this.$t('invalid_bhxh_paper_no').toString());
          this.loading = false;

          return;
        }
        pattern = /^[0-9]+\s*$/;
        if (this.$data.form.phone && (this.$data.form.phone.length !== 10 || !pattern.test(this.$data.form.phone))
          || this.$data.form.urgentContactPhone &&
          (this.$data.form.urgentContactPhone.length !== 10 || !pattern.test(this.$data.form.urgentContactPhone))) {
          this.$message.error(this.$t('invalid_phone_number').toString());

          return;
        }
        if (this.$data.form.bhxh && (!this.$data.form.bhxhNo || this.$data.form.bhxhNo.length !== 10 ||
          !pattern.test(this.$data.form.bhxhNo))) {
          this.$message.error(this.$t('invalid_bhxh').toString());

          return;
        }
        const date = new Date(this.$data.form.dob);
        date.setFullYear(date.getFullYear() + 15);
        const now = new Date();
        if (this.$data.form.dob && date.getTime() > now.getTime()) {
          this.$message.error(this.$t('invalid_dob').toString());

          return;
        }

        const s1: string[] = ['gender', 'dob', 'pob', 'nationality', 'maritalStatus', 'religion',
          'ethnic', 'email', 'phone', 'academicLevel', 'localExpat', 'qualifications',
          'urgentContactName', 'urgentContactPhone', 'urgentContactRelationship'];
        const s2: string[] = ['idNo', 'idDoi', 'idPoi', 'hospital', 'bornCity', 'bornDistrict', 'bornWard'];
        const s4: string[] = ['pCity', 'pDistrict', 'pWard', 'tCity', 'tDistrict', 'tWard'];
        for (let i = 1; i < 8; ++i) {
          this.$set(this.$data.form, `s${i}NotFull`, false);
        }
        s1.forEach((field) => {
          if (!this.$data.form[field]) {
            this.$set(this.$data.form, 's1NotFull', true);
          }
        });
        if (this.$data.form.tncn && !this.$data.form.taxNo ||
          this.$data.form.bhxh && (!this.$data.form.bhxhNo || !this.$data.form.bhxhStatus)) {
          this.$set(this.$data.form, 's1NotFull', true);
        }

        s2.forEach((field) => {
          if (!this.$data.form[field]) {
            this.$set(this.$data.form, 's2NotFull', true);
          }
        });
        if (this.$data.form.idType === 'CCCD' && !this.$data.form.idExpDate || !this.$data.form.IdImg
          || !this.$data.form.IdImg.length || !this.$data.form.healthImg || !this.$data.form.healthImg.length) {
          this.$set(this.$data.form, 's2NotFull', true);
        }
        if (this.$data.form.bankAcc && (!this.$data.form.bankAccNo || !this.$data.form.bankName)) {
          this.$set(this.$data.form, 's2NotFull', true);
        }
        if (!this.$data.form.familyMembers || !this.$data.form.familyMembers.length) {
          this.$set(this.$data.form, 's3NotFull', true);
        } else {
          this.$data.form.familyMembers.forEach((rec) => {
            for (const key of Object.keys(rec)) {
              if (key !== 'focus' && !rec[key]) {
                this.$set(this.$data.form, 's3NotFull', true);
                break;
              }
            }
          });
        }
        s4.forEach((field) => {
          if (!this.$data.form[field]) {
            this.$set(this.$data.form, 's4NotFull', true);
          }
        });
        if (!this.$data.form.houseHoldImg || !this.$data.form.houseHoldImg.length) {
          this.$set(this.$data.form, 's4NotFull', true);
        }
        if (!this.$data.form.degrees || !this.$data.form.degrees.length ||
          !this.$data.form.degreeImg || !this.$data.form.degreeImg.length) {
          this.$set(this.$data.form, 's5NotFull', true);
        }
        if (this.$data.form.workingRelative && (!this.$data.form.relativeName ||
          !this.$data.form.relativePosition || !this.$data.form.relativeRelationship) || !this.$data.form.referrers ||
          !this.$data.form.referrers.length || this.$data.form.referrers.length < 2 ||
          (!this.$data.form.referrers[0].name || !this.$data.form.referrers[0].position ||
          !this.$data.form.referrers[0].pow || !this.$data.form.referrers[0].contact ||
          !this.$data.form.referrers[0].relationship || !this.$data.form.referrers[1].name ||
          !this.$data.form.referrers[1].position || !this.$data.form.referrers[1].pow ||
          !this.$data.form.referrers[1].contact || !this.$data.form.referrers[1].relationship ||
          this.$data.form.pastWorking &&
          (!this.$data.form.pastStartDate || !this.$data.form.pastEndDate ||
            !this.$data.form.pastPosition || !this.$data.form.pastReason))) {
          this.$set(this.$data.form, 's7NotFull', true);
        }
        for (let i = 1; i < 8; ++i) {
          if (!this.$data.form[`s${i}NotFull`]) {
            continue;
          }
          Object.keys(this.$data.form).forEach((key) => {
            if (key === 'homePhone') {
              return;
            }
            if (typeof this.$data.form[key] !== 'boolean' && !this.$data.form[key]) {
              this.$set(this.$data.form, key, '');
            }
          });
          this.$message.error(this.$t('required_fields_cannot_be_empty').toString());

          return;
        }
      }
      const check = await this.checkEmailExisted();
      if (!check) {
        this.$message.error(this.$t('email_existed').toString());

        return;
      }
      for (let i = 1; i < 8; ++i) {
        delete this.$data.form[`s${i}NotFull`];
      }
      this.$store.commit(MutationType.Authenticated, this.user);
      this.loading = true;
      const submitForm = cloneDeep(this.$data.form);
      if (isComplete) {
        submitForm.progress.personalInfo = true;
      }
      Object.keys(submitForm).forEach((key) => {
        if (this.$data.form[key] === '') {
          submitForm[key] = null;
        }
        if (!['jobs', 'referrers', 'familyMembers', 'certificates', 'degrees'].includes(key)) {
          return;
        }
        const newItems = [];
        submitForm[key].forEach((item) => {
          for (const k of Object.keys(item)) {
            if (k === 'focus' || !item[k]) {
              continue;
            }
            newItems.push(item);
            break;
          }
        });
        submitForm[key] = newItems;
      });
      submitForm.familyMembers.forEach((item) => delete item.focus);
      submitForm.degrees.forEach((item) => delete item.focus);
      submitForm.certificates.forEach((item) => delete item.focus);
      submitForm.jobs.forEach((item) => delete item.focus);
      submitForm.referrers.forEach((item) => delete item.focus);
      if (submitForm.sameAddr) {
        submitForm.tCity = submitForm.pCity;
        submitForm.tDistrict = submitForm.pDistrict;
        submitForm.tWard = submitForm.pWard;
        submitForm.tempAddr = submitForm.permAddr;
      }
      if (submitForm.oldAvatar) {
        await new Promise((resolve) => {
          getFirebaseApp().storage().refFromURL(submitForm.oldAvatar).delete()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log(err);
              resolve();
            });
        });
        delete submitForm.oldAvatar;
        this.$data.form.oldAvatar = '';
      }
      if (submitForm.avatar && submitForm.avatar.name) {
        const url = await uploadToFirebase(submitForm.avatar, `${this.user.id}/images/`,
          null, 'Hinh_dai_dien');
        submitForm.avatar = url;
        this.$set(this.$data.form, 'avatar', url);
      }
      if (submitForm.oldIdImg) {
        await this.deleteOnFirebase(submitForm, 'oldIdImg');
        delete submitForm.oldIdImg;
        this.$data.form.oldIdImg = [];
      }
      if (submitForm.rawIdImg) {
        const dict = await this.getAvailableNames(submitForm, 'IdImg', 2, 'CMND');
        await this.uploadImages(submitForm, 'IdImg', 'rawIdImg', dict);
        this.$set(this.$data.form, 'IdImg', submitForm.IdImg);
        delete submitForm.rawIdImg;
        this.$data.form.rawIdImg = {};
      }
      if (submitForm.oldhouseHoldImg) {
        await this.deleteOnFirebase(submitForm, 'oldhouseHoldImg');
        delete submitForm.oldhouseHoldImg;
        this.$data.form.oldhouseHoldImg = [];
      }
      if (submitForm.rawhouseHoldImg) {
        const dict = await this.getAvailableNames(submitForm, 'houseHoldImg', 20, 'Ho_khau');
        await this.uploadImages(submitForm, 'houseHoldImg', 'rawhouseHoldImg', dict);
        this.$set(this.$data.form, 'houseHoldImg', submitForm.houseHoldImg);
        delete submitForm.rawhouseHoldImg;
        this.$data.form.rawhouseHoldImg = {};
      }
      if (submitForm.olddegreeImg) {
        await this.deleteOnFirebase(submitForm, 'olddegreeImg');
        delete submitForm.olddegreeImg;
        this.$data.form.olddegreeImg = [];
      }
      if (submitForm.rawdegreeImg) {
        const dict = await this.getAvailableNames(submitForm, 'degreeImg', 5, 'Bang_cap');
        await this.uploadImages(submitForm, 'degreeImg', 'rawdegreeImg', dict);
        this.$set(this.$data.form, 'degreeImg', submitForm.degreeImg);
        delete submitForm.rawdegreeImg;
        this.$data.form.rawdegreeImg = {};
      }
      if (submitForm.oldcertificateImg) {
        await this.deleteOnFirebase(submitForm, 'oldcertificateImg');
        delete submitForm.oldcertificateImg;
        this.$data.form.oldcertificateImg = [];
      }
      if (submitForm.rawcertificateImg) {
        const dict = await this.getAvailableNames(submitForm, 'certificateImg', 5, 'Chung_chi');
        await this.uploadImages(submitForm, 'certificateImg', 'rawcertificateImg', dict);
        this.$set(this.$data.form, 'certificateImg', submitForm.certificateImg);
        delete submitForm.rawcertificateImg;
        this.$data.form.rawcertificateImg = {};
      }
      if (submitForm.oldhealthImg) {
        await this.deleteOnFirebase(submitForm, 'oldhealthImg');
        delete submitForm.oldhealthImg;
        this.$data.form.oldhealthImg = [];
      }
      // tslint:disable-next-line: early-exit
      if (submitForm.rawhealthImg) {
        const dict = await this.getAvailableNames(submitForm, 'healthImg', 20, 'Giay_kham_suc_khoe');
        await this.uploadImages(submitForm, 'healthImg', 'rawhealthImg', dict);
        this.$set(this.$data.form, 'healthImg', submitForm.healthImg);
        delete submitForm.rawhealthImg;
        this.$data.form.rawhealthImg = {};
      }
      Object.keys(submitForm).forEach((key) => {
        submitForm[key] = typeof submitForm[key] === 'string' && submitForm[key] ?
          submitForm[key].trim() : submitForm[key];
      });
      this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
        id: this.user.profile,
        form: {
          ...submitForm
        },
        opts: {
          query: gql`
            mutation UPDATE_PROFILES($id: uuid!, $form: profiles_set_input){
              update_profiles(where: {id: {_eq: $id}}, _set: $form) {
                returning {
                  id
                  progress
                  avatar
                  firstName
                  middleName
                  lastName
                  email
                  phone
                  dob
                  pob
                  gender
                  maritalStatus
                  nationality
                  ethnic
                  religion
                  homePhone
                  idType
                  idNo
                  idDoi
                  idPoi
                  IdImg
                  oldIdNo
                  oldIdDoi
                  oldIdPoi
                  houseHoldIdNo
                  headOfHHIdNo
                  familyMembers
                  sameAddr
                  tempAddr
                  tCity
                  tDistrict
                  tWard
                  permAddr
                  pCity
                  pDistrict
                  pWard
                  houseHoldImg
                  degrees
                  degreeImg
                  certificates
                  certificateImg
                  workingRelative
                  relativeName
                  relativePosition
                  relativeRelationship
                  pastWorking
                  pastPosition
                  pastStartDate
                  pastEndDate
                  pastReason
                  positionCode
                  academicLevel,
                  localExpat,
                  qualifications,
                  idExpDate,
                  oldIdExpDate,
                  tncn,
                  taxNo,
                  bhxh,
                  bhxhNo,
                  bhxhStatus,
                  bhxhPaperNo
                  healthStatus,
                  hospital,
                  healthImg,
                  dependants,
                  bankName,
                  bankAccNo,
                  bankAcc,
                  positionLevel,
                  urgentContactName,
                  urgentContactRelationship,
                  urgentContactPhone,
                  bornCity,
                  bornDistrict,
                  bornWard,
                  jobs,
                  referrers,
                  checkedTerm1,
                  checkedTerm2
                }
              }
            }
          `
        },
        onSuccess: (res) => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id: this.user.id,
            form: {
              fullName: getFullName(res),
              email: res.email,
              updatedAt: res.updatedAt
            },
            opts: {
              query: gql`
                mutation UPDATE_USERS($id: uuid!, $form: users_set_input){
                  update_users(where: {id: {_eq: $id}}, _set: $form) {
                    returning {
                      id
                      fullName
                      email
                      role
                      profile
                      profileOfUser {
                        firstName
                        middleName
                        lastName
                        phone
                        positionCode
                        startDate
                      }
                    }
                  }
                }
              `
            },
            onSuccess: () => {
              this.loading = false;
              // tslint:disable-next-line: early-exit
              if (!isComplete) {
                this.$message.success(this.$t('profile_saved_successfully').toString());
              } else {
                this.$data.form.progress.personalInfo = true;
                const h = this.$createElement;
                this.$message.success({
                  message: h('div', { style: 'width: 300px; color: #67c23a;' }, [
                    h('span', null, this.$t('profile_completed_successfully').toString()),
                  ]),
                  duration: 12000,
                  center: true,
                  offset: window.innerHeight / 2,
                  showClose: true
                });
              }
            },
            onFailure: (error) => {
              this.loading = false;
              this.$message.error(this.$t(error.message.substring(15)).toString());
            }
          });
        },
        onFailure: (error) => {
          this.loading = false;
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    } catch (error) {
      this.$message.error(this.$t(error.message.substring(15)).toString());
    }
  }

  private checkEmailExisted() {
    return new Promise((resolve, reject) => {
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          filterParams: {
            email: this.$data.form.email
          },
          onSuccess: (res) => {
            if (!res[0] || res[0].profile === this.profileId) {
              return resolve(true);
            }

            return resolve(false);
          },
          onFailure: (error) => {
            reject(error);
          }
        },
        opts: {
          query: gql`
          query filterUsers($limit: Int!, $offset: Int!, $filter: [users_bool_exp],
            $distinctOn: [users_select_column!],
            $orderParams: [users_order_by!] = { updatedAt: desc }) {
            users(distinct_on: $distinctOn, limit: $limit, offset: $offset,
              where: {status: {_neq: "deleted"}, _and: $filter}, order_by: $orderParams) {
              id
              fullName
              email
              role
              profile
              profileOfUser {
                firstName
                middleName
                lastName
                phone
                positionCode
                startDate
              }
            }
            users_aggregate(distinct_on: $distinctOn, where: {status: {_neq: "deleted"}, _and: $filter}) {
              aggregate {
                count
              }
            }
          }`
        }
      });
    });
  }

  private async deleteOnFirebase(form: any, field: string) {
    for (const img of form[field]) {
      await new Promise((resolve) => {
        getFirebaseApp().storage().refFromURL(img).delete()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      });
    }
  }

  private async uploadImages(form: any, field: string, rawField: string, nameDict: string[]) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < form[field].length; ++i) {
      if (form[field][i][0] !== 'b') {
        continue;
      }
      const name = nameDict.pop();
      const url = await uploadToFirebase(form[rawField][form[field][i]], `${this.user.id}/images/`, null, name);
      form[field][i] = url;
    }
  }

  private async getAvailableNames(form: any, field: string, limit: number, name: string): Promise<string[]> {
    const dict: string[] = [];
    const temp = {};
    for (const item of form[field]) {
      if (item[0] === 'b') {
        continue;
      }
      const metadata = await getFirebaseApp().storage().refFromURL(item).getMetadata();
      temp[metadata.name.split('.')[0]] = 1;
    }
    for (let i = limit; i > 0; --i) {
      if (!temp[`${name}_${i}`]) {
        dict.push(`${name}_${i}`);
      }
    }

    return dict;
  }
}
