import moment from 'moment';
import { ruleDob, ruleEmail, ruleID, ruleNumber, rulePhoneNumber, ruleRequired, ruleText } from 'root/helpers';
import { IUser, ReferenceTableName } from 'root/models';
import { ActionTypeGuideline } from 'root/pages/Guidelines/Store';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { CandidateExportForm } from '../CandidateExportForm';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'candidate-export-form': CandidateExportForm
  },
  props: {
    form: Object
  },
  watch: {
    data(val) {
      if (!val || this.userId) {
        return;
      }
      this.userId = val.id;
    },
    'form.avatar'(val) {
      this.avatarHolder = this.avatarHolder ? this.avatarHolder : val;
    },
    'form.positionLevel'(val) {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: val,
        onSuccess: (res) => {
          if (!res[0]) {
            return;
          }
          this.positionLevel = res[0].name;
        }
      });
    },
    'form.bornCity'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.bornDistrict = '';
        this.form.bornWard = '';
      }
      this.optionChange(val, 'bornDistrictOptions');
    },
    'form.bornDistrict'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.bornWard = '';
      }
      this.optionChange(val, 'bornWardOptions');
    },
    'form.pCity'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.pDistrict = '';
        this.form.pWard = '';
      }
      this.optionChange(val, 'pDistrictOptions');
    },
    'form.tCity'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.tDistrict = '';
        this.form.tWard = '';
      }
      this.optionChange(val, 'tDistrictOptions');
    },
    'form.pDistrict'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.pWard = '';
      }
      this.optionChange(val, 'pWardOptions');
    },
    'form.tDistrict'(val, old) {
      if (!val) {
        return;
      }
      if (old) {
        this.form.tWard = '';
      }
      this.optionChange(val, 'tWardOptions');
    }
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading,
      data: (state: IState) => state.user.data[0],
      guidelines: (state: IState) => {
        const temp = {};
        state.guideline.data.forEach((row) => {
          temp[row.section] = row;
        });

        return temp;
      },
    })
  }
})
export class PublicCandidateForm extends Vue {
  public mediumWidth = 1024;
  public smallWidth = 440;
  public width: number = window.screen.width;
  public visibleCreateForm: boolean = false;
  public itemIndex = null;
  public data: IUser;
  public userId: string = '';
  public activeName: string = '1';
  public idCard: string = 'cmnd';
  public addr: string = 'perm';
  public positionLevel: string = '';
  public guidelines: any;
  public section: string = '';
  public showGuideline: boolean = false;
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };
  public referenceGroups: any = {
    [ReferenceTableName.Gender]: 'genderOptions',
    [ReferenceTableName.BirthLocation]: 'birthOptions',
    [ReferenceTableName.Nationality]: 'nationalityOptions',
    [ReferenceTableName.MarriageStatus]: 'maritalOptions',
    [ReferenceTableName.Ethnicity]: 'ethnicityOptions',
    [ReferenceTableName.Religion]: 'religionOptions',
    [ReferenceTableName.IDLocation]: 'idLocationOptions',
    [ReferenceTableName.City]: 'cityOptions',
    [ReferenceTableName.AcademicLevel]: 'academicOptions',
    [ReferenceTableName.LocalExpat]: 'localExpatOptions',
    [ReferenceTableName.Qualifications]: 'qualificationsOptions'
  };
  public genderOptions: any[] = [];
  public birthOptions: any[] = [];
  public nationalityOptions: any[] = [];
  public maritalOptions: any[] = [];
  public ethnicityOptions: any[] = [];
  public religionOptions: any[] = [];
  public academicOptions: any[] = [];
  public localExpatOptions: any[] = [];
  public qualificationsOptions: any[] = [];
  public qualificationsOptions2: any[] = [];
  public idLocationOptions: any[] = [];
  public cityOptions: any[] = [];
  public cityOptions2: any[] = [];
  public bornDistrictOptions: any[] = [];
  public bornWardOptions: any[] = [];
  public pDistrictOptions: any[] = [];
  public pWardOptions: any[] = [];
  public tDistrictOptions: any[] = [];
  public tWardOptions: any[] = [];
  public bhxhStatusOptions: any[] = [
    { label: 'Đã đồng bộ', value: 'synced' },
    { label: 'Chưa đồng bộ', value: 'not synced' }
  ];
  public healthStatusOptions: any[] = [
    { label: 'Loại I', value: 'type1' },
    { label: 'Loại II', value: 'type2' },
    { label: 'Loại III', value: 'type3' },
    { label: 'Loại IV', value: 'type4' },
    { label: 'Loại V', value: 'type5' }
  ];
  public bankOptions: any[] = [
    { label: 'Timo', value: 'timo' },
    { label: 'VP Bank', value: 'vpbank' }
  ];
  public degreeOptions: any[] = [
    { label: 'Chính quy', value: 'Chính quy' },
    { label: 'Tại chức', value: 'Tại chức' },
    { label: 'Học từ xa', value: 'Học từ xa' },
    { label: 'Tự học có hướng dẫn', value: 'Tự học có hướng dẫn' }
  ];
  public relationshipOptions: any[] = [
    { label: 'Đồng nghiệp cũ', value: 'Đồng nghiệp cũ' },
    { label: 'Quản lý cũ', value: 'Quản lý cũ' },
    { label: 'Bạn bè', value: 'Bạn bè' },
  ];

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      phone: rulePhoneNumber(),
      id: ruleID(),
      dob: ruleDob(),
      text: ruleText(),
      number: ruleNumber(),
      bhxh: rulePhoneNumber('invalid_bhxh')
    };
  }
  public pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() >= Date.now();
    },
  };

  public disabled: boolean = false;

  public avatarHolder: string = '';

  public mounted() {
    this.$nextTick(() => {
      const cssText = `
      @media only screen and (max-width: 768px)  {
      .degree-table td:nth-of-type(6):before { content: "Bằng cấp/ hình thức đào tạo"; top: 8px; }
      .job-table td:nth-of-type(4):before { content: "Chức vụ"; }
      .job-table td:nth-of-type(6):before { content: "Lý do nghỉ việc"; }
      .job-table td:nth-of-type(7):before { content: "Nhiệm vụ và trách nhiệm cụ thể"; top: 8px; }
      .referrer-table td:nth-of-type(2):before { content: "Chức vụ"; }
      .referrer-table td:nth-of-type(3):before { content: "Đơn vị công tác"; }
      }
      `;
      const css = document.createElement('style');
      css.type = 'text/css';
      css.setAttributeNode(document.createAttribute('scopped'));
      css.appendChild(document.createTextNode(cssText));
      this.$el.appendChild(css);
      window.addEventListener('resize', () => {
        this.width = window.screen.width;
      });
      this.$store.dispatch(ActionTypeGuideline.GuidelineFilterNoCache, {
        params: {
          pagingParams: {
            limit: 100,
            page: 1
          }
        }
      });
      const names = [];
      Object.keys(this.referenceGroups).forEach((key) => {
        names.push({
          tableName: { _eq: key }
        });
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: names
          },
          onSuccess: (data: any) => {
            if (!data.length) {
              return;
            }
            data.forEach((record: any) => {
              if (record.tableName === ReferenceTableName.City && record.name !== 'Khác') {
                this.cityOptions2.push({
                  label: record.name,
                  value: record.id
                });
              }
              this[`${this.referenceGroups[record.tableName]}`].push({
                label: record.name,
                value: record.id
              });
              if (record.tableName === ReferenceTableName.LocalExpat &&
                record.name === 'Local' && !this.$props.form.localExpat) {
                this.$props.form.localExpat = record.id;
              }
              // tslint:disable-next-line: early-exit
              if (record.tableName === ReferenceTableName.Qualifications) {
                this.qualificationsOptions2.push({
                  label: record.name,
                  value: record.name
                });
              }
            });
          }
        }
      });
    });
  }

  public reverse(arr: any[]) {
    return arr.slice().reverse();
  }

  public format(date: string, isFull: boolean = true) {
    if (!date) {
      return '';
    }
    if (!isFull) {
      return moment(new Date(date)).format('MM/YYYY');
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  public async onChange(file: any, fileList: any[]) {
    fileList[0] = file;
    fileList.pop();
    if (this.$props.form.avatar && !this.$props.form['oldAvatar']) {
      this.$props.form['oldAvatar'] = this.$props.form.avatar;
    }
    this.$props.form.avatar = file.raw;
    this.avatarHolder = URL.createObjectURL(file.raw);
  }

  public handleRemove(field: string, index: number) {
    const oldField = `old${field}`;
    const rawField = `raw${field}`;
    if (this.$props.form[field][index][0] === 'h') {
      if (!this.$props.form[oldField]) {
        this.$props.form[oldField] = [];
      }
      this.$props.form[oldField].push(this.$props.form[field][index]);
    } else {
      if (!this.$props.form[rawField]) {
        this.$props.form[rawField] = {};
      }
      delete this.$props.form[rawField][this.$props.form[field][index]];
    }
    this.$props.form[field].splice(index, 1);
  }

  public onChangeCMND(file: any, fileList: any[]) {
    if (this.$props.form.IdImg.length === 2) {
      this.$message.warning('Vượt giới hạn 2 file!');

      return;
    }
    fileList[0] = file;
    fileList.pop();
    let url = URL.createObjectURL(file.raw);
    if (file.raw.type === 'application/pdf') {
      url += '.pdf';
    }
    this.$props.form.IdImg.push(url);
    if (!this.$props.form['rawIdImg']) {
      this.$props.form['rawIdImg'] = {};
    }
    this.$props.form['rawIdImg'][url] = file.raw;
  }

  public onChangeHoKhau(file: any, fileList: any[]) {
    if (this.$props.form.houseHoldImg.length === 20) {
      this.$message.warning('Vượt giới hạn 20 file!');

      return;
    }
    fileList[0] = file;
    fileList.pop();
    let url = URL.createObjectURL(file.raw);
    if (file.raw.type === 'application/pdf') {
      url += '.pdf';
    }
    this.$props.form.houseHoldImg.push(url);
    if (!this.$props.form['rawhouseHoldImg']) {
      this.$props.form['rawhouseHoldImg'] = {};
    }
    this.$props.form['rawhouseHoldImg'][url] = file.raw;
  }

  public onChangeDegree(file: any, fileList: any[]) {
    if (this.$props.form.degreeImg.length === 5) {
      this.$message.warning('Vượt giới hạn 5 file!');

      return;
    }
    fileList[0] = file;
    fileList.pop();
    let url = URL.createObjectURL(file.raw);
    if (file.raw.type === 'application/pdf') {
      url += '.pdf';
    }
    this.$props.form.degreeImg.push(url);
    if (!this.$props.form['rawdegreeImg']) {
      this.$props.form['rawdegreeImg'] = {};
    }
    this.$props.form['rawdegreeImg'][url] = file.raw;
  }

  public onChangeCertificate(file: any, fileList: any[]) {
    if (this.$props.form.certificateImg.length === 5) {
      this.$message.warning('Vượt giới hạn 5 file!');

      return;
    }
    fileList[0] = file;
    fileList.pop();
    let url = URL.createObjectURL(file.raw);
    if (file.raw.type === 'application/pdf') {
      url += '.pdf';
    }
    this.$props.form.certificateImg.push(url);
    if (!this.$props.form['rawcertificateImg']) {
      this.$props.form['rawcertificateImg'] = {};
    }
    this.$props.form['rawcertificateImg'][url] = file.raw;
  }

  public onChangeHealth(file: any, fileList: any[]) {
    if (this.$props.form.healthImg.length === 20) {
      this.$message.warning('Vượt giới hạn 20 file!');

      return;
    }
    fileList[0] = file;
    fileList.pop();
    let url = URL.createObjectURL(file.raw);
    if (file.raw.type === 'application/pdf') {
      url += '.pdf';
    }
    this.$props.form.healthImg.push(url);
    if (!this.$props.form['rawhealthImg']) {
      this.$props.form['rawhealthImg'] = {};
    }
    this.$props.form['rawhealthImg'][url] = file.raw;
  }

  public addMember(array: any[]) {
    array.push({
      focus: true,
      fullName: '',
      relationship: '',
      profession: '',
      phone: '',
      dob: ''
    });
  }

  public checkMember(array: any[], index: number) {
    let check: boolean = false;
    let ok: boolean = true;
    array.forEach((e) => {
      if (e.familyHead) {
        if (check) {
          this.$message.error(this.$t('family_head_exsisted').toString());
          ok = false;
        } else {
          check = true;
        }
      }

      return;
    });
    array[index].focus = ok ? false : true;
  }

  public addDegree(array: any[]) {
    array.push({
      focus: true,
      startDate: '',
      endDate: '',
      organization: '',
      level: '',
      major: '',
      degree: ''
    });
  }

  public addCertificate(array: any[]) {
    array.push({
      focus: true,
      startDate: '',
      endDate: '',
      name: '',
      organization: ''
    });
  }

  public addJob(array: any[]) {
    array.push({
      focus: true,
      startDate: '',
      endDate: '',
      companyName: '',
      position: '',
      income: '',
      reason: '',
      responsibility: ''
    });
  }

  public addReferrer(array: any[]) {
    array.push({
      focus: true,
      name: '',
      position: '',
      pow: '',
      contact: '',
      relationship: ''
    });
  }

  public optionChange(value: string, options: string) {
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
      params: {
        pagingParams: this.pagingParams,
        filterParams: {
          superior: value
        },
        onSuccess: (data: any) => {
          this[options] = [];
          if (!data.length) {
            return;
          }
          data.forEach((record: any) => {
            this[options].push({
              label: record.name,
              value: record.id
            });
          });
        }
      }
    });
  }

  public open(link: string) {
    if (link[0] === 'b') {
      window.open(link.slice(0, -4), '_blank');
    } else {
      window.open(link, '_blank');
    }
  }

  public validateId(value: string) {
    const date = new Date(value);
    const now = new Date();
    date.setFullYear(date.getFullYear() + 15);
    if (date.getTime() < now.getTime()) {
      this.$message.error(this.$t('id_expired').toString());
      this.$props.form.idDoi = '';
    }
  }

  public jumpTo(num: number) {
    setTimeout(() => {
      window.scrollTo(0, this.$refs[`collapse-${num}`]['offsetTop']);
    }, 500);
  }

  public setRow(name: string, num: number) {
    const index = this.$props.form[name].length - 1 - num;
    for (const key of Object.keys(this.$props.form[name][index])) {
      if (key === 'focus' || !this.$props.form[name][index][key]) {
        continue;
      }
      this.$set(this.$props.form[name][index], 'focus', false);

      return;
    }
    this.$props.form[name].splice(index, 1);
  }

  public transformHtml(text: any) {
    return text.split('<a ').join('<a target="_new"');
  }
}
