import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    items: Array
  }
})
export class HPSelect extends Vue {
  public value: string = '';
  public getValue(value: string) {
    this.$emit('getValue', value);
  }
}
