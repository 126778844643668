import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'shortcuts';

const model = `id
  name
  description
  `;

export const ShortcutQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
