import { HPDropdown, HPSearch } from 'root/components';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeReference } from './Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-dropdown': HPDropdown,
    'hp-search': HPSearch
  },
  watch: {
    $route(to) {
      this.addNew = to.path === '/references/new' ? true : false;
      this.name = to.path.split('/')[2] === 'edit' ? this.name : '';
    }
  }
})
export class Reference extends Vue {
  public addNew: boolean = false;
  public name: string = '';

  public status: string = 'all';

  public statuses: any[] = [
    {
      value: 'Tất cả',
      key: 'all'
    },
    {
      value: 'Hoạt động',
      key: 'active'
    },
    {
      value: 'Không hoạt động',
      key: 'inactive'
    }
  ];

  public mounted() {
    this.addNew = this.$route.path === '/references/new' ? true : false;
  }

  public importNewReferenceData() {
    this.$router.push('/references/new');
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    this.status = actions[0];
  }

  public handleFilter() {
    const filter: any = this.status !== 'all' ?
      { status: { _eq: this.status } } : {};
    this.$store.dispatch(ActionTypeReference.ReferenceFilterChange, {
      params: {
        _and: [
          { ...filter }
        ]
      }
    });
  }

  public handleSearch(keyWord: string) {
    this.$store.dispatch(ActionTypeReference.ReferenceFilterChange, {
      params: {
        name: {
          searchMode: true,
          text: keyWord
        }
      }
    });
  }
}
