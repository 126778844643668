import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    isActive: Number
  }
})
export class HPSteps extends Vue {

}
