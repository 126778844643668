import lodash from 'lodash';
import { hpGqlClient } from 'root/api';
import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown, HPSearch } from 'root/components';
import { rulePhoneNumber, ruleRequired } from 'root/helpers';
import * as Excel from 'root/helpers/Export2Excel.js';
import { EmployeeStatus, IUser, Permissions } from 'root/models';
import { getFirebaseApp, uploadToFirebase } from 'root/services';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../ProfileStore';
import { ActionTypeUser } from '../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-search': HPSearch,
    'hp-dropdown': HPDropdown
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data,
      pagination: (state: IState) => state.user.pagination,
      authUser: (state: IState) => state.global.authUser
    })
  },
  watch: {
    $route(to) {
      this.newForm = to.path === '/candidates/new' ? true : false;
    }
  }
})

export class CandidateList extends Vue {
  public authUser: IUser;
  public pagination: IPaginationParams;
  public newForm: boolean = false;
  public showForm: boolean = false;
  public inputCc: string = null;
  public ccDict: any = {};
  public profileId: string = '';
  public form = {
    contactPerson: null,
    contactNumber: null,
    workingLocation: null,
    startTime: null,
    emailCc: []
  };
  public data: IUser[];
  public exportUsers: IUser[] = [];
  public loading: boolean = false;
  public loading1: boolean = false;
  public loading2: boolean = false;
  public attch: any = null;
  public emailSending: boolean = false;
  public filter: any[] = [];
  public employee: boolean = false;
  public employeePending: boolean = false;
  public employeeComplete: boolean = false;
  public hr: boolean = false;
  public hrPending: boolean = false;
  public hrComplete: boolean = false;
  public hris: boolean = false;
  public hrisPending: boolean = false;
  public hrisComplete: boolean = false;
  public contract: boolean = false;
  public contractPending: boolean = false;
  public contractSigned: boolean = false;

  public filters: any = { status: 'all', startDate: [] };
  public candidateStatus: any[] = [
    { value: 'Tất cả', key: 'all' },
    { value: 'Mới', key: EmployeeStatus.New },
    { value: 'Đang hoạt động', key: EmployeeStatus.Active },
    { value: 'Đã từ chối', key: EmployeeStatus.Rejected },
    { value: 'Đã nghỉ việc', key: EmployeeStatus.Quit },
    { value: 'Ứng tuyển lại', key: EmployeeStatus.Rehire }
  ];

  public get rules() {
    return {
      required: ruleRequired(),
      phone: rulePhoneNumber()
    };
  }

  public mounted() {
    this.newForm = this.$route.path === '/candidates/new' ? true : false;
    this.$store.dispatch(ActionTypeProfile.ProfileFilterNoCache, {
      params: {
        filterParams: {
          userId: this.$route.path.split('/')[3]
        },
        onSuccess: (res) => {
          if (!res[0]) {
            return;
          }
          this.profileId = res[0].id;
          res[0].emailCc.map((e) => {
            this.ccDict[e] = 1;
          });
          this.form = {
            ...lodash.pick(res[0], ['contactPerson', 'contactNumber', 'workingLocation', 'startTime', 'emailCc'])
          };
        },
        onFailure: (err) => {
          this.$message.error(this.$t(err.result).toString());
        }
      }
    });
  }

  public reset() {
    this.employee = false;
    this.employeeComplete = false;
    this.employeePending = false;
    this.hr = false;
    this.hrPending = false;
    this.hrComplete = false;
    this.hris = false;
    this.hrisPending = false;
    this.hrisComplete = false;
    this.contract = false;
    this.contractPending = false;
    this.contractSigned = false;
  }

  public addCc() {
    this.inputCc = this.inputCc ? this.inputCc.trim() : '';
    if (!this.inputCc || this.ccDict[this.inputCc]) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    const pattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(this.inputCc)) {
      this.$message.warning(this.$t('invalid_email').toString());

      return;
    }
    if (this.form.emailCc.length === 5) {
      this.$message.warning(this.$t('max_number_cc').toString());

      return;
    }
    this.form.emailCc.push(this.inputCc);
    this.ccDict[this.inputCc] = 1;
    this.inputCc = '';
  }

  public removeCc(index: number) {
    delete this.ccDict[this.form.emailCc[index]];
    this.form.emailCc.splice(index, 1);
  }

  public createNewProfileByUpload() {
    this.$router.push('/candidates/new');
  }

  public createNewProfileByForm() {
    this.$router.push('/candidates/new');
  }

  public handleSearch(keyWord: string) {
    this.$store.dispatch(ActionTypeUser.UserFilterChange, {
      params: {
        _or: [
          {
            fullName: {
              _ilike: `%${keyWord.trim()}%`
            }
          },
          {
            email: {
              _ilike: `%${keyWord.trim()}%`
            }
          }
        ]
      }
    });
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    this.filters[actions[4]] = actions[0];

    return;
  }

  public async handleFilter() {
    let filter1: any;
    let filter2: any;
    let filter3: any;
    let filter4: any;
    let filter5: any;
    let filter6: any;
    if (this.employee && (!this.employeePending || !this.employeeComplete)) {
      if (this.employeePending) {
        filter1 = {
          profileOfUser: {
            progress: {
              _contains: { personalInfo: false }
            }
          }
        };
      }
      if (this.employeeComplete) {
        filter1 = {
          profileOfUser: {
            progress: {
              _contains: { personalInfo: true }
            }
          }
        };
      }
    }
    if (this.hris && (!this.hrisPending || !this.hrisComplete)) {
      if (this.hrisPending) {
        filter2 = {
          profileOfUser: {
            progress: {
              _contains: { hrisImported: false }
            }
          }
        };
      }
      if (this.hrisComplete) {
        filter2 = {
          profileOfUser: {
            progress: {
              _contains: { hrisImported: true }
            }
          }
        };
      }
    }
    if (this.hr && (!this.hrPending || !this.hrComplete)) {
      if (this.hrPending) {
        filter3 = {
          profileOfUser: {
            _or: [
              {
                progress: {
                  _contains: { salary: false }
                },
              },
              {
                progress: {
                  _contains: { contract: false }
                },
              },
              {
                progress: {
                  _contains: { workingPosition: false }
                }
              },
              {
                progress: {
                  _contains: { bonus: false }
                }
              }
            ]
          }
        };
      }
      if (this.hrComplete) {
        filter3 = {
          profileOfUser: {
            progress: {
              _contains: {
                bonus: true,
                salary: true,
                contract: true,
                workingPosition: true
              }
            }
          }
        };
      }
    }
    if (this.filters.startDate && this.filters.startDate.length) {
      filter4 = {
        profileOfUser: {
          _and: [
            { startDate: { _gte: this.filters.startDate[0] } },
            { startDate: { _lte: this.filters.startDate[1] } }
          ]
        }
      };
    }
    if (this.filters.status !== 'all') {
      filter5 = {
        profileOfUser: {
          employeeStatus: { _eq: this.filters.status }
        }
      };
    }
    if (this.contract && (!this.contractPending || !this.contractSigned)) {
      if (this.contractPending) {
        filter6 = {
          profileOfUser: {
            _not: {
              progress: {
                _contains: { contractSigned: true }
              }
            }
          }
        };
      }
      if (this.contractSigned) {
        filter6 = {
          profileOfUser: {
            progress: {
              _contains: { contractSigned: true }
            }
          }
        };
      }
    }
    this.filter = [
      { role: { _eq: 'guest' }},
      { ...filter1 },
      { ...filter2 },
      { ...filter3 },
      { ...filter4 },
      { ...filter5 },
      { ...filter6 }
    ];
    if (this.authUser.role !== Permissions.SuperAdmin &&
      this.authUser.role !== Permissions.Admin) {
      this.filter.push({ createdBy:
        { _eq: this.authUser.id }
      });
    }
    this.$store.dispatch(ActionTypeUser.UserFilterChange, {
      params: {
        _and: this.filter
      }
    });
  }

  public getFullName(obj: any) {
    if (!obj) {
      return '';
    }
    if (!obj.middleName) {
      return `${obj.lastName} ${obj.firstName}`;
    }

    return `${obj.lastName} ${obj.middleName} ${obj.firstName}`;
  }

  public async sendEmail() {
    this.emailSending = true;
    let url = null;
    if (this.attch) {
      url = await uploadToFirebase(this.attch, `${this.$route.path.split('/')[3]}/jds/`);
    }
    this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
      id: this.profileId,
      form: {
        ...this.form
      },
      onSuccess: () => {
        hpGqlClient.user.sendEmail(this.$route.path.split('/')[3], url)
          .then((res: any) => {
            if (!url) {
              this.$message.success(this.$t(res.result).toString());
              this.emailSending = false;

              return;
            }
            getFirebaseApp().storage().refFromURL(url).delete()
              .then(() => {
                this.$message.success(this.$t(res.result).toString());
                this.emailSending = false;
              })
              .catch((err) => {
                console.log(err);
                this.emailSending = false;
              });
          })
          .catch((error: any) => {
            this.$message.error(this.$t(error.result).toString());
            this.emailSending = false;
          });
      },
      onFailure: (err) => {
        this.$message.error(this.$t(err.result).toString());
      }
    });
  }

  public async onChange(file: any) {
    this.attch = file.raw;
  }

  public async onRemove() {
    this.attch = null;
  }

  public async exportData(referrers: boolean = false, candidates: boolean = false) {
    try {
      if (referrers) {
        this.loading1 = true;
      } else if (candidates) {
        this.loading2 = true;
      } else {
        this.loading = true;
      }
      let employee = 'all';
      if (this.employee && this.employeePending && !this.employeeComplete) {
        employee = 'pending';
      }
      if (this.employee && !this.employeePending && this.employeeComplete) {
        employee = 'done';
      }
      let hr = 'all';
      if (this.hr && this.hrPending && !this.hrComplete) {
        hr = 'pending';
      }
      if (this.hr && !this.hrPending && this.hrComplete) {
        hr = 'done';
      }
      let hris = 'all';
      if (this.hris && this.hrisPending && !this.hrisComplete) {
        hris = 'pending';
      }
      if (this.hris && !this.hrisPending && this.hrisComplete) {
        hris = 'done';
      }
      let contractSigned = 'all';
      if (this.contract && this.contractPending && !this.contractSigned) {
        contractSigned = 'pending';
      }
      if (this.contract && !this.contractPending && this.contractSigned) {
        contractSigned = 'done';
      }
      const res = await hpGqlClient.user.getExportData(employee, hr, hris, contractSigned,
        this.filters.status, this.authUser.id, this.filters.startDate, referrers, candidates);
      if (!res[0]) {
        if (referrers) {
          this.loading1 = false;
        } else if (candidates) {
          this.loading2 = false;
        } else {
          this.loading = false;
        }

        return;
      }
      const header = Object.keys(res[0]);
      const arr = [];
      res.forEach((row) => {
        const temp = [];
        Object.keys(row).forEach((key) => {
          temp.push(row[key]);
        });
        arr.push(temp);
      });
      Excel.export_json_to_excel({
        header,
        data: arr,
        filename: referrers ? `${Date.now()}_Danh_sách_tham_chiếu` :
          candidates ? `${Date.now()}_Danh_sách_ứng_viên_biên_tập_viên` : `${Date.now()}_Danh_sách_ứng_viên`,
        autoWidth: true,
        bookType: 'xlsx'
      });
      if (referrers) {
        this.loading1 = false;
      } else if (candidates) {
        this.loading2 = false;
      } else {
        this.loading = false;
      }
    } catch {
      if (referrers) {
        this.loading1 = false;
      } else if (candidates) {
        this.loading2 = false;
      } else {
        this.loading = false;
      }
      this.$message.error(this.$t('something_went_wrong').toString());
    }
  }

  public async exportCandidates() {
    try {
      this.loading2 = true;
      const res = await hpGqlClient.user.exportCandidates();
      if (!res[0]) {
        this.loading2 = false;

        return;
      }
      const header = Object.keys(res[0]);
      const arr = [];
      res.forEach((row) => {
        const temp = [];
        Object.keys(row).forEach((key) => {
          temp.push(row[key]);
        });
        arr.push(temp);
      });
      Excel.export_json_to_excel({
        header,
        data: arr,
        filename: `${Date.now()}_Danh_sách_ứng_viên_biên_tập_viên`,
        autoWidth: true,
        bookType: 'xlsx'
      });
      this.loading2 = false;
    } catch {
      this.loading2 = false;
      this.$message.error(this.$t('something_went_wrong').toString());
    }
  }
}
