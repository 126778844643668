import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'users';

const model = `id
  fullName
  email
  role
  lockedAccess
  profile
  publicToken
  profileOfUser {
    avatar
    firstName
    middleName
    lastName
    department
    unit
    team
    phone
    positionCode
    startDate
    progress
    salaryTotal
    staffId
    employeeStatus
    positionText
    oldProfile
    isRehired
    level {
      name
    }
  }
  creator {
    fullName
  }
  `;

export const UserQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
