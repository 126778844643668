import { HttpService, IHttpServiceOptions } from '../Core';

import { GlobalHttpService, IGlobalHttpService } from './Global';
import { IProfileHttpService, ProfileHttpService } from './Profile';
import { IReferenceHttpService, ReferenceHttpService } from './Reference';
import { IUserHttpService, UserHttpService } from './User';

export interface IHPHttpClient {
  global: IGlobalHttpService;
  profile: IProfileHttpService;
  user: IUserHttpService;
  reference: IReferenceHttpService;
}

export function HPHttpClient(options: IHttpServiceOptions): IHPHttpClient {

  const httpService = HttpService(options);

  return {
    global: GlobalHttpService({ httpService }),
    profile: ProfileHttpService({ httpService }),
    user: UserHttpService({ httpService }),
    reference: ReferenceHttpService({ httpService })
  };
}
