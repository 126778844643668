
import { Permissions } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  },
  watch: {
    authUser(val) {
      if (val.role === Permissions.Admin) {
        this.$router.push('/users');
      } else {
        this.$router.push('/candidates');
      }
    }
  }
})

export class General extends Vue {
  public authUser: any;
}
