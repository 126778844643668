import { hpGqlClient } from 'root/api';
import { HPPage } from 'root/components';
import { ruleEmail, rulePhoneNumber, ruleRequired } from 'root/helpers';
import { staffFormDefault, IStaffForm, IUser, ReferenceTableName } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../../ProfileStore';
import { ActionTypeUser } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-page': HPPage
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data,
      authUser: (state: IState) => state.global.authUser
    })
  },
  beforeRouteLeave(_, __, next) {
    if (this.noWarning) {
      window.removeEventListener('beforeunload', this.listener);
      next();

      return;
    }
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  },
  watch: {
    'form.department'(val, old) {
      if (!val || val.length === 36) {
        return;
      }
      if (old && old.length > 36) {
        this.form.unit = '';
        this.form.team = '';
      }
      this.unitOptions = [];
      this.teamsOfDepartment = [];
      this.teamOptions = [];
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            superior: val.split('_')[1],
            tableName: ReferenceTableName.unitTable
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this.form.unit = this.form.unit === record.id ?
              `${record.id}_${record.name}` : this.form.unit;
              this.unitOptions.push({
                label: record.name,
                value: `${record.id}_${record.name}`
              });
            });
          }
        }
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            superior: val.split('_')[1],
            tableName: ReferenceTableName.teamTable
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this.teamsOfDepartment.push({
                label: record.name,
                value: record.id
              });
            });
          }
        }
      });
    },
    'form.unit'(val, old) {
      if (old && old.length > 36) {
        this.form.team = '';
      }
      if (!val || val.length === 36) {
        this.teamOptions = [];
        if (!this.form.department) {
          return;
        }
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
          params: {
            pagingParams: this.pagingParams,
            filterParams: {
              superior: this.form.department.split('_')[1],
              tableName: ReferenceTableName.teamTable
            },
            onSuccess: (data: any) => {
              data.forEach((record: any) => {
                this.teamsOfDepartment.push({
                  label: record.name,
                  value: record.id
                });
              });
            }
          }
        });

        return;
      }
      this.teamsOfDepartment = [];
      this.teamOptions = [];
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            superior: val.split('_')[1],
            tableName: ReferenceTableName.teamTable
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this.teamOptions.push({
                label: record.name,
                value: record.id
              });
            });
          }
        }
      });
    }
  }
})

export class StaffCreateForm extends Vue {
  public data: IUser[];
  public authUser: any;
  public form: IStaffForm = staffFormDefault();
  public noWarning: boolean = false;
  public pagingParams: any = {
    limit: 240,
    page: 1
  };
  public roleOptions: any[] = [
    {
      label: 'Quản trị viên',
      value: 'adminuser'
    },
    {
      label: 'Biên tập viên',
      value: 'user'
    }
  ];
  public defaultOption: any = {
    label: '',
    value: ''
  };
  public departmentOptions: any[] = [];
  public unitOptions: any[] = [];
  public teamsOfDepartment: any[] = [];
  public teamOptions: any[] = [];

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      phone: rulePhoneNumber()
    };
  }

  public mounted() {
    this.$nextTick(async () => {
      window.addEventListener('beforeunload', this.listener);
      if (this.$route.path !== '/users/new') {
        this.$store.dispatch(ActionTypeUser.UserFindById, {
          id: this.$route.params.id,
          onSuccess: (data) => {
            if (!data[0]) {
              return;
            }
            document.title = `FE CREDIT - ${data[0].fullName}`;
            this.form = {
              phone: data[0]['profileOfUser'] && data[0]['profileOfUser'].phone || null,
              department: data[0]['profileOfUser'] && data[0]['profileOfUser'].department || '',
              unit: data[0]['profileOfUser'] && data[0]['profileOfUser'].unit || '',
              team: data[0]['profileOfUser'] && data[0]['profileOfUser'].team || '',
              fullName: data[0].fullName,
              email: data[0].email.split('@')[0],
              role: data[0].role,
              password: '123456'
            };
          }
        });
      }
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            tableName: ReferenceTableName.departmentTable
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this.form.department = this.form.department === record.id ?
              `${record.id}_${record.name}` : this.form.department;
              this.departmentOptions.push({
                label: record.name,
                value: `${record.id}_${record.name}`
              });
            });
          }
        }
      });
    });

    return;
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public cancelForm() {
    this.$router.push('/users');
  }

  public async submitForm() {
    Object.keys(this.form).forEach((key) => {
      this.form[key] = typeof this.form[key] === 'string' && this.form[key] ? this.form[key].trim() : this.form[key];
    });
    const { phone, fullName, password, role } = this.form;
    const email = `${this.form.email}@fecredit.com.vn`;
    // tslint:disable-next-line: max-line-length
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.form.email || !pattern.test(email)) {
      this.$message.error(this.$t('invalid_email').toString());

      return;
    }
    pattern = /^[0-9]+\s*$/;
    if (phone && (phone.length !== 10 || !pattern.test(phone))) {
      this.$message.error(this.$t('invalid_phone_number').toString());

      return;
    }
    if (!phone || !fullName || !password || !role || !email) {
      this.$message.error(this.$t('required_fields_cannot_be_empty').toString());

      return;
    }
    if (this.$route.path === '/users/new') {
      hpGqlClient.user.createUser({
        fullName,
        role,
        password,
        email: `${this.form.email}@fecredit.com.vn`,
        createdBy: this.authUser.id
      })
      .then((user) => {
        this.$store.dispatch(ActionTypeProfile.ProfileCreate, {
          form: {
            phone,
            email,
            department: this.form.department ? this.form.department.split('_')[0] : '',
            unit: this.form.unit ? this.form.unit.split('_')[0] : '',
            team: this.form.team ? this.form.team.split('_')[0] : '',
            userId: user.id,
            updatedBy: this.authUser.id
          },
          onSuccess: (profile) => {
            this.$store.dispatch(ActionTypeUser.UserUpdate, {
              id: user.id,
              form: {
                profile: profile.id
              },
              onSuccess: () => {
                this.$message.success(this.$t('user_created_successfully').toString());
                this.noWarning = true;
                this.$router.push('/users');
              },
              onFailure: (error) => {
                this.$message.error(this.$t(error.message.substring(15)).toString());
              }
            });
          },
          onFailure: (error) => {
            this.$store.dispatch(ActionTypeUser.UserUpdate, {
              id: user.id,
              form: {
                status: 'deleted'
              }
            });
            this.$message.error(this.$t(error.message.substring(15)).toString());
          }
        });
      })
      .catch((error) => {
        this.$message.error(this.$t(error.message.substring(15)).toString());
      });
    } else {
      this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
        id: this.data[0].profile,
        form: {
          phone,
          email,
          department: this.form.department ? this.form.department.split('_')[0] : '',
          unit: this.form.unit ? this.form.unit.split('_')[0] : '',
          team: this.form.team ? this.form.team.split('_')[0] : '',
          updatedBy: this.authUser.id
        },
        onSuccess: () => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id: this.data[0].id,
            form: {
              fullName,
              role,
              email,
              updatedBy: this.authUser.id
            },
          })
          .then(() => {
            this.$message.success(this.$t('user_updated_successfully').toString());
          })
          .catch((error) => {
            this.$message.error(this.$t(error.message.substring(15)).toString());
          });
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    }
  }
}
