import { stripObject } from '@hgiasac/helper';
// import { IOptionsHTTP } from 'api/http/Core';
import { DocumentNode } from 'graphql';
import { IGqlQueryOption, PaginationParams } from 'root/api/graphql/Core';
import { ActionContext } from 'vuex';
import { ActionType, IState } from '../types';
// import { IPaginationState } from '../types';
import { ICRUDFilterState, ICRUDState, IOptionCRUD } from './mutations';

// export interface ICRUDActionOptions<T, F> {
//   filter?(params: F, pagingParams: any, optionsHTTP?: IOptionsHTTP): Promise<any>;
//   list?(params?: any, optionsHTTP?: IOptionsHTTP): Promise<T[]>;
//   getById?(id: number | string, optionsHTTP?: IOptionsHTTP): Promise<T>;
//   create?(form: any, optionsHTTP?: IOptionsHTTP): Promise<T>;
//   update?(id: number | string, form: any, optionsHTTP?: IOptionsHTTP): Promise<T>;
//   deleteById?(id: number | string, optionsHTTP?: IOptionsHTTP): Promise<number>;
// }

// export interface IOptionFunctionFilterCRUD {
//   disableLoading?: boolean;
// }

// export function crudActions<T extends object, F = any>(
//   name: string, options: ICRUDActionOptions<T, F>, optionCRUD?: IOptionCRUD) {

//   let keyState;
//   if (optionCRUD && optionCRUD.keyState) {
//     keyState = optionCRUD.keyState;
//   }
//   function getDataState(state: ICRUDState<T>, key: string) {
//     if (keyState) {
//       return state[keyState][key];
//     }

//     return state[key];
//   }

//   function filter(
//     { commit, dispatch, state, params },
//     opts: IOptionFunctionFilterCRUD = {},
//     optionsHTTP?: IOptionsHTTP
//   ) {
//     if (!opts.disableLoading) {
//       commit(`${name}Loading`);
//     }

//     if (params.filterParams) {
//       commit(`${name}FilterChange`, params.filterParams);
//     } else {
//       params.filterParams = getDataState(state, 'filterParams');
//     }

//     const pagination = getDataState(state, 'pagination');
//     options.filter(params.filterParams, {
//       page: pagination.page,
//       size: pagination.size
//     }, optionsHTTP).then((results) => {
//       if (!opts.disableLoading) {
//         commit(`${name}Loaded`);
//       }
//       const data = results.data,
//         filterResults: ICRUDFilterState<T> = results;

//       if (params.onSuccess) {
//         params.onSuccess(data);
//       }
//       commit(`${name}UpdateModels`, data);
//       commit(`${name}FilterModels`, filterResults);
//     }).catch((e) => {
//       if (!opts.disableLoading) {
//         commit(`${name}Loaded`);
//       }
//       dispatch(ActionType.CatchException, e);
//     });
//   }

//   return stripObject({
//     [`${name}Filter`]: !options.filter ? undefined : (
//       { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
//       { params = null, optionsHTTP = null } = {}
//     ) => {
//       if (getDataState(state, 'data').length && !params) { return; }
//       params = { ...params };

//       return filter({ commit, dispatch, state, params }, {}, optionsHTTP);
//     },
//     // [`${name}FilterHard`]: !options.filter ? undefined : (
//     //   { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
//     //   params
//     // ) => {
//     //   params = { ...params };

//     //   return filter({ commit, dispatch, state, params });
//     // },
//     [`${name}FilterNoCache`]: !options.filter ? undefined : (
//       { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
//       { params = {}, optionsHTTP = null } = {}
//     ) => {
//       params = { ...params };

//       return filter({ commit, dispatch, state, params }, {}, optionsHTTP);
//     },
//     [`${name}FilterNoCacheNoLoading`]: !options.filter ? undefined : (
//       { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
//       { params = {}, optionsHTTP = null } = {}
//     ) => {
//       params = { ...params };

//       return filter({ commit, dispatch, state, params }, {
//         disableLoading: true
//       }, optionsHTTP);
//     },
//     [`${name}FetchMany`]: !options.list ? undefined : (
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { params = {}, optionsHTTP = null } = {}
//     ) => {
//       commit(`${name}Loading`);
//       options.list(params, optionsHTTP).then((results) => {
//         commit(`${name}Loaded`);
//         commit(`${name}RefreshModels`, results);
//       }).catch((e) => {
//         commit(`${name}Loaded`);
//         dispatch(ActionType.CatchException, e);
//       });
//     },
//     // [`${name}FetchAll`]: !options.list ? undefined : (
//     //   { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//     //   params
//     // ) => {
//     //   commit(`${name}Loading`);
//     //   options.list(params).then((results) => {
//     //     commit(`${name}Loaded`);
//     //     commit(`${name}RefreshModels`, results);
//     //   }).catch((e) => {
//     //     commit(`${name}Loaded`);
//     //     dispatch(ActionType.CatchException, e);
//     //   });
//     // },
//     [`${name}FindById`]: !options.getById ? undefined : (
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { id, optionsHTTP }
//     ) => {
//       commit(`${name}Loading`);

//       return options.getById(id, optionsHTTP)
//         .then((model) => {
//           commit(`${name}Loaded`);
//           commit(`${name}RefreshModels`, [model]);

//         }).catch((e) => {
//           commit(`${name}Loaded`);
//           dispatch(ActionType.CatchException, e);
//         });
//     },
//     [`${name}Update`]: !options.update ? undefined : (
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { id, form, router, redirectPath, onSuccess, onFailure, optionsHTTP },
//     ) => {
//       commit(`${name}Loading`);

//       return options.update(id, form, optionsHTTP)
//         .then((result) => {
//           commit(`${name}Loaded`);
//           commit(`${name}UpdateModels`, [result]);

//           if (onSuccess) {
//             onSuccess(result);
//           }

//           if (router && redirectPath) {
//             router.replace(redirectPath);
//           }
//         }).catch((e) => {
//           commit(`${name}Loaded`);
//           dispatch(ActionType.CatchException, e);
//           if (onFailure) {
//             onFailure(e);
//           }
//         });
//     },
//     [`${name}Create`]: !options.create ? undefined : (
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { form, router, redirectPath, onSuccess, onFailure, optionsHTTP }
//     ) => {
//       commit(`${name}Loading`);

//       return options.create(form, optionsHTTP)
//         .then((result) => {
//           commit(`${name}Loaded`);
//           commit(`${name}AppendModel`, result);
//           if (onSuccess) {
//             onSuccess(result);
//           }

//           if (router && redirectPath) {
//             router.replace(redirectPath);
//           }
//         }).catch((e) => {
//           commit(`${name}Loaded`);
//           dispatch(ActionType.CatchException, e);
//           if (onFailure) {
//             onFailure(e);
//           }
//         });
//     },
//     [`${name}Delete`]: !options.deleteById ? undefined : (
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { id, onSuccess, onFailure, optionsHTTP }
//     ) => {
//       // commit(`${name}Loading`);

//       return options.deleteById(id, optionsHTTP)
//         .then(() => {
//           // commit(`${name}Loaded`);
//           commit(`${name}RemoveModelByIDs`, [id]);
//           if (onSuccess) {
//             onSuccess();
//           }
//         }).catch((e) => {
//           // commit(`${name}Loaded`);
//           dispatch(ActionType.CatchException, e);
//           if (onFailure) {
//             onFailure(e);
//           }
//         });
//     },
//     [`${name}FilterChange`](
//       { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
//       { params = {}, optionsHTTP = null } = {}
//     ) {
//       commit(`${name}FilterChange`, params);
//       const pagination = getDataState(state, 'pagination');
//       commit(`${name}PaginationChange`, {
//         ...pagination,
//         page: pagination.page = 1,
//       });
//       dispatch(`${name}Filter`, { params, optionsHTTP });
//     },
//     [`${name}PaginationChange`](
//       { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
//       { pagination = PaginationState(), optionsHTTP = null } = {}
//     ) {
//       commit(`${name}PaginationChange`, pagination);
//       const params = {};
//       dispatch(`${name}Filter`, { params, optionsHTTP });
//     },
//   });
// }

export interface ICRUDActionOptions<M> {
  createMany?(CREATE: DocumentNode, form: any[]): Promise<M[]>;
  filter?(query: DocumentNode, distinctOn: string, pagingParams?: any,
          orderParams?: any, options?: IGqlQueryOption): Promise<any>;
  getById?(query: DocumentNode, id: string): Promise<M>;
  update?(query: DocumentNode, id: string, form: any): Promise<M>;
  create?(query: DocumentNode, form: any): Promise<M>;
  deleteById?(query: DocumentNode, id: string): Promise<M>;
  fetchMany?(query: DocumentNode): Promise<M>;
  upsert?(query: DocumentNode, params: any, form: any): Promise<M>;
  upsertMany?(query: DocumentNode, params: any, form: any[]): Promise<M[]>;
}

export interface IOptionFunctionFilterCRUD {
  disableLoading?: boolean;
}

export function crudActions<T extends object>(
  name: string, options: ICRUDActionOptions<T>, optionCRUD: IOptionCRUD) {

  let keyState;
  if (optionCRUD && optionCRUD.keyState) {
    keyState = optionCRUD.keyState;
  }
  function getDataState(state: ICRUDState<T>, key: string) {
    if (keyState) {
      return state[keyState][key];
    }

    return state[key];
  }

  function filter(
    query: DocumentNode,
    { commit, state, params },
    opts: IOptionFunctionFilterCRUD = {},
    optionsHTTP?: IGqlQueryOption
  ) {
    if (!opts.disableLoading) {
      commit(`${name}Loading`);
    }

    if (params.filterParams) {
      commit(`${name}FilterChange`, params.filterParams);
    } else {
      params.filterParams = getDataState(state, 'filterParams');
    }

    if (params.orderParams) {
      commit(`${name}OrderChange`, params.orderParams);
    } else {
      params.orderParams = getDataState(state, 'orderParams');
    }
    const distinctOn = params.distinctOn ? params.distinctOn : '';
    let filterParams = optionsHTTP ? optionsHTTP.filterParams : {};
    filterParams = {
      ...filterParams,
      ...params.filterParams
    };

    const pagination = params.pagingParams ?
      { ...params.pagingParams } : getDataState(state, 'pagination');
    options.filter(query, distinctOn, pagination,
      params.orderParams, {
        ...optionsHTTP,
        filterParams: {
          // ...optionsHTTP.filterParams,
          ...filterParams
        }
      }).then((results) => {
        if (!opts.disableLoading) {
          commit(`${name}Loaded`);
        }
        const data = results.data,
          filterResults: ICRUDFilterState<T> = results;

        if (params.onSuccess) {
          params.onSuccess(data);
        }
        commit(`${name}UpdateModels`, data);
        commit(`${name}FilterModels`, filterResults);
      }).catch((e) => {
        if (!opts.disableLoading) {
          commit(`${name}Loaded`);
        }
        if (params.onFailure) {
          params.onFailure(e);
        }
        // dispatch(ActionType.CatchException, e);
      });
  }

  return stripObject({
    [`${name}FilterNoCache`]: !options.filter ? undefined : (
      { commit, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, opts = null } = {}
    ) => {
      params = { ...params };
      const useQuery = opts && opts.query ? opts.query : optionCRUD.queries.FILTER;

      return filter(useQuery, { commit, state, params }, {}, opts);
    },
    [`${name}FetchMany`]: !options.fetchMany ? undefined : (
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>
    ) => {
      commit(`${name}Loading`);
      options.fetchMany(optionCRUD.queries.FETCH_MANY).then((results) => {
        commit(`${name}Loaded`);
        if (results) {
          commit(`${name}RefreshModels`, results);
        }
      }).catch((e) => {
        commit(`${name}Loaded`);
        dispatch(ActionType.CatchException, e);
      });
    },
    [`${name}FindById`]: !options.getById ? undefined : (
      { commit }: ActionContext<ICRUDState<T>, IState>,
      { id, onSuccess, onFailure, opts }
    ) => {
      if (!(opts && opts.disableLoading)) {
        commit(`${name}Loading`);
      }

      const query = opts && opts.query ? opts.query : optionCRUD.queries.GET_BY_ID;

      return options.getById(query, id)
        .then((model) => {
          if (!(opts && opts.disableLoading)) {
            commit(`${name}Loaded`);
          }
          if (model[0]) {
            commit(`${name}RefreshModels`, [model[0]]);
          }
          if (onSuccess) {
            onSuccess(model);
          }

        }).catch((e) => {
          if (!(opts && opts.disableLoading)) {
            commit(`${name}Loaded`);
          }
          // dispatch(ActionType.CatchException, e);
          if (onFailure) {
            onFailure(e);
          }
        });
    },
    [`${name}Update`]: !options.update ? undefined : (
      { commit, rootState }: ActionContext<ICRUDState<T>, IState>,
      { id, form, router, redirectPath, onSuccess, onFailure, opts },
    ) => {
      commit(`${name}Mutating`);
      const authUser = rootState.global.authUser;

      const query = opts && opts.query ? opts.query : optionCRUD.queries.UPDATE;

      return options.update(query, id, {
        ...form,
        updatedBy: authUser.id
      }).then((result) => {
        commit(`${name}Mutated`);
        commit(`${name}UpdateModels`, [result]);

        if (onSuccess) {
          onSuccess(result);
        }

        if (router && redirectPath) {
          router.replace(redirectPath);
        }
      }).catch((e) => {
        commit(`${name}Mutated`);
        // dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      });
    },
    [`${name}Create`]: !options.create ? undefined : (
      { commit, rootState }: ActionContext<ICRUDState<T>, IState>,
      { form, router, redirectPath, onSuccess, onFailure }
    ) => {
      commit(`${name}Mutating`);
      const authUser = rootState.global.authUser;

      return options.create(optionCRUD.queries.CREATE, {
        ...form,
        createdBy: authUser.id
      }).then((result) => {
        commit(`${name}Mutated`);
        commit(`${name}InsertModel`, result);
        if (onSuccess) {
          onSuccess(result);
        }

        if (router && redirectPath) {
          router.replace(redirectPath);
        }
      }).catch((e) => {
        commit(`${name}Mutated`);
        // dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      });
    },
    [`${name}Upsert`]: !options.upsert ? undefined : (
      { commit, rootState }: ActionContext<ICRUDState<T>, IState>,
      { form, params, router, redirectPath, onSuccess, onFailure }
    ) => {
      commit(`${name}Mutating`);
      const authUser = rootState.global.authUser;

      return options.upsert(optionCRUD.queries.UPSERT, params, {
        ...form,
        createdBy: authUser.id
      }).then((result) => {
        commit(`${name}Mutated`);
        commit(`${name}UpsertModel`, result);
        if (onSuccess) {
          onSuccess(result);
        }

        if (router && redirectPath) {
          router.replace(redirectPath);
        }
      }).catch((e) => {
        commit(`${name}Mutated`);
        // dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      });
    },
    [`${name}UpsertMany`]: !options.upsertMany ? undefined : (
      { commit, rootState }: ActionContext<ICRUDState<T>, IState>,
      { form, params, router, redirectPath, onSuccess, onFailure }
    ) => {
      commit(`${name}Mutating`);
      const authUser = rootState.global.authUser;
      form = form.map((e) => {
        return {
          ...e,
          createdBy: authUser.id
        };
      });

      return options.upsertMany(optionCRUD.queries.UPSERT, params, form).then((result) => {
        commit(`${name}Mutated`);
        commit(`${name}InsertModel`, result);
        if (onSuccess) {
          onSuccess(result);
        }

        if (router && redirectPath) {
          router.replace(redirectPath);
        }
      }).catch((e) => {
        commit(`${name}Mutated`);
        // dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      });
    },
    [`${name}CreateMany`]: !options.create ? undefined : (
      { commit, rootState }: ActionContext<ICRUDState<T>, IState>,
      { form, router, redirectPath, onSuccess, onFailure }
    ) => {
      commit(`${name}Mutating`);
      const authUser = rootState.global.authUser;
      form = form.map((e) => {
        return {
          ...e,
          createdBy: authUser.id
        };
      });

      return options.createMany(optionCRUD.queries.CREATE, form).then((result) => {
        commit(`${name}Mutated`);
        commit(`${name}InsertModel`, result);
        if (onSuccess) {
          onSuccess(result);
        }

        if (router && redirectPath) {
          router.replace(redirectPath);
        }
      }).catch((e) => {
        commit(`${name}Mutated`);
        // dispatch(ActionType.CatchException, e);
        if (onFailure) {
          onFailure(e);
        }
      });
    },
    [`${name}Delete`]: !options.deleteById ? undefined : (
      { commit }: ActionContext<ICRUDState<T>, IState>,
      { id, onFailure, onSuccess }
    ) => {
      return options.deleteById(optionCRUD.queries.DELETE, id)
        .then(() => {
          commit(`${name}RemoveModelByIDs`, [id]);
          if (onSuccess) {
            onSuccess();
          }
        }).catch((e) => {
          // dispatch(ActionType.CatchException, e);
          if (onFailure) {
            onFailure(e);
          }
        });
    },
    [`${name}FilterChange`](
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) {
      commit(`${name}FilterChange`, params);
      const pagination = getDataState(state, 'pagination');
      commit(`${name}PaginationChange`, {
        ...pagination,
        page: pagination.page = 1,
      });
      dispatch(`${name}FilterNoCache`, { optionsHTTP });
    },
    [`${name}OrderChange`](
      { commit, dispatch, state }: ActionContext<ICRUDState<T>, IState>,
      { params = {}, optionsHTTP = null } = {}
    ) {
      commit(`${name}OrderChange`, params);
      const pagination = getDataState(state, 'pagination');
      commit(`${name}PaginationChange`, {
        ...pagination,
        page: pagination.page = 1,
      });
      dispatch(`${name}FilterNoCache`, { optionsHTTP });
    },
    [`${name}PaginationChange`](
      { commit, dispatch }: ActionContext<ICRUDState<T>, IState>,
      { pagination = PaginationParams(), optionsHTTP = null } = {}
    ) {
      commit(`${name}PaginationChange`, pagination);
      const params = {};
      dispatch(`${name}FilterNoCache`, { params, optionsHTTP });
    },
  });
}
