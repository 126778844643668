import { IProfile } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IProfileGqlService
  extends ICRUDGqlService<IProfile> {
    //
}
export function ProfileGqlService(): IProfileGqlService {
  return {
    ...CRUDGqlService<IProfile>('profiles'),
  };
}
