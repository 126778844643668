import { hpGqlClient } from 'root/api';
import { FooterGuest } from 'root/components';
import { HPCaptcha } from 'root/components/HPCaptcha';
import { ruleEmail, ruleRequired } from 'root/helpers';
import { RouterDictionaryName } from 'root/models';
import { HPAuth, IFormLogin } from 'root/services';
import { ActionType, HPToken } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Route } from 'vue-router';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'footer-guest': FooterGuest,
    'my-captcha': HPCaptcha
  },
  computed: {
  },
  beforeRouteLeave: async (to: Route, _, next) => {
    const isRequired = !to.meta.isGuest
      && (to.name !== RouterDictionaryName.SignUp.toString()
          && to.name !== RouterDictionaryName.ForgotPassword.toString()),
      isAuth = HPAuth.getAccessToken();

    if (isRequired && !isAuth) {
      return next('/login');
    }

    return next();
  }
})

export class LoginContainer extends Vue {
  public email: string = '';
  public password: string = '';
  public loading: boolean = false;
  public count: number = 0;
  public form: IFormLogin = {
    email: '',
    password: ''
  };
  public $refs: {
    loginForm: any,
    captcha: any
  };

  public showPassword: boolean = false;
  public errorMessage: string = null;
  public invalid: boolean = false;

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }

  public onCaptchaVerified() {
    this.invalid = false;
  }

  public submit() {
    if (this.invalid) {
      return;
    }
    localStorage.removeItem(HPToken.accessToken);
    this.$refs.loginForm.validate(async (valid) => {
      if (valid) {
        this.loading = true;
        hpGqlClient.user.login(this.form.email, this.form.password)
          .then((user) => {
            this.loading = false;
            // this.$cookies.set('session', true);
            this.$store.dispatch(ActionType.ChangeStateAuth, { accessToken: user.token, router: this.$router });
          })
          .catch((error) => {
            this.loading = false;
            ++this.count;
            if (this.count > 3) {
              this.$refs.captcha.reset();
            }
            this.invalid = this.count >= 3 ? true : false;
            this.$message.error(this.$t(error.message.substring(15)).toString());
          });
      }

      return;
    });
  }
}
