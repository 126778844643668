import Vue from 'vue';
import Component from 'vue-class-component';

// components

// state
import { ActionType, IState, MutationType } from 'root/store';
import { mapState } from 'vuex';

import { HPNavigator } from 'root/components';
import { confirmAlert } from 'root/helpers';
import './home.scss';

Component.registerHooks([
  'onIdle',
  'onActive'
]);

@Component({
  template: require('./view.html'),
  components: {
    'hp-navigator': HPNavigator
  },
  computed: {
    ...mapState({
      isLoading: (state: IState) => state.global.isLoading
    }),
  }
})

export class HomeLayout extends Vue {
  public toggleNavCollapse() {
    this.$store.commit(MutationType.ToggleNavCollapse);
  }

  public onIdle() {
    const router = this.$router;
    confirmAlert(this, {
      title: 'Hết phiên làm việc',
      message: 'Vui lòng đăng nhập lại!',
      handleFunction: () => {
        this.$store.dispatch(ActionType.Unauthenticated, router);
      }
    });
  }
}
