
import { IReferenceRecord } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IReferenceRecordGqlService
  extends ICRUDGqlService<IReferenceRecord> {
    //
}
export function ReferenceRecordGqlService(): IReferenceRecordGqlService {
  return {
    ...CRUDGqlService<IReferenceRecord>('referenceRecords'),

  };
}

// async function insertMany(objects: IReferenceRecord[]) {
//   const query = gql`
//   mutation InsertReferenceRecords {
//     insert_referenceRecords(objects: $objects) {
//       returning {
//         id
//       }
//     }
//   }
//   `;
//   const result = await gqlClient.mutate({
//     query
//   });

//   if (result.errors) {
//     throw result.errors;
//   }

//   return result.data['insert_referenceRecords'];
// }
