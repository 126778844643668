import * as moment from 'moment';
// import { i18n } from 'root/locales';

export function formatTimeDuration(time: Date, locale?: string) {
  if (!time) {
    return '';
  }

  return moment(new Date(time)).locale(locale || 'vi').fromNow();
}

const roleText = {
  adminuser: 'Quản trị viên',
  user: 'Biên tập viên',
  guest: 'Ứng viên'
};

export function getRole(role: string): string {
  return roleText[role];
}

export function getFullName(obj: any, del: string = ' ') {
  if (!obj) {
    return '';
  }
  if (!obj.middleName) {
    return `${obj.lastName}${del}${obj.firstName}`;
  }

  return `${obj.lastName}${del}${obj.middleName}${del}${obj.firstName}`;
}
