import moment from 'moment';
import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown, HPPage } from 'root/components';
import { confirmAction } from 'root/helpers';
import { IUser } from 'root/models';
import { ActionTypeSignatory } from 'root/pages/Contracts/SignatoryStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.signatory.data,
      loading: (state: IState) => state.signatory.loading,
      pagination: (state: IState) => state.signatory.pagination,
      authUser: (state: IState) => state.global.authUser
    })
  },
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  }
})
export class SignatoryTable extends Vue {
  public authUser: any;
  public pagination: IPaginationParams;
  public data: IUser[];
  public listOption: number = 10;
  public listOptions: any[] = [
    { value: '10 kết quả mỗi trang', key: 10 },
    { value: '20 kết quả mỗi trang', key: 20 },
    { value: '50 kết quả mỗi trang', key: 50 }
  ];
  public actionOptions: any[] = [
    {
      value: 'Chi tiết',
      key: 'detail',
      icon: 'el-icon-view'
    },
    {
      value: 'Xoá người ký',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];

  public mounted() {
    this.$store.dispatch(ActionTypeSignatory.SignatoryFilterNoCache, {
      params: {
        filterParams: {},
        pagingParams: {
          limit: 10,
          page: 1
        },
      }
    });
  }

  public formatDate(date: string) {
    if (!date) {
      return '';
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    if (actions[2] === 'filter') {
      this.listOption = parseInt(actions[0], 10);
      this.$store.dispatch(ActionTypeSignatory.SignatoryFilterNoCache, {
        params: {
          pagingParams: {
            limit: this.listOption,
            page: 1
          }
        }
      });

      return;
    }
    const index = actions[1];
    const id = this.data[index].id;

    switch (actions[0]) {
    case 'delete':
      confirmAction(this, {
        title: 'Xoá người ký',
        message: `Xác nhận xoá người ký ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeSignatory.SignatoryDelete, {id})
            .then(() => {
              this.$message.success(this.$t('signatory_deleted_successfully').toString());
            })
            .catch(() => {
              this.$message.error(this.$t('cannot_delete_signatory').toString());
            });
        }
      });
      break;
    case 'detail':
      this.$router.push(`/contracts/edit/signatory/${id}`);
      break;
    default:
      break;
    }
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeSignatory.SignatoryFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }
}
