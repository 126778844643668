export enum MutationTypeSignatory {
  SignatoryAppendModel = 'signatoryAppendModel',
  SignatoryLoading = 'signatoryLoading',
  SignatoryLoaded = 'signatoryLoaded',
  SignatoryResetModels = 'signatoryResetModels'
}

export enum ActionTypeSignatory {
  SignatoryPaginationChange = 'signatoryPaginationChange',
  SignatoryFilterNoCache = 'signatoryFilterNoCache',
  SignatoryFilterChange = 'signatoryFilterChange',
  SignatoryUpsert = 'signatoryUpsert',
  SignatoryCreate = 'signatoryCreate',
  SignatoryDelete = 'signatoryDelete',
  SignatoryUpdate = 'signatoryUpdate',
  SignatoryFetchMany = 'signatoryFetchMany',
  SignatoryFindById = 'signatoryFindById'
}
