import { IGuideline } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IGuidelineGqlService
  extends ICRUDGqlService<IGuideline> {
    //
}
export function GuidelineGqlService(): IGuidelineGqlService {
  return {
    ...CRUDGqlService<IGuideline>('guidelines'),
  };
}
