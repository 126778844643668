import { omit } from 'lodash';
import { defaultValue } from 'root/helpers';
import { i18n } from 'root/locales';
import {
  HPToken, IAlertState,
  IGlobalConfiguration, IGlobalState, IState, Layout, MutationType, TypeAlert
} from './types';

export function AlertState(): IAlertState {
  return {
    opened: false,
    type: TypeAlert.Info,
    message: 'Text message',
    icon: 'info'
  };
}

export const globalState: IGlobalState = {
  authUser: null,
  currentLocale: null,
  drawer: true,
  globalConfig: null,
  isLoading: true,
  isRegisting: false,
  lastRoutePath: null,
  layout: Layout.Login,
  signInErrorMessage: null,
  topAlert: AlertState(),
  navCollapsed: false
};

export const mutations = {
  // init
  [MutationType.SetCurrentLocale](state: IState, locale: string) {
    state.global.currentLocale = locale;
    i18n().locale = locale;
  },
  [MutationType.ClosePlashScreen](state: IState) {
    state.global.isLoading = false;
  },
  [MutationType.OpenPlashScreen](state: IState) {
    state.global.isLoading = true;
  },
  [MutationType.SetLayout](state: IState, layout: Layout) {
    state.global.layout = layout;
  },
  [MutationType.SetGlobalConfig](state: IState, globalConfig: IGlobalConfiguration) {
    state.global.globalConfig = globalConfig;
  },
  [MutationType.OpenTopAlert](state: IState, topAlert: IAlertState) {
    state.global.topAlert = {
      ...topAlert,
      opened: true
    };
  },
  [MutationType.CloseTopAlert](state: IState) {
    state.global.topAlert = AlertState();
  },
  // login
  [MutationType.Authenticated](state: IState, user) {
    state.global.authUser = user;
  },
  [MutationType.Unauthenticated](state: IState) {
    state.global.authUser = null;
    // state.global.lastRoutePath = state.route.path;
  },
  // [MutationType.SetProfile](state: IState, user) {
    // state.global.authUser = user;
  // },
  [MutationType.SetRegisting](state: IState) {
    state.global.isRegisting = true;
  },
  [MutationType.SetRegisted](state: IState) {
    state.global.isRegisting = false;
  },
  [MutationType.ToggleNavCollapse](state: IState) {
    state.global.navCollapsed = !state.global.navCollapsed;
  },
  [MutationType.ClearStore](state: IState) {
    const _state = omit(defaultValue(state), ['global', 'route']);
    Object.keys(_state).forEach((key) => {
      state[key] = {
        ..._state[key],
        pagination: {
          limit: 10,
          page: 1
        }
      };
    });
  },
  [MutationType.SetToken](_: any, token: string) {
    localStorage.setItem(HPToken.accessToken, token);
  },
  [MutationType.SetLoggedIn](_: any, state: string) {
    localStorage.setItem('loggedIn', state);
  }
};
