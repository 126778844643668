import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'guidelines';

const model = `id
  section
  urls
  content
  updatedAt
  `;

export const GuidelineQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
