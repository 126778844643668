import moment from 'moment';
import { ReferenceTableName } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    form: Object
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading
    })
  },
  watch: {
    tempInput(val) {
      this.tempInput = `${parseInt(val.replace(/\,/g, ''), 10)}`
        .replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    'form.salaryTotal'(val) {
      this.$props.form.noBonusSalary = val === '4,730,000';
    },
    'form.positionCode'(val) {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: val,
        onSuccess: (data: any) => {
          if (!data[0]) {
            return;
          }
          this.$props.form.noBonusLevel = data[0].superior.split('_')[0].toLowerCase() === '*nhân viên dịch vụ';
        },
        onFailure: () => {
          return;
        }
      });
    }
  }
})
export class ProfileBonusForm extends Vue {
  public tempInput: string = '';
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };
  public referenceGroups: any = {
    [ReferenceTableName.BonusGroup]: 'groupOptions',
    [ReferenceTableName.BonusType]: 'typeOptions',
    [ReferenceTableName.BonusCurrency]: 'currencyOptions'
  };
  public groupOptions: any[] = [];
  public typeOptions: any[] = [];
  public currencyOptions: any[] = [];
  public displayReference: any = {};

  public mounted() {
    this.$nextTick(() => {
      const filter = [];
      const idToIndex = {};
      this.$props.form.bonuses.forEach((obj, index) => {
        if (!obj.group) {
          return;
        }
        if (idToIndex[obj.group]) {
          idToIndex[obj.group].push(index);
        } else {
          idToIndex[obj.group] = [index];
        }
      });
      Object.keys(this.referenceGroups).forEach((key) => {
        filter.push({
          tableName: { _eq: key }
        });
      });
      if (this.$props.form.positionCode) {
        filter.push({
          id: { _eq: this.$props.form.positionCode }
        });
      }
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: filter
          },
          onSuccess: (data: any) => {
            if (!data.length) {
              return;
            }
            data.forEach((record: any) => {
              if (record.id === this.$props.form.positionCode) {
                this.$props.form.noBonusLevel = record.superior.split('_')[0].toLowerCase() === '*nhân viên dịch vụ';

                return;
              }
              if (idToIndex[record.id]) {
                idToIndex[record.id].forEach((index) => {
                  this.$set(this.$props.form.bonuses[index], 'group',
                    `${record.id}_${record.name}`);
                });
              }
              if (this.referenceGroups[record.tableName] !== 'typeOptions') {
                this[`${this.referenceGroups[record.tableName]}`].push({
                  label: record.name,
                  value: this.referenceGroups[record.tableName] === 'currencyOptions' ?
                    record.id : `${record.id}_${record.name}`
                });
                if (this.referenceGroups[record.tableName] === 'currencyOptions') {
                  this.$set(this.displayReference, record.id, record.name);
                } else {
                  this.$set(this.displayReference, `${record.id}_${record.name}`, record.name);
                }

                return;
              }
              this.$set(this.displayReference, record.id, record.name);
            });
          }
        }
      });
    });
  }

  public addRow(array) {
    this.typeOptions = [];
    array.push({
      focus: true,
      group: '',
      signDate: undefined,
      expiryDate: undefined,
      effectiveDate: undefined,
      type: '',
      value: '',
      currency: ''
    });
  }

  public groupChange(value: string) {
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
      params: {
        pagingParams: this.pagingParams,
        filterParams: {
          superior: value.split('_')[1],
          tableName: ReferenceTableName.BonusType
        },
        onSuccess: (data: any) => {
          this.typeOptions = [];
          if (!data.length) {
            return;
          }
          data.forEach((record: any) => {
            this.typeOptions.push({
              label: record.name,
              value: record.id
            });
          });
        }
      }
    });
  }

  public editRow(num: number) {
    this.tempInput = this.$props.form.bonuses[num].value;
    this.$set(this.$props.form.bonuses[num], 'focus', true);
    if (!this.$props.form.bonuses[num].group) {
      return;
    }
    this.groupChange(this.$props.form.bonuses[num].group);
  }

  public format(date: string) {
    if (!date) {
      return '';
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }
}
