import { ruleEmail, ruleRequired } from 'root/helpers';
import { ReferenceTableName } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    form: Object
  },
  watch: {
    'form.company'(val) {
      this.valueChange(val, 0);
    },
    'form.division'(val) {
      this.valueChange(val, 1);
    },
    'form.center'(val) {
      this.valueChange(val, 2);
    },
    'form.department'(val) {
      this.valueChange(val, 3);
    },
    'form.unit'(val) {
      this.valueChange(val, 4);
    },
    'form.team'(val) {
      this.valueChange(val, 5);
    },
    'form.positionLevel'(val) {
      if (this.form['locked']) {
        return;
      }
      if (this.form.positionCode) {
        this.$set(this.form, 'positionCode', '');
      }
      this.positionOptions = [];
      if (!val || val === '-') {
        return;
      }
      this.$props.form.noBonus = val.split('_')[1].toLowerCase() === '*nhân viên dịch vụ';
      const arr = val.split('_');
      this.optionChange(`${arr[1]}_${arr[2]}`, 'position', `positionOptions`);
    }
  }
})

export class ProfileWorkForm extends Vue {
  public loading: boolean = false;
  public staffId: string = '';
  public tree: string[] = ['company', 'division', 'center', 'department',
    'unit', 'team', 'level', 'position'];
  public pagingParams: any = {
    limit: 240,
    page: 1
  };
  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }
  public referenceGroups: any = {
    [ReferenceTableName.ChangeType]: 'changeOptions',
    [ReferenceTableName.companyTable]: 'companyOptions',
    [ReferenceTableName.WorkLocation]: 'powOptions',
    [ReferenceTableName.EmployeeType]: 'typeOptions'
  };
  public changeOptions: any[] = [];
  public defaultOption: any = {
    label: '-',
    value: '-'
  };
  public defaultOption1: any = {
    label: '',
    value: ''
  };
  public companyOptions: any[] = [];
  public divisionsOfCompany: any[] = [];
  public centersOfCompany: any[] = [];
  public centersOfDivision: any[] = [];
  public departmentsOfCompany: any[] = [];
  public departmentsOfDivision: any[] = [];
  public departmentsOfCenter: any[] = [];
  public unitsOfCompany: any[] = [];
  public unitsOfDivision: any[] = [];
  public unitsOfCenter: any[] = [];
  public unitsOfDepartment: any[] = [];
  public teamsOfCompany: any[] = [];
  public teamsOfDivision: any[] = [];
  public teamsOfCenter: any[] = [];
  public teamsOfDepartment: any[] = [];
  public teamsOfUnit: any[] = [];
  public levelsOfCompany: any[] = [];
  public levelsOfDivision: any[] = [];
  public levelsOfCenter: any[] = [];
  public levelsOfDepartment: any[] = [];
  public levelsOfUnit: any[] = [];
  public levelsOfTeam: any[] = [];
  public positionOptions: any[] = [];
  public powOptions: any[] = [];
  public typeOptions: any[] = [];

  public mounted() {
    this.$nextTick(() => {
      const names = [];
      Object.keys(this.referenceGroups).forEach((key) => {
        names.push({
          tableName: { _eq: key }
        });
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: names
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              if (this.referenceGroups[record.tableName] === 'companyOptions') {
                this[`${this.referenceGroups[record.tableName]}`].push({
                  label: record.name,
                  value: `${record.id}_${record.name}`
                });
              } else {
                this[`${this.referenceGroups[record.tableName]}`].push({
                  label: record.name,
                  value: record.id
                });
              }
            });
          }
        }
      });
      const temp = {
        company: this.$props.form.company,
        division: this.$props.form.division,
        center: this.$props.form.center,
        department: this.$props.form.department,
        unit: this.$props.form.unit,
        team: this.$props.form.team,
        level: this.$props.form.positionLevel,
        position: this.$props.form.positionCode,
      };
      this.firstLoad(temp);
    });
  }

  public async valueChange(val: string, pos: number, force: boolean = false) {
    if (this.$props.form['locked'] && !force) {
      return;
    }
    this.$props.form['locked'] = true;
    this.resetFields(pos + 1);
    this.resetOptions(pos + 1);
    if (!val) {
      for (let j = pos - 1; j >= 0; --j) {
        if (!this.$props.form[this.tree[j]] || this.$props.form[this.tree[j]] === '-') {
          continue;
        }
        await this.optionChange(this.$props.form[this.tree[j]], 'level',
          `levelsOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`);
        break;
      }
      this.$props.form['locked'] = false;

      return;
    }
    for (const i of (pos === 5 ? [6] : [pos + 1, 6])) {
      if (val !== '-') {
        await this.optionChange(val, this.tree[i],
          `${this.tree[i]}sOf${this.tree[pos].replace(/^\w/, (c) => c.toUpperCase())}`);
        continue;
      }
      for (let j = pos - 1; j >= 0; --j) {
        if (!this.$props.form[this.tree[j]] || this.$props.form[this.tree[j]] === '-') {
          continue;
        }
        await this.optionChange(this.$props.form[this.tree[j]], this.tree[i],
          `${this.tree[i]}sOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`);
        break;
      }
    }
    this.$props.form['locked'] = false;
  }

  public resetFields(from: number) {
    for (let i = from; i < 6; ++i) {
      if (this.$props.form[this.tree[i]]) {
        this.$set(this.$props.form, this.tree[i], '');
      }
    }
    if (this.$props.form.positionLevel) {
      this.$set(this.$props.form, 'positionLevel', '');
    }
    if (this.$props.form.positionCode) {
      this.$set(this.$props.form, 'positionCode', '');
    }
  }

  public resetOptions(from: number) {
    for (let i = from; i < 7; ++i) {
      for (let j = i - 1; j >= 0; --j) {
        this[`${this.tree[i]}sOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`] = [];
      }
    }
    this.positionOptions = [];
  }

  public async optionChange(value: string, key: string, options: string) {
    await new Promise((resolve) => {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            superior: key === 'position' ? value : value.split('_')[1],
            tableName: ReferenceTableName[`${key}Table`]
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this[options].push({
                label: record.name,
                value: key === 'position' ? record.id :
                  (key === 'level' ? `${record.id}_${record.name}_${record.superior}` :
                  `${record.id}_${record.name}`)
              });
            });
            resolve();
          }
        }
      });
    });
  }

  public async firstLoad(form: any) {
    this.loading = true;
    for (let i = 0; i < 8; ++i) {
      if (!form[this.tree[i]] || form[this.tree[i]] === '-') {
        this.$props.form['locked'] = true;
        this.$props.form[this.tree[i]] = form[this.tree[i]];
        await this.valueChange(form[this.tree[i]], i, true);
        continue;
      }
      await new Promise((resolve) => {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
          id: form[this.tree[i]],
          onSuccess: async (data: any) => {
            if (!data[0]) {
              resolve();
            }
            if (i < 6) {
              this.$props.form['locked'] = true;
              this.$props.form[this.tree[i]] = `${data[0].id}_${data[0].name}`;
              await this.valueChange(`${data[0].id}_${data[0].name}`, i, true);
            } else if (i === 6) {
              this.$props.form['locked'] = true;
              this.$props.form.positionLevel = `${data[0].id}_${data[0].name}_${data[0].superior}`;
              await this.optionChange(`${data[0].name}_${data[0].superior}`,
                'position', `positionOptions`);
              this.$props.form['locked'] = false;
            } else {
              this.$props.form.positionCode = data[0].id;
            }
            resolve();
          }
        });
      });
    }
    this.loading = false;
  }

  public async codeChange(value: string) {
    const arr = value.split('.');
    if (arr.length < 8) {
      return;
    }
    this.loading = true;
    this.resetFields(0);
    this.resetOptions(1);
    let lastSuperior: number = 5;
    for (let i = 5; i >= 0; --i) {
      if (arr[i] === '0'.repeat(i + 1)) {
        continue;
      }
      lastSuperior = i;
      break;
    }
    for (let i = 0; i < 6; ++i) {
      if (arr[i] === '0'.repeat(i + 1)) {
        this.$props.form['locked'] = true;
        this.$props.form[this.tree[i]] = i < lastSuperior ? '-' : '';
        await this.valueChange(this.$props.form[this.tree[i]], i, true);
        continue;
      }
      await this.query(value, i);
    }
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
      params: {
        pagingParams: this.pagingParams,
        filterParams: {
          superior: this.$props.form[this.tree[lastSuperior]].split('_')[1],
          tableName: ReferenceTableName.levelTable
        },
        onSuccess: (res: any) => {
          if (!res[0]) {
            return;
          }
          const levels = {};
          res.forEach((obj) => {
            levels[`${obj.name}_${obj.superior}`] = `${obj.id}_${obj.name}_${obj.superior}`;
          });
          this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
            params: {
              pagingParams: this.pagingParams,
              filterParams: {
                code: `${arr[6]}.${arr[7]}`,
                tableName: ReferenceTableName.positionTable,
                superior: Object.keys(levels)
              },
              onSuccess: async (res2: any) => {
                if (!res2[0]) {
                  return;
                }
                this.$props.form['locked'] = true;
                this.$props.form.positionLevel = levels[res2[0].superior];
                const tmp = levels[res2[0].superior].split('_');
                await this.optionChange(`${tmp[1]}_${tmp[2]}`,
                  'position', `positionOptions`);
                this.$props.form.positionCode = res2[0].id;
                this.loading = false;
              }
            }
          });
        }
      }
    });
  }

  public onChange(value: string) {
    if (value.split('_')[1].toLowerCase() === '*nhân viên dịch vụ') {
      this.$props.form.noBonus = true;
    }
  }

  private query(value: string, index: number) {
    const arr = value.split('.');
    const key = this.tree[index];
    let superior: string = '';
    for (let i = index - 1; i >= 0; --i) {
      if (!this.$props.form[this.tree[i]] || this.$props.form[this.tree[i]] === '-') {
        continue;
      }
      superior = this.$props.form[this.tree[i]].split('_')[1];
      break;
    }

    return new Promise((resolve, reject) => {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            code: arr[index],
            superior: superior ? superior : 'None',
            tableName: ReferenceTableName[`${key}Table`]
          },
          onSuccess: async (data: any) => {
            if (!data[0]) {
              return;
            }
            this.$props.form['locked'] = true;
            this.$props.form[key] = `${data[0].id}_${data[0].name}`;
            await this.valueChange(`${data[0].id}_${data[0].name}`, index, true);
            resolve();
          },
          onFailure: (err) => {
            reject(err);
          }
        }
      });
    });
  }
}
