export enum MutationTypeReference {
  ReferenceLoading = 'referenceLoading',
  ReferenceLoaded = 'referenceLoaded',
  ReferenceResetModels = 'referenceResetModels'
}

export enum ActionTypeReference {
  ReferencePaginationChange = 'referencePaginationChange',
  ReferenceFilterNoCache = 'referenceFilterNoCache',
  ReferenceFilter = 'referenceFilter',
  ReferenceFilterChange = 'referenceFilterChange',
  ReferenceUpsert = 'referenceUpsert',
  ReferenceUpsertMany = 'referenceUpsertMany',
  ReferenceCreate = 'referenceCreate',
  ReferenceDelete = 'referenceDelete',
  ReferenceUpdate = 'referenceUpdate',
  ReferenceFetchMany = 'referenceFetchMany'
}
