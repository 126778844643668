import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown, HPPage } from 'root/components';
import { confirmAction } from 'root/helpers';
import { ActionTypeContractTemplate } from 'root/pages/Contracts/Store';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.contractTemplate.data,
      loading: (state: IState) => state.contractTemplate.loading,
      pagination: (state: IState) => state.contractTemplate.pagination,
      authUser: (state: IState) => state.global.authUser
    })
  },
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  }
})
export class ContractTable extends Vue {
  public authUser: any;
  public pagination: IPaginationParams;
  public data: any[];
  public openForm: boolean = false;
  public showIndex: number = 0;
  public listOption: number = 10;
  public listOptions: any[] = [
    { value: '10 kết quả mỗi trang', key: 10 },
    { value: '20 kết quả mỗi trang', key: 20 },
    { value: '50 kết quả mỗi trang', key: 50 }
  ];
  public actionOptions: any[] = [
    {
      value: 'Chi tiết',
      key: 'view',
      icon: 'el-icon-view'
    },
    {
      value: 'Chỉnh sửa',
      key: 'edit',
      icon: 'el-icon-edit'
    },
    {
      value: 'Xoá mẫu hợp đồng',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];

  public mounted() {
    this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateFilterNoCache, {
      params: {
        pagingParams: {
          limit: 10,
          page: 1
        }
      }
    });
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    if (actions[2] === 'filter') {
      this.listOption = parseInt(actions[0], 10);
      this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateFilterNoCache, {
        params: {
          pagingParams: {
            limit: this.listOption,
            page: 1
          }
        }
      });

      return;
    }
    const index = actions[1];
    const id = this.data[index].id;

    switch (actions[0]) {
    case 'delete':
      confirmAction(this, {
        title: 'Xoá mẫu hợp đồng',
        message: `Xác nhận xoá mẫu hợp đồng ${this.data[index].name}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateDelete, {id})
            .then(() => {
              this.$message.success(this.$t('contract_deleted_successfully').toString());
            })
            .catch(() => {
              this.$message.error(this.$t('cannot_delete_contract').toString());
            });
        }
      });
      break;
    case 'view':
      this.showIndex = parseInt(index, 10);
      this.openForm = true;
      break;
    case 'edit':
      this.$router.push(`/contracts/edit/template/${id}`);
      break;
    default:
      break;
    }
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeContractTemplate.ContractTemplateFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }
}
