import { IContractTemplate } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IContractTemplateState extends ICRUDState<IContractTemplate> {
}

export const defaultState: IContractTemplateState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('contractTemplate')
};
