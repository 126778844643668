import { IUser } from 'root/models';
import { IShortcutState } from 'root/pages/Contracts/ShortcutStore';
import { ISignatoryState } from 'root/pages/Contracts/SignatoryStore';
import { IContractTemplateState } from 'root/pages/Contracts/Store';
import { IGuidelineState } from 'root/pages/Guidelines/Store';
import { IReferenceRecordState } from 'root/pages/Reference/RecordStore';
import { IReferenceState } from 'root/pages/Reference/Store';
import { IProfileState } from 'root/pages/Users/ProfileStore';
import { IUserState } from 'root/pages/Users/Store';

// import { User } from 'firebase';
// import { IProfile } from 'root/models';
// tslint:disable max-file-line-count

// define interface
export interface IGlobalState {
  drawer: boolean;
  isLoading: boolean;
  lastRoutePath: string;
  currentLocale: string;
  authUser: IUser;
  topAlert: IAlertState;
  signInErrorMessage: string;
  layout: Layout;
  globalConfig: IGlobalConfiguration;
  isRegisting: boolean;
  navCollapsed: boolean;
}

export enum TypeAlert {
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
  Error = 'error'
}

export interface IAlertState {
  opened: boolean;
  message: string;
  type: TypeAlert;
  icon: string;
}

export interface IRouteState {
  path: string;
  params: object;
  query: object;
}

// export interface IPaginationState {
//   page?: number;
//   size?: number;
//   totalPages?: number;
// }

export interface IState {
  global: IGlobalState;
  route: IRouteState;
  user: IUserState;
  reference: IReferenceState;
  referenceRecord: IReferenceRecordState;
  profile: IProfileState;
  guideline: IGuidelineState;
  contractTemplate: IContractTemplateState;
  signatory: ISignatoryState;
  shortcut: IShortcutState;
}

export interface IFormState<T> {
  payload: T;
  valid: boolean;
  errorMessage: string;
}

export interface IGlobalConfiguration {
  systemMessage: string;
  systemStatus: number;
}

export enum Layout {
  Home = 'home-layout',
  Login = 'login-layout',
  Public = 'public-layout'
}

export interface IAvatarDefault {
  user: string;
  image: string;
  video: string;
  project: string;
  plan: string;
  message: string;
}

export interface ICustomLazy {
  src: string;
  loading?: string;
  error?: string;
}

// function
// export function PaginationState(params?: IPaginationState): IPaginationState {
//   return {
//     page: 1,
//     size: 20,
//     ...params,
//   };
// }

// define type screen

export enum ViewPortSize {
  Mobile = 768,
  Tablet = 1023,
  Desktop = 1215,
  Widescreen = 1407,
}

// define type mutation and action
export enum HPToken {
  apiToken = 'apiToken',
  accessToken = 'accessToken'
}

export enum MutationType {
  // global
  SetCurrentLocale = 'setCurrentLocale',
  ClosePlashScreen = 'closePlashScreen',
  WindowResized = 'windowResized',
  OpenPlashScreen = 'openPlashScreen',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  SetMenuCollapse = 'setMenuCollapse',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',
  ToggleNavCollapse = 'toggleNavCollapse',
  // users
  SetListUsers = 'setListUsers',
  CreateUser = 'createUser',
  UpdateUser = 'updateUser',
  // login
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  SetProfile = 'setProfile',

  // profile
  LoadingProfile = 'loadingProfile',
  ClearStore = 'clearStore',
  SetRegisting = 'SetRegisting',
  SetRegisted = 'SetRegisted',
  SetToken = 'SetToken',
  SetLoggedIn = 'SetLoggedIn'
}

export enum ActionType {
  // global
  InitializeSettings = 'initializeSettings',
  CatchException = 'catchException',
  SetLayout = 'setLayout',
  SetGlobalConfig = 'setGlobalConfig',
  GoBack = 'goBack',
  GoToRoute = 'goToRoute',
  CollapseMenu = 'collapseMenu',
  OpenTopAlert = 'openTopAlert',
  CloseTopAlert = 'closeTopAlert',

  // navigator
  DirectNavigator = 'directNavigator',

  // login
  ChangeStateAuth = 'changeStateAuth',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',

  // profile
  UpdateProfile = 'updateProfile',
  LoadingProfile = 'loadingProfile',
  ReloadProfile = 'reloadProfile',

  // user-notification-setting
  UserNotificationSettingUpdate = 'userNotificationSettingUpdate'
}
