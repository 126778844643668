import { getLocaleString, ILanguageDictionary } from '@hgiasac/helper';
import { IState } from './types';

export const getters = {
  getLocaleString(state: IState) {
    return (val: string | ILanguageDictionary): string => {
      return getLocaleString(val, state.global.currentLocale);
    };
  },
};
