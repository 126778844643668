export enum MutationTypeShortcut {
  ShortcutAppendModel = 'shortcutAppendModel',
  ShortcutLoading = 'shortcutLoading',
  ShortcutLoaded = 'shortcutLoaded',
  ShortcutResetModels = 'shortcutResetModels'
}

export enum ActionTypeShortcut {
  ShortcutPaginationChange = 'shortcutPaginationChange',
  ShortcutFilterNoCache = 'shortcutFilterNoCache',
  ShortcutFilterChange = 'shortcutFilterChange',
  ShortcutUpsert = 'shortcutUpsert',
  ShortcutCreate = 'shortcutCreate',
  ShortcutDelete = 'shortcutDelete',
  ShortcutUpdate = 'shortcutUpdate',
  ShortcutFetchMany = 'shortcutFetchMany',
  ShortcutFindById = 'shortcutFindById'
}
