import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'signatories';

const model = `id
  fullName
  gender
  positionLevel
  idNo
  positionName
  idDoi
  idPoi
  dob
  permAddr
  authLetter
  candidateLevels
  `;

export const SignatoryQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
