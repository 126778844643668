// register the plugin
import Vue from 'vue';
import VueRouter from 'vue-router';

// import { UserRoutes } from 'root/pages/User';
// route dictionary
import {
  CandidateCreateForm, CandidateEditForm, CandidateExportForm, CandidateList,
  CandidateTable, ContractCreateForm, ContractList, ContractManager, General,
  Guideline, GuidelineEditForm, GuidelineTable, LoginContainer, PublicPageContainer,
  Reference, ReferenceCreateForm, ReferenceTable, SignatoryCreateForm, StaffCreateForm,
  StaffList, StaffTable
} from 'root/pages';
import { Layout } from 'root/store';
import { RouteDictionary, TitleDictionary } from './types';

// export * from "./link";
export * from './types';

// register the plugin
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: RouteDictionary.Home,
      component: General
    },
    {
      path: RouteDictionary.Login,
      component: LoginContainer,
      beforeEnter: (_, __, next) => {
        document.title = `FE CREDIT - ${TitleDictionary.Login}`;
        next();
      }
    },
    {
      path: RouteDictionary.User,
      meta: {
        layout: Layout.Home
      },
      component: StaffList,
      children: [
        {
          path: '',
          meta: {
            layout: Layout.Home
          },
          component: StaffTable,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.User}`;
            next();
          }
        },
        {
          path: 'new',
          meta: {
            layout: Layout.Home
          },
          component: StaffCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.User}`;
            next();
          }
        },
        {
          path: 'edit/:id',
          meta: {
            layout: Layout.Home
          },
          component: StaffCreateForm
        }
      ]
    },
    {
      path: RouteDictionary.Profile,
      meta: {
        layout: Layout.Home
      },
      component: CandidateList,
      children: [
        {
          path: '',
          meta: {
            layout: Layout.Home
          },
          component: CandidateTable,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Profile}`;
            next();
          }
        },
        {
          path: 'new',
          meta: {
            layout: Layout.Home
          },
          component: CandidateCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Profile}`;
            next();
          }
        },
        {
          path: 'rehire/:id',
          meta: {
            layout: Layout.Home
          },
          component: CandidateCreateForm
        },
        {
          path: 'edit/:id',
          meta: {
            layout: Layout.Home
          },
          component: CandidateEditForm
        },
        {
          path: 'export/:id',
          meta: {
            layout: Layout.Home
          },
          component: CandidateExportForm
        }
      ]
    },
    {
      path: RouteDictionary.Reference,
      meta: {
        layout: Layout.Home
      },
      component: Reference,
      children: [
        {
          path: '',
          meta: {
            layout: Layout.Home
          },
          component: ReferenceTable,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Reference}`;
            next();
          }
        },
        {
          path: 'new',
          meta: {
            layout: Layout.Home
          },
          component: ReferenceCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Reference}`;
            next();
          }
        }
      ]
    },
    {
      path: RouteDictionary.PublicPage,
      meta: {
        layout: Layout.Public
      },
      component: PublicPageContainer
    },
    {
      path: RouteDictionary.Guideline,
      meta: {
        layout: Layout.Home
      },
      component: Guideline,
      children: [
        {
          path: '',
          meta: {
            layout: Layout.Home
          },
          component: GuidelineTable,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Guideline}`;
            next();
          }
        },
        {
          path: 'edit',
          meta: {
            layout: Layout.Home
          },
          component: GuidelineEditForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Guideline}`;
            next();
          }
        }
      ]
    },
    {
      path: RouteDictionary.Contract,
      meta: {
        layout: Layout.Home
      },
      component: ContractList,
      children: [
        {
          path: '',
          meta: {
            layout: Layout.Home
          },
          component: ContractManager,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Contract}`;
            next();
          }
        },
        {
          path: 'new/template',
          meta: {
            layout: Layout.Home
          },
          component: ContractCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Contract}`;
            next();
          }
        },
        {
          path: 'edit/template/:id',
          meta: {
            layout: Layout.Home
          },
          component: ContractCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Contract}`;
            next();
          }
        },
        {
          path: 'new/signatory',
          meta: {
            layout: Layout.Home
          },
          component: SignatoryCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Contract}`;
            next();
          }
        },
        {
          path: 'edit/signatory/:id',
          meta: {
            layout: Layout.Home
          },
          component: SignatoryCreateForm,
          beforeEnter: (_, __, next) => {
            document.title = `FE CREDIT - ${TitleDictionary.Contract}`;
            next();
          }
        }
      ]
    }
  ]
});

export default router;
