import { cloneDeep } from 'lodash';
import { hpGqlClient } from 'root/api';
import { ruleRequired } from 'root/helpers';
import { changePasswordFormDefault, IUser } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading,
      authUser: (state: IState) => state.global.authUser
    })
  }
})
export class UserPasswordForm extends Vue {
  public loading: boolean;
  public authUser: IUser;
  public form: any = changePasswordFormDefault();
  public $refs: {
    passwordForm: any;
  };

  public get rules() {
    return {
      required: ruleRequired()
    };
  }

  public closeDialog() {
    this.$emit('update:visible', false);
  }

  public submit() {
    const form = cloneDeep(this.form);
    if (form.newPassword !== form.confirmedPassword) {
      this.$message.error(this.$t('retype_password_not_matched').toString());

      return;
    }

    hpGqlClient.user.changePassword(form.oldPassword, form.newPassword)
      .then(() => {
        this.$refs.passwordForm.resetFields();
        this.$message.success(this.$t('password_changed_successfully').toString());
      })
      .catch(() => {
        this.$message.error(this.$t('wrong_current_password').toString());
      });
  }
}
