
import { IReference } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IReferenceGqlService
  extends ICRUDGqlService<IReference> {
    //
}
export function ReferenceGqlService(): IReferenceGqlService {
  return {
    ...CRUDGqlService<IReference>('references'),
  };
}
