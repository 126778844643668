import VueRouter from 'vue-router';
import { ActionContext } from 'vuex';

import { hpGqlClient } from 'root/api/graphql';
import { parseParamUrl } from 'root/helpers';
import { IErrorException, Permissions } from 'root/models';
import { RouteDictionary } from 'root/router';
import {
  // deleteTagForNotification,
  HPAuth, Logger,
  // Logger
} from 'root/services';
import { i18n } from '../locales';
import { ActionType, IState, Layout, MutationType, TypeAlert } from './types';

export const actions = {
  // global
  [ActionType.InitializeSettings](
    { commit }: ActionContext<IState, IState>
  ) {
    const locale = 'vi';
    i18n().locale = locale;
    commit(MutationType.SetCurrentLocale, locale);
  },
  [ActionType.SetLayout](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.SetLayout);
  },
  [ActionType.SetGlobalConfig](
    { commit }: ActionContext<IState, IState>,
    globalConfig
  ) {
    commit(MutationType.SetGlobalConfig, globalConfig);
  },
  [ActionType.GoBack](
    _: ActionContext<any, any>,
    router: VueRouter,
  ) {
    router.back();
  },
  [ActionType.GoToRoute](
    _: ActionContext<any, any>,
    { router, route, paramUrl }
  ) {
    const method = typeof route === 'string' ? 'push'
      : (route.method || 'push');
    router[method](parseParamUrl(route, paramUrl));
  },
  [ActionType.CollapseMenu](
    { commit }: ActionContext<any, any>,
    isCollapse: boolean,
  ) {
    commit(MutationType.SetMenuCollapse, isCollapse);
  },
  [ActionType.CatchException](
    { commit }: ActionContext<any, any>,
    ex: IErrorException
  ) {
    console.error(ex);
    const message = ex.code
      ? (i18n().t(ex.code) !== ex.code ? i18n().t(ex.code) : ex.message)
      : ex.message;
    commit(MutationType.OpenTopAlert, {
      message,
      type: TypeAlert.Error
    });
  },
  [ActionType.CloseTopAlert](
    { commit }: ActionContext<IState, IState>
  ) {
    commit(MutationType.CloseTopAlert);
  },
  // login
  [ActionType.ChangeStateAuth](
    { dispatch, commit, state }: ActionContext<IState, IState>,
    { router, accessToken }
  ) {
    if (state.global.isRegisting) {
      return;
    }
    if (state.route.path.split('/')[1] === 'public') {
      return;
    }
    if (accessToken) {
      // get profile 
      commit(MutationType.SetToken, accessToken);
      commit(MutationType.OpenPlashScreen);
      hpGqlClient.user.getProfile()
        .then((profile) => {
          commit(MutationType.ClosePlashScreen);
              // can not get profile, go back to login page
          if (!profile) {
            Logger.warn('Can not get profile. This user doesn\'t exist in the system');

            // commit(MutationType.SetLayout, Layout.Login);
            router.replace(RouteDictionary.Login);

            return HPAuth.logOut();
          }
          commit(MutationType.Authenticated, profile[0]);
          commit(MutationType.SetLoggedIn, 'true');

          if (state.route.path === RouteDictionary.Login
            || state.route.path === RouteDictionary.ForgotPassword) {
            const next = (state.global.authUser.role === Permissions.Admin ||
              state.global.authUser.role === Permissions.SuperAdmin) ?
              RouteDictionary.User : RouteDictionary.Profile;
            router.replace(next);
          } else if (state.route.path.split('/')[1] === 'users'
            && state.global.authUser.role !== Permissions.SuperAdmin
            && state.global.authUser.role !== Permissions.Admin) {
              router.replace(RouteDictionary.Profile);
          }
        })
        .catch((error) => {
          dispatch(ActionType.CatchException, error);
          commit(MutationType.ClosePlashScreen);
          if (state.route.path !== RouteDictionary.Login) {
            router.replace(RouteDictionary.Login);
          }
        });
    } else {
      commit(MutationType.ClosePlashScreen);
      if (state.route.path !== RouteDictionary.Login) {
        router.replace(RouteDictionary.Login);
      }
    }
  },
  [ActionType.Unauthenticated](
    { commit, dispatch, state }: ActionContext<IState, IState>, router: VueRouter
  ) {
    // deleteTagForNotification();
    commit(MutationType.OpenPlashScreen);
    HPAuth.logOut(() => {
      commit(MutationType.Unauthenticated);
      commit(MutationType.SetLayout, Layout.Login);
      if (state.route.path !== RouteDictionary.Login) {
        router.replace(RouteDictionary.Login);
      }
      commit(MutationType.ClearStore);
      dispatch(ActionType.ChangeStateAuth, {router, user: null});
    });
  },
};
