export enum MutationTypeGuideline {
  GuidelineAppendModel = 'guidelineAppendModel',
  GuidelineLoading = 'guidelineLoading',
  GuidelineLoaded = 'guidelineLoaded',
  GuidelineResetModels = 'guidelineResetModels'
}

export enum ActionTypeGuideline {
  GuidelinePaginationChange = 'guidelinePaginationChange',
  GuidelineFilterNoCache = 'guidelineFilterNoCache',
  GuidelineFilterChange = 'guidelineFilterChange',
  GuidelineOrderChange = 'guidelineOrderChange',
  GuidelineUpsert = 'guidelineUpsert',
  GuidelineCreate = 'guidelineCreate',
  GuidelineDelete = 'guidelineDelete',
  GuidelineUpdate = 'guidelineUpdate',
  GuidelineFetchMany = 'guidelineFetchMany',
  GuidelineFindById = 'guidelineFindById'
}
