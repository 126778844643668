import Vue from 'vue';
import Component from 'vue-class-component';
import { ProfileCommitmentViewer } from '../profileCommitmentViewer';
import { ProfileContractViewer } from '../profileContractViewer';
import { ProfileDecisionViewer } from '../profileDecisionViewer';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    contractStatus: Object
  },
  components: {
    'contract-viewer': ProfileContractViewer,
    'decision-viewer': ProfileDecisionViewer,
    'commitment-viewer': ProfileCommitmentViewer
  }
})
export class ProfileDocumentViewer extends Vue {
  public currentViewer: string = 'contract';
}
