export enum MutationTypeProfile {
  ProfileAppendModel = 'profileAppendModel',
  ProfileLoading = 'profileLoading',
  ProfileLoaded = 'profileLoaded',
  ProfileResetModels = 'profileResetModels'
}

export enum ActionTypeProfile {
  ProfilePaginationChange = 'profilePaginationChange',
  ProfileFilterNoCache = 'profileFilterNoCache',
  ProfileFilterChange = 'profileFilterChange',
  ProfileCreate = 'profileCreate',
  ProfileDelete = 'profileDelete',
  ProfileUpdate = 'profileUpdate',
  ProfileFetchMany = 'profileFetchMany',
  ProfileFindById = 'profileFindById'
}
