
import gql from 'graphql-tag';
import { IUser } from 'root/models';
import { gqlClient, CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IUserGqlService
  extends ICRUDGqlService<IUser> {
  getProfile(): Promise<IUser>;
  login(email: string, password: string): Promise<IUser>;
  changePassword(oldPassword: string, newPassword: string): Promise<IUser>;
  createUser(form: any): Promise<IUser>;
  sendEmail(id: string, url: string): Promise<string>;
  getExportData(employee: string, hr: string, hris: string, contractSigned: string, employeeStatus: string,
                createdBy: string, startDate: string[], referrers: boolean, candidates: boolean): Promise<any>;
  validatePhone(token: string, phoneNumber: string);
  generateContract(id: string): Promise<any>;
  generateDecision(id: string): Promise<any>;
  generateCommitment(id: string): Promise<any>;
  exportCandidates();
  createRehire(form: any): Promise<IUser>;
  checkRehire(email: string): Promise<any>;
    //
}
export function UserGqlService(): IUserGqlService {
  return {
    ...CRUDGqlService<IUser>('users'),
    getProfile,
    login,
    changePassword,
    createUser,
    sendEmail,
    getExportData,
    validatePhone,
    generateContract,
    generateDecision,
    generateCommitment,
    exportCandidates,
    createRehire,
    checkRehire
  };
}

async function getProfile(): Promise<IUser> {
  const query = gql`
  query GetProfile {
    myprofile {
      email
      fullName
      id
      role
      profileOfUser {
        phone
        department
        unit
        team
      }
    }
  }
  `;
  const result = await gqlClient.query({
    query,
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['myprofile'];
}

async function login(email: string, password: string): Promise<IUser> {
  const mutation = gql`
  mutation Login($email: MaxLength!, $password: MaxLength!) {
    login(input: {email: $email, password: $password}) {
      email
      fullName
      id
      role
      token
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      email,
      password
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['login'];
}

async function changePassword(oldPassword: string, newPassword: string): Promise<IUser> {
  const mutation = gql`
  mutation ChangePassword($oldPassword: MaxLength!, $newPassword: MaxLength!) {
    changePassword(input: {oldPassword: $oldPassword, newPassword: $newPassword}) {
      id
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      oldPassword,
      newPassword
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['changePassword'];
}

async function createUser(form: any): Promise<IUser> {
  const { email, password, role, fullName, startDate } = form;
  const mutation = gql`
  mutation CreateUser($email: MaxLength!, $password: MaxLength!, $role: String!,
    $fullName: String, $startDate: String) {
    createUser(input: {email: $email, password: $password, role: $role,
      fullName: $fullName, startDate: $startDate}) {
      id
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      email,
      password,
      role,
      fullName,
      startDate
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['createUser'];
}

async function createRehire(form: any): Promise<IUser> {
  const { email, password, role, fullName, startDate } = form;
  const mutation = gql`
  mutation CreateUser($email: MaxLength!, $password: MaxLength!, $role: String!,
    $fullName: String, $startDate: String) {
    createRehire(input: {email: $email, password: $password, role: $role,
      fullName: $fullName, startDate: $startDate}) {
      id
      profile
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      email,
      password,
      role,
      fullName,
      startDate
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['createRehire'];
}

async function sendEmail(id: string, url: string): Promise<string> {
  const query = gql`
  query SendEmail($id: ID!, $url: String) {
    sendEmail(input: { id: $id, link: $url }) {
      result
    }
  }
  `;
  const result = await gqlClient.query({
    query,
    variables: {
      id,
      url
    },
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['sendEmail'];
}

async function getExportData(employee: string, hr: string, hris: string, contractSigned: string, employeeStatus: string,
                             createdBy: string, startDate: string[], referrers: boolean, candidates: boolean)
                             : Promise<any> {
  const query = gql`
  query GetExportData($employee: String!, $hr: String!, $hris: String!, $contractSigned: String!,
    $employeeStatus: String!, $createdBy: String!, $startDateFrom: String, $startDateTo: String,
    $referrers: Boolean!, $candidates: Boolean!) {
    getExportData(input: {
      employee: $employee,
      hr: $hr,
      hris: $hris,
      contractSigned: $contractSigned,
      employeeStatus: $employeeStatus,
      createdBy: $createdBy,
      startDateFrom: $startDateFrom,
      startDateTo: $startDateTo,
      onlyReferrers: $referrers,
      exportCandidates: $candidates
    })
  }
  `;
  const result = await gqlClient.query({
    query,
    variables: {
      employee,
      hr,
      hris,
      contractSigned,
      employeeStatus,
      createdBy,
      referrers,
      candidates,
      startDateFrom: startDate ? startDate[0] : null,
      startDateTo: startDate ? startDate[1] : null,
    },
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['getExportData'];
}

async function exportCandidates(): Promise<any> {
  const query = gql`
  query ExportCandidates {
    exportCandidates
  }
  `;
  const result = await gqlClient.query({
    query,
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['exportCandidates'];
}

async function validatePhone(token: string, phoneNumber: string): Promise<any> {
  const query = gql`
  query ValidatePhone($token: String!, $phoneNumber: String!) {
    validatePhone(input: {token: $token, phoneNumber: $phoneNumber}) {
      result
    }
  }
  `;
  const result = await gqlClient.query({
    query,
    variables: {
      token,
      phoneNumber
    },
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['validatePhone'];
}

async function generateContract(id: string): Promise<any> {
  const mutation = gql`
  mutation GenerateContract($id: ID!) {
    generateContract(id: $id) {
      docx
      pdf
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      id
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['generateContract'];
}

async function generateDecision(id: string): Promise<any> {
  const mutation = gql`
  mutation GenerateDecision($id: ID!) {
    generateDecision(id: $id) {
      docx
      pdf
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      id
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['generateDecision'];
}

async function generateCommitment(id: string): Promise<any> {
  const mutation = gql`
  mutation GenerateCommitment($id: ID!) {
    generateCommitment(id: $id) {
      docx
      pdf
    }
  }
  `;
  const result = await gqlClient.mutate({
    mutation,
    variables: {
      id
    }
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['generateCommitment'];
}

async function checkRehire(email: string): Promise<any> {
  const query = gql`
  query CheckRehire($email: String!) {
    checkRehire(email: $email) {
      fullName
      profile
      status
    }
  }
  `;
  const result = await gqlClient.query({
    query,
    variables: {
      email
    },
    fetchPolicy: 'network-only'
  });

  if (result.errors) {
    throw result.errors;
  }

  return result.data['checkRehire'];
}
