import { IReference } from 'root/models';
import { CRUDHttpService, ICRUDHttpService, IHttpServiceClientOptions } from '../Core';

export interface IReferenceHttpService
  extends ICRUDHttpService<IReference> {
  getReferenceList(filter: any): Promise<any>;
}

export function ReferenceHttpService(options: IHttpServiceClientOptions): IReferenceHttpService {

  const basePath = '/reference';
  const baseURL = options.httpService.getUrl(basePath, options.mock);

  function getReferenceList(filter: any) {
    return options.httpService.get(baseURL, {
      params: filter
    });
  }

  return {
    ...CRUDHttpService<IReference>({
      ...options,
      basePath
    }),
    getReferenceList
  };
}
