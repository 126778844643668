import 'font-awesome/css/font-awesome.css';
import uuid from 'uuid';
import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    callSuccess: {
      type: Function,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: '#1D9D74'
    },
    mode: {
      type: String,
      required: false,
      default: 'text'   // (default) 'text' -> alphanumeric | 'math' -> operations math
    },
    resolve: {
      type: String,
      required: false,
      default: 'all'    // (default) 'all' -> written and digit | 'text' -> only written | 'digit' -> only digit
    }
  },
  watch: {
    errorSelect(error) {
      if (error > 0) {
        // console.error('[Vue-captcha] Try Errors: ', error)
      }
    },
    success(acert) {
      if (acert === 6) {
        this.$props.callSuccess();
      }
    }
  },
})
export class HPCaptcha extends Vue {
  public key: string = '';
  public auth: boolean = false;
  public opc: number = 0;
  public text: string = '';
  public contenTextTop: number = 200;
  public contenTextLeft: number = 200;
  public errorSelect: number = 0;
  public success: number = 0;
  public preSuccess: boolean = false;
  public arrayForNum: any[] = [];
  public $refs: {
    image: any,
    main: any
  };

  public mounted() {
    // tslint:disable-next-line: early-exit
    if ((this.$props.mode === 'text' || this.$props.mode === 'math')) {
      if ((this.$props.resolve === 'all' || this.$props.resolve === 'text' || this.$props.resolve === 'digit')) {
        this.picture();
      } else {
        // tslint:disable-next-line: prefer-template
        console.error('[Vue-Captcha] Error: option resolve = \'all\' Or \'text\' Or \'digit\', Not valid \''
          + this.$props.resolve + '\'.');
      }
    } else {
      console.error('[Vue-Captcha] Error: option mode = \'text\' Or \'math\'.');
    }
  }

  public reset() {
    this.key = '';
    this.auth = false;
    this.opc = 0;
    this.text = '';
    this.contenTextTop = 200;
    this.contenTextLeft = 200;
    this.errorSelect = 0;
    this.success = 0;
    this.preSuccess = false;
    this.arrayForNum = [];
    this.picture();
  }

  public getKey() {
    let newKey = null;

    if (this.$props.mode === 'text') {
      do {
        newKey = uuid();
        newKey = newKey.replace('-', '');
        newKey = newKey.substr(5, 6);
      } while (this.codex(newKey));
      this.key = newKey;
    } else if (this.$props.mode === 'math') {
      newKey = this.operation();
      // tslint:disable-next-line: no-eval
      this.key = eval(newKey);
    }

    this.opc = 0;

    return newKey;
  }

  public picture() {
    const image = this.$refs.image;

    const ctx = image.getContext('2d');
    ctx.clearRect(0, 0, image.width, image.height);
    ctx.font = '30px Arial';

    ctx.strokeStyle = this.$props.color;

    ctx.beginPath();
    ctx.moveTo(0, this.randomNum(0, 50));
    ctx.lineTo(200, this.randomNum(0, 50));
    ctx.stroke();

    ctx.moveTo(0, this.randomNum(0, 50));
    ctx.lineTo(200, this.randomNum(0, 50));
    ctx.stroke();

    ctx.moveTo(0, this.randomNum(0, 50));
    ctx.lineTo(200, this.randomNum(0, 50));
    ctx.stroke();

    ctx.fillText(this.getKey(), this.randomNum(15, 85), this.randomNum(22, 45));
  }

  public randomNum(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public inputText() {
    // tslint:disable-next-line: radix
    const text = (this.$props.mode === 'math') ? Number.parseInt(this.text) : this.text;
    if (this.key === text) {
      this.auth = true;
      this.success = 6;
    }
  }

  public ContentFixed() {
    let el = this.$refs.main;
    let _x = 0;
    let _y = 0;

    /*** position of component for show keyboard ***/
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }

    this.contenTextTop = _y + 10;
    this.contenTextLeft = _x + 210;

    this.opc = (this.opc === 2) ? 0 : 2;
  }

  public selectItem(e) {
    if (this.$props.mode === 'math') {
      return;
    }

    const value = e.target.innerText;
    if (e.target.className.indexOf(' red') === -1) {
      if (e.target.className.indexOf(' blue') === -1) {
        if (this.key.indexOf(value) === -1) {
          this.errorSelect += 1;
          e.target.className += ' red';
        } else {
          this.success += 1;
          e.target.className += ' blue';
        }
      } else {
        e.target.className = e.target.className.replace(' blue', '');
        this.success--;
      }
    } else {
      e.target.className = e.target.className.replace(' red', '');
      this.errorSelect--;
    }

    this.auth = (this.success === 6 && this.errorSelect === 0);
  }

  public selectItemNum(e) {
    if (this.$props.mode === 'text') {
      return;
    }

    const value = e.target.innerText;
    const key = String(this.key);

    if (value !== key) {
      if (e.target.className.indexOf(' red') === -1) {
        e.target.className += ' red';
        this.errorSelect++;
      } else {
        e.target.className = e.target.className.replace(' red', '');
        this.errorSelect--;
      }
    }
    if ((value === key || this.preSuccess) && this.errorSelect === 0) {
      this.auth = true;
      this.success = 6;
    } else if (value === key) {
      this.preSuccess = true;
      e.target.className += ' blue';
    }
  }

  public codex(key) {
    let no = false;
    // tslint:disable-next-line: prefer-for-of
    for (let x=0; x < key.length; x++) {
      let count = -1;
      // tslint:disable-next-line: prefer-for-of
      for (let y=0; y < key.length; y++) {
        if (key[x] === key[y]) {
          count++;
        }
      }
      if (count > 0) {
        no = true;
        break;
      }
    }

    return no;
  }

  public operation() {
    const max = 20;
    const a = Math.floor((Math.random() * max) + 10);
    const b = Math.floor((Math.random() * max) + 10);
    const o = Math.floor((Math.random() * 9) + 0);
    const cadena = `${a} + ${b}`;
    // tslint:disable-next-line: no-eval
    const result = eval(cadena);

    /*** CONTROL ARRAY FOR RANDOM SELECT  ***/
    do {
      const pre = Math.floor((Math.random() * max) + 10);
      if (pre !== result) {
        this.arrayForNum.push(pre);
      }
    } while (this.arrayForNum.length < 10);
    this.arrayForNum[o] = result;
    /***   ***/

    return cadena;
  }
}
