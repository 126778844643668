import { IShortcut } from 'root/models';
import { CRUDGqlService, ICRUDGqlService } from '../Core';

export interface IShortcutGqlService
  extends ICRUDGqlService<IShortcut> {
    //
}
export function shortcutGqlService(): IShortcutGqlService {
  return {
    ...CRUDGqlService<IShortcut>('shortcuts'),
  };
}
