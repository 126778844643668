export enum RouteDictionary {
  Home = '/',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  Account = '/account',
  User = '/users',
  Profile = '/candidates',
  Reference = '/references',
  PublicPage = '/public',
  Guideline = '/user-guides',
  Contract = '/contracts'
}
export enum TitleDictionary {
  Home = 'Trang chủ',
  Login = 'Đăng nhập',
  ForgotPassword = 'Quên mật khẩu',
  Account = 'Tài khoản',
  User = 'Quản lý người dùng',
  Profile = 'Quản lý ứng viên & hồ sơ',
  Reference = 'Quản lý tham chiếu',
  PublicPage = 'Hồ sơ ứng viên',
  Guideline = 'Quản lý hướng dẫn',
  Contract = 'Quản lý hợp đồng'
}
