import CKEditor from 'ckeditor4-vue';
import { IGuideline, IUser } from 'root/models';
import { getFirebaseApp, uploadToFirebase } from 'root/services';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeGuideline } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    ckeditor: CKEditor.component
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.guideline.data,
      authUser: (state: IState) => state.global.authUser
    })
  },
  beforeRouteLeave(_, __, next) {
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  },
  watch: {
    data: {
      deep: true,
      handler(arr) {
        this.sectionOptions = [];
        arr.forEach((item) => {
          this.guidlines[item.section] = item;
          this.sectionOptions.push({
            label: item.section,
            value: item.section
          });
        });
      }
    }
  }
})
export class GuidelineEditForm extends Vue {
  public data: IGuideline[];
  public guidlines: any = {};
  public authUser: IUser;
  public storageRef = getFirebaseApp().storage().ref();
  public form: any = {
    section: '',
    urls: [],
    content: ''
  };
  public editorConfig = {
    fullPage: true,
    allowedContent: true,
    language: 'vi',
    toolbar: [
      { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
      { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button',
        'ImageButton', 'HiddenField' ] }, '/',
      { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-',
        'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote',
        'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr',
        'BidiRtl', 'Language' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar',
        'PageBreak', 'Iframe' ] }, '/',
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      { name: 'about', items: [ 'About' ] }
    ]
  };
  public sectionOptions: any[] = [];

  public mounted() {
    window.addEventListener('beforeunload', this.listener);
    this.$store.dispatch(ActionTypeGuideline.GuidelineFilterNoCache, {
      params: {
        pagingParams: {
          limit: 100,
          page: 1
        }
      }
    });
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public async onChange(file: any, fileList: any[], index: number) {
    fileList[0] = file;
    fileList.pop();
    if (this.form.urls[index].url &&
      this.form.urls[index].url.substring(8, 23) === 'firebasestorage') {
      await new Promise((resolve) => {
        getFirebaseApp().storage().refFromURL(this.form.urls[index].url).delete()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log(err);
              resolve();
            });
      });
    }
    this.form.urls[index].url = await uploadToFirebase(file.raw, `documents/`);
  }

  public async deleteUrl(index: number) {
    if (this.form.urls[index].url &&
      this.form.urls[index].url.substring(8, 23) === 'firebasestorage') {
      await new Promise((resolve) => {
        getFirebaseApp().storage().refFromURL(this.form.urls[index].url).delete()
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.log(err);
              resolve();
            });
      });
    }
    this.form.urls.splice(index, 1);
  }

  public setSection(value: string) {
    if (!this.guidlines[value]) {
      this.form = {
        section: value,
        urls: [],
        content: ''
      };

      return;
    }
    const { section, urls, content } = this.guidlines[value];
    this.form.section = section;
    this.form.urls = urls;
    this.form.content = content;
  }

  public addUrl(array: any[]) {
    array.push({
      focus: true,
      name: '',
      url: ''
    });
  }

  public submitForm() {
    const { section, content } = this.form;
    const urls = this.form.urls.map((url) => {
      return { name: url.name, url: url.url };
    });
    this.$store.dispatch(ActionTypeGuideline.GuidelineUpsert, {
      params: {
        constraint: 'guidelines_section_key',
        update_columns: ['urls', 'content', 'note']
      },
      form: {
        section,
        urls,
        content,
        updatedBy: this.authUser.id
      },
      onSuccess: () => {
        this.$message.success(this.$t('guideline_created_successfully').toString());
      },
      onFailure: (error) => {
        this.$message.error(this.$t(error.message.substring(15)).toString());
      }
    });
  }
}
