import { getRole } from 'root/helpers';
import { IUser } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    visible: Boolean
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading,
      authUser: (state: IState) => state.global.authUser
    })
  },
  watch: {
    authUser(val) {
      if (!val || !val.profileOfUser) {
        return;
      }
      if (val.profileOfUser.department) {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
          id: val.profileOfUser.department,
          onSuccess: (res) => {
            this.department = res[0].name;
          }
        });
      }
      if (val.profileOfUser.unit) {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
          id: val.profileOfUser.unit,
          onSuccess: (res) => {
            this.unit = res[0].name;
          }
        });
      }
      if (!val.profileOfUser.team) {
        return;
      }
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: val.profileOfUser.team,
        onSuccess: (res) => {
          this.team = res[0].name;
        }
      });
    }
  }
})
export class UserInfo extends Vue {
  public loading: boolean;
  public authUser: IUser;
  public department: string = '';
  public unit: string = '';
  public team: string = '';

  public mounted() {
    if (!this.authUser || !this.authUser['profileOfUser']) {
      return;
    }
    if (this.authUser['profileOfUser'].department) {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: this.authUser['profileOfUser'].department,
        onSuccess: (res) => {
          this.department = res[0].name;
        }
      });
    }
    if (this.authUser['profileOfUser'].unit) {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: this.authUser['profileOfUser'].unit,
        onSuccess: (res) => {
          this.unit = res[0].name;
        }
      });
    }
    if (!this.authUser['profileOfUser'].team) {
      return;
    }
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
      id: this.authUser['profileOfUser'].team,
      onSuccess: (res) => {
        this.team = res[0].name;
      }
    });
  }

  public roleFormatter(role: string) {
    return getRole(role);
  }

  public closeDialog() {
    this.$emit('update:visible', false);
  }
}
