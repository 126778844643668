import moment from 'moment';
import { IUser } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data[0]
    })
  },
  watch: {
    data(val) {
      if (!val || !val.profileOfUser) {
        return;
      }
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
        id: val.profileOfUser.positionCode,
        onSuccess: (res) => {
          if (!res[0]) {
            return;
          }
          this.position = res[0].name;
        }
      });
    }
  }
})
export class HPPublicSidebar extends Vue {
  public data: IUser;
  public position: string = '';
  public smallWidth = 440;
  public width: number = window.screen.width;

  public mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.width = window.screen.width;
      });
    });
  }

  public formatDate(date: string) {
    if (!date) {
      return '';
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  public getFullName(obj: any) {
    if (!obj) {
      return '';
    }
    if (!obj.middleName) {
      return `${obj.lastName} ${obj.firstName}`;
    }

    return `${obj.lastName} ${obj.middleName} ${obj.firstName}`;
  }
}
