import { IGuideline } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IGuidelineState extends ICRUDState<IGuideline> {
}

export const defaultState: IGuidelineState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('guideline')
};
