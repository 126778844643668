import { crudQuery, ICRUDQuery } from 'root/store/helpers';
const PREFIX = 'contract_templates';

const model = `id
  name
  typeId
  paperType
  contractType {
    code
  }
  note
  content
  docFile
  createdByUser {
    fullName
  }
  `;

export const ContractTemplateQuery: ICRUDQuery = {
  ...crudQuery(PREFIX, model)
};
