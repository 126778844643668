import * as XLSX from 'xlsx';

export function confirm(self, { id, actionType, onSuccess }) {
  self.$msgbox({
    title: self.$t('delete_confirm').toString(),
    message: self.$t('delete_confirm_message').toString(),
    showCancelButton: true,
    confirmButtonText: self.$t('confirm').toString(),
    cancelButtonText: self.$t('cancel').toString(),
    beforeClose: (action, instance, done) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = 'Loading...';
        self.$store.dispatch(actionType, {
          id,
          onSuccess: () => {
            instance.confirmButtonLoading = false;
            done();
            onSuccess();
          },
          onFailure: () => {
            done();
            instance.confirmButtonText = self.$t('confirm').toString();
            instance.confirmButtonLoading = false;
          }
        });
      } else {
        done();
      }

      return;
    }
  }).then(() => {
    self.$message({
      type: 'info',
      message: this.$t('deleted_successfully').toString()
    });
  }).catch(() => {
    // no handle
  });
}

export function confirmAction(self, {title, message, handleFunction}) {
  self.$confirm(
    message,
    title,
    {
      confirmButtonText: self.$t('confirm').toString(),
      cancelButtonText: self.$t('cancel').toString(),
      type: 'info'
    })
    .then(() => {
      handleFunction();
    })
    .catch(() => {
      //
    });
}

export function confirmAlert(self, {title, message, handleFunction}) {
  self.$alert(
    message,
    title,
    {
      confirmButtonText: self.$t('confirm').toString(),
      callback: handleFunction
    }
  );
}

export function formatJson(filterVal, jsonData) {
  return jsonData.map((v) => filterVal.map((j) => {
    // if (j === 'timestamp') {
    //   return formatTimeDuration(v[j]);
    // }
    if (typeof v[j] === 'boolean') {
      v[j] = v[j] ? 1 : 0;
    }

    return v[j];
  }));
}

export function processExcelToJson(data: any) {
  // Read the Excel File data.
  const workbook = XLSX.read(data, {
    type: 'binary',
    cellDates: true,
    dateNF: 'dd/mm/yyyy'
  });

  // Fetch the name of First Sheet.
  const firstSheet = workbook.SheetNames[0];

  // Read all rows from First Sheet into an JSON array.
  // const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
  // const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
  const utils: any = XLSX.utils;

  return utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
}

export function flatten(data: any) {
  const result = {};
  function recurse(cur: any, prop: string) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; ++i) {
        recurse(cur[i], `${prop}[${i}]`);
      }
      if (l === 0) {
        result[prop] = [];
      }
    } else {
      let isEmpty = true;
      for (const p of Object.keys(cur)) {
        isEmpty = false;
        recurse(cur[p], prop ? `${prop}.${p}` : `${p}`);
      }
      if (isEmpty && prop) {
        result[prop] = {};
      }
    }
  }
  recurse(data, '');

  return result;
}
