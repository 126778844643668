import { ISignatory } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface ISignatoryState extends ICRUDState<ISignatory> {
}

export const defaultState: ISignatoryState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('signatory')
};
