import lodash from 'lodash';
import { hpGqlClient } from 'root/api';
import { confirmAction, ruleEmail, rulePhoneNumber, ruleRequired } from 'root/helpers';
import { candidateFormDefault, EmployeeStatus, ICandidateForm, ReferenceTableName } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../../ProfileStore';
import { ActionTypeUser } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      authUser: (state: IState) => state.global.authUser
    })
  },
  beforeRouteLeave(_, __, next) {
    if (this.noWarning) {
      window.removeEventListener('beforeunload', this.listener);
      next();

      return;
    }
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  },
  watch: {
    authUser(val) {
      if (this.form.emailCc.length) {
        return;
      }
      this.form.emailCc.push(val.email);
      this.ccDict[val.email] = 1;
    },
    'form.company'(val) {
      this.valueChange(val, 0);
    },
    'form.division'(val) {
      this.valueChange(val, 1);
    },
    'form.center'(val) {
      this.valueChange(val, 2);
    },
    'form.department'(val) {
      this.valueChange(val, 3);
    },
    'form.unit'(val) {
      this.valueChange(val, 4);
    },
    'form.team'(val) {
      this.valueChange(val, 5);
    },
    'form.positionLevel'(val) {
      if (this.form['locked']) {
        return;
      }
      if (this.form.positionCode) {
        this.$set(this.form, 'positionCode', '');
      }
      this.positionOptions = [];
      if (!val || val === '-') {
        return;
      }
      const arr = val.split('_');
      this.optionChange(`${arr[1]}_${arr[2]}`, 'position', `positionOptions`);
    }
  }
})

export class CandidateCreateForm extends Vue {
  public authUser: any;
  public form: ICandidateForm = candidateFormDefault();
  public code: string = '';
  public loading: boolean = false;
  public noWarning: boolean = false;
  public rehire: boolean = false;
  public tree: string[] = ['company', 'division', 'center', 'department',
    'unit', 'team', 'level', 'position'];
  public inputCc: string = null;
  public ccDict: any = {};
  public attch: any = null;
  public debounceCheck = lodash.debounce(this.checkRehire, 1000);
  public pagingParams: any = {
    limit: 240,
    page: 1
  };

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      phone: rulePhoneNumber()
    };
  }
  public defaultOption: any = {
    label: '-',
    value: '-'
  };
  public referenceGroups: any[] = [
    { key: 'companyOptions', name: ReferenceTableName.companyTable }
  ];

  public companyOptions: any[] = [];
  public divisionsOfCompany: any[] = [];
  public centersOfCompany: any[] = [];
  public centersOfDivision: any[] = [];
  public departmentsOfCompany: any[] = [];
  public departmentsOfDivision: any[] = [];
  public departmentsOfCenter: any[] = [];
  public unitsOfCompany: any[] = [];
  public unitsOfDivision: any[] = [];
  public unitsOfCenter: any[] = [];
  public unitsOfDepartment: any[] = [];
  public teamsOfCompany: any[] = [];
  public teamsOfDivision: any[] = [];
  public teamsOfCenter: any[] = [];
  public teamsOfDepartment: any[] = [];
  public teamsOfUnit: any[] = [];
  public levelsOfCompany: any[] = [];
  public levelsOfDivision: any[] = [];
  public levelsOfCenter: any[] = [];
  public levelsOfDepartment: any[] = [];
  public levelsOfUnit: any[] = [];
  public levelsOfTeam: any[] = [];
  public positionOptions: any[] = [];

  public mounted() {
    this.$nextTick(async () => {
      this.rehire = this.$route.path.split('/')[2] === 'rehire' ? true : false;
      if (this.authUser && !this.form.emailCc.length) {
        this.form.emailCc.push(this.authUser.email);
        this.ccDict[this.authUser.email] = 1;
      }
      window.addEventListener('beforeunload', this.listener);
      if (this.$route.path !== '/candidates/new') {
        await this.$store.dispatch(ActionTypeProfile.ProfileFindById, {
          id: this.$route.params.id,
          onSuccess: (data) => {
            if (!data[0]) {
              return;
            }
            const { email, firstName, middleName, lastName, phone, startDate,
              company, division, center, department, unit, team, positionLevel,
              positionCode, startLocation } = data[0];
            lodash.assign(this.form, { email, firstName, middleName, lastName, phone, startDate, startLocation });
            const temp = {
              company,
              division,
              center,
              department,
              unit,
              team,
              level: positionLevel,
              position: positionCode,
            };
            this.firstLoad(temp);
          }
        });
      }
      this.referenceGroups.forEach((reference) => {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
          params: {
            pagingParams: this.pagingParams,
            filterParams: {
              tableName: reference.name
            },
            onSuccess: (data: any) => {
              data.forEach((record: any) => {
                if (reference.key === 'companyOptions') {
                  this[`${reference.key}`].push({
                    label: record.name,
                    value: `${record.id}_${record.name}`
                  });
                } else {
                  this[`${reference.key}`].push({
                    label: record.name,
                    value: record.id
                  });
                }
              });
            }
          }
        });
      });
    });
  }

  public async firstLoad(form: any) {
    this.loading = true;
    for (let i = 0; i < 8; ++i) {
      if (!form[this.tree[i]] || form[this.tree[i]] === '-') {
        this.form['locked'] = true;
        this.form[this.tree[i]] = form[this.tree[i]];
        await this.valueChange(form[this.tree[i]], i, true);
        continue;
      }
      await new Promise((resolve) => {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
          id: form[this.tree[i]],
          onSuccess: async (data: any) => {
            if (!data[0]) {
              resolve();
            }
            if (i < 6) {
              this.form['locked'] = true;
              this.form[this.tree[i]] = `${data[0].id}_${data[0].name}`;
              await this.valueChange(`${data[0].id}_${data[0].name}`, i, true);
            } else if (i === 6) {
              this.form['locked'] = true;
              this.form.positionLevel = `${data[0].id}_${data[0].name}_${data[0].superior}`;
              await this.optionChange(`${data[0].name}_${data[0].superior}`,
                'position', `positionOptions`);
              this.form['locked'] = false;
            } else {
              this.form.positionCode = data[0].id;
            }
            resolve();
          }
        });
      });
    }
    this.loading = false;
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public addCc() {
    this.inputCc = this.inputCc.trim();
    if (!this.inputCc || this.ccDict[this.inputCc]) {
      return;
    }
    // tslint:disable-next-line: max-line-length
    const pattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(this.inputCc)) {
      this.$message.warning(this.$t('invalid_email').toString());

      return;
    }
    if (this.form.emailCc.length === 5) {
      this.$message.warning(this.$t('max_number_cc').toString());

      return;
    }
    this.form.emailCc.push(this.inputCc);
    this.ccDict[this.inputCc] = 1;
    this.inputCc = '';
  }

  public removeCc(index: number) {
    delete this.ccDict[this.form.emailCc[index]];
    this.form.emailCc.splice(index, 1);
  }

  public cancelForm() {
    this.$router.push('/candidates');
  }

  public async submitForm() {
    Object.keys(this.form).forEach((key) => {
      this.form[key] = typeof this.form[key] === 'string' && this.form[key] ? this.form[key].trim() : this.form[key];
    });

    if (this.rehire) {
      return this.createRehire();
    }
    const check = await this.checkRehire();
    if (!check || !this.validateForm()) {
      return;
    }
    const { email, firstName, middleName, lastName, phone, startDate, startTime, emailCc,
      password, company, division, center, department, unit, team, positionLevel,
      positionCode, contactPerson, contactNumber, startLocation, workingLocation } = this.form;
    const fullName = middleName ? `${lastName} ${middleName} ${firstName}` :
      `${lastName} ${firstName}`;
    hpGqlClient.user.createUser({
      email,
      password,
      fullName,
      startDate,
      role: 'guest'
    })
    .then((user) => {
      this.$store.dispatch(ActionTypeProfile.ProfileCreate, {
        form: {
          email,
          phone,
          firstName,
          middleName,
          lastName,
          startDate,
          startTime,
          emailCc,
          positionCode,
          startLocation,
          workingLocation,
          contactPerson,
          contactNumber,
          signDate: startDate,
          effectiveDate: startDate,
          effDate: startDate,
          actualDate: startDate,
          dop: startDate,
          company: company.split('_')[0],
          division: division ? division.split('_')[0] : '',
          center: center ? center.split('_')[0] : '',
          department: department ? department.split('_')[0] : '',
          unit: unit ? unit.split('_')[0] : '',
          team: team ? team.split('_')[0] : '',
          positionLevel: positionLevel.split('_')[0],
          userId: user.id,
          updatedBy: this.authUser.id
        },
        onSuccess: (profile) => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id: user.id,
            form: {
              profile: profile.id
            },
            onSuccess: () => {
              this.$message.success(this.$t('candidate_created_successfully').toString());
              this.noWarning = true;
              this.$router.push('/candidates');
              // const url = await uploadToFirebase(this.attch, `${user.id}/jds/`);
              // hpGqlClient.user.sendEmail(user.id, url)
              //   .then((res) => {
              //     getFirebaseApp().storage().refFromURL(url).delete()
              //       .then(() => {
              //         this.$message.success(this.$t(res.result).toString());
              //         this.$router.push('/candidates');
              //       })
              //       .catch((err) => {
              //         console.log(err);
              //       });
              //   })
              //   .catch((error: any) => {
              //     this.$message.error(this.$t(error.result).toString());
              //   });
            },
            onFailure: (error) => {
              this.$message.error(this.$t(error.message.substring(15)).toString());
            }
          });
        },
        onFailure: (error) => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id: user.id,
            form: {
              status: 'deleted'
            }
          });
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    })
    .catch((error) => {
      this.$message.error(this.$t(error.message.substring(15)).toString());
    });
  }

  public async valueChange(val: string, pos: number, force: boolean = false) {
    if (this.form['locked'] && !force) {
      return;
    }
    this.form['locked'] = true;
    this.resetFields(pos + 1);
    this.resetOptions(pos + 1);
    if (!val) {
      for (let j = pos - 1; j >= 0; --j) {
        if (!this.form[this.tree[j]] || this.form[this.tree[j]] === '-') {
          continue;
        }
        await this.optionChange(this.form[this.tree[j]], 'level',
          `levelsOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`);
        break;
      }
      this.form['locked'] = false;

      return;
    }
    for (const i of (pos === 5 ? [6] : [pos + 1, 6])) {
      if (val !== '-') {
        await this.optionChange(val, this.tree[i],
          `${this.tree[i]}sOf${this.tree[pos].replace(/^\w/, (c) => c.toUpperCase())}`);
        continue;
      }
      for (let j = pos - 1; j >= 0; --j) {
        if (!this.form[this.tree[j]] || this.form[this.tree[j]] === '-') {
          continue;
        }
        await this.optionChange(this.form[this.tree[j]], this.tree[i],
          `${this.tree[i]}sOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`);
        break;
      }
    }
    this.form['locked'] = false;
  }

  public resetFields(from: number) {
    for (let i = from; i < 6; ++i) {
      if (this.form[this.tree[i]]) {
        this.$set(this.form, this.tree[i], '');
      }
    }
    if (this.form.positionLevel) {
      this.$set(this.form, 'positionLevel', '');
    }
    if (this.form.positionCode) {
      this.$set(this.form, 'positionCode', '');
    }
  }

  public resetOptions(from: number) {
    for (let i = from; i < 7; ++i) {
      for (let j = i - 1; j >= 0; --j) {
        this[`${this.tree[i]}sOf${this.tree[j].replace(/^\w/, (c) => c.toUpperCase())}`] = [];
      }
    }
    this.positionOptions = [];
  }

  public async optionChange(value: string, key: string, options: string) {
    await new Promise((resolve) => {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            superior: key === 'position' ? value : value.split('_')[1],
            tableName: ReferenceTableName[`${key}Table`]
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              this[options].push({
                label: record.name,
                value: key === 'position' ? record.id :
                  (key === 'level' ? `${record.id}_${record.name}_${record.superior}` :
                  `${record.id}_${record.name}`)
              });
            });
            resolve();
          }
        }
      });
    });
  }

  public async onChange(file: any) {
    this.attch = file.raw;
  }

  public async onRemove() {
    this.attch = null;
  }

  public async codeChange(value: string) {
    const arr = value.split('.');
    if (arr.length < 8) {
      return;
    }
    this.loading = true;
    this.resetFields(0);
    this.resetOptions(1);
    let lastSuperior: number = 5;
    for (let i = 5; i >= 0; --i) {
      if (arr[i] === '0'.repeat(i + 1)) {
        continue;
      }
      lastSuperior = i;
      break;
    }
    for (let i = 0; i < 6; ++i) {
      if (arr[i] === '0'.repeat(i + 1)) {
        this.form['locked'] = true;
        this.form[this.tree[i]] = i < lastSuperior ? '-' : '';
        await this.valueChange(this.form[this.tree[i]], i, true);
        continue;
      }
      await this.query(value, i);
    }
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
      params: {
        pagingParams: this.pagingParams,
        filterParams: {
          superior: this.form[this.tree[lastSuperior]].split('_')[1],
          tableName: ReferenceTableName.levelTable
        },
        onSuccess: (res: any) => {
          if (!res[0]) {
            return;
          }
          const levels = {};
          res.forEach((obj) => {
            levels[`${obj.name}_${obj.superior}`] = `${obj.id}_${obj.name}_${obj.superior}`;
          });
          this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
            params: {
              pagingParams: this.pagingParams,
              filterParams: {
                code: `${arr[6]}.${arr[7]}`,
                tableName: ReferenceTableName.positionTable,
                superior: Object.keys(levels)
              },
              onSuccess: async (res2: any) => {
                if (!res2[0]) {
                  return;
                }
                this.form['locked'] = true;
                this.form.positionLevel = levels[res2[0].superior];
                const tmp = levels[res2[0].superior].split('_');
                await this.optionChange(`${tmp[1]}_${tmp[2]}`,
                  'position', `positionOptions`);
                this.form.positionCode = res2[0].id;
                this.loading = false;
              }
            }
          });
        }
      }
    });
  }

  public async createRehire() {
    if (!this.validateForm()) {
      return;
    }
    const { email, firstName, middleName, lastName, phone, startDate, startTime, emailCc,
      password, company, division, center, department, unit, team, positionLevel,
      positionCode, contactPerson, contactNumber, startLocation, workingLocation } = this.form;
    const fullName = middleName ? `${lastName} ${middleName} ${firstName}` :
      `${lastName} ${firstName}`;
    hpGqlClient.user.createRehire({
      email,
      password,
      fullName,
      startDate,
      role: 'guest'
    })
    .then((res) => {
      this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
        id: res.profile,
        form: {
          phone, startDate, startTime, emailCc,
          positionCode, contactPerson,
          contactNumber, startLocation, workingLocation,
          signDate: startDate,
          effectiveDate: startDate,
          effDate: startDate,
          actualDate: startDate,
          dop: startDate,
          company: company.split('_')[0],
          division: division ? division.split('_')[0] : '',
          center: center ? center.split('_')[0] : '',
          department: department ? department.split('_')[0] : '',
          unit: unit ? unit.split('_')[0] : '',
          team: team ? team.split('_')[0] : '',
          positionLevel: positionLevel.split('_')[0],
        },
        onSuccess: () => {
          this.$message.success(this.$t('candidate_created_successfully').toString());
          this.noWarning = true;
          this.$router.push('/candidates');
          // hpGqlClient.user.sendEmail(user.id)
          //   .then(() => {
          //     this.$message.success(this.$t(res.result).toString());
          //     this.$router.push('/candidates');
          //   })
          //   .catch((error: any) => {
          //     this.$message.error(this.$t(error.result).toString());
          //   });
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    })
    .catch((error) => {
      this.$message.error(this.$t(error.message.substring(15)).toString());
    });
  }

  private checkRehire() {
    // tslint:disable-next-line: max-line-length
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(this.form.email)) {
      this.$message.error(this.$t('invalid_email').toString());

      return false;
    }

    return new Promise((resolve, reject) => {
      hpGqlClient.user.checkRehire(this.form.email)
        .then((res) => {
          console.log(res);
          if (!res) {
            return resolve(true);
          }
          if ([EmployeeStatus.New, EmployeeStatus.Active, EmployeeStatus.Rehire].includes(res.status)) {
            this.$message.error(this.$t('user_existed').toString());

            return resolve(false);
          }
          const vnStatus = res === EmployeeStatus.Rejected ? 'từ chối' : 'nghỉ việc';
          confirmAction(this, {
            title: 'Tạo hồ sơ ứng tuyển',
            message: `Ứng viên ${res.fullName} đã ${vnStatus}. Tạo lại hồ sơ ứng tuyển cho ứng viên?`,
            handleFunction: () => {
              this.$store.dispatch(ActionTypeProfile.ProfileFindById, {
                id: res.profile,
                onSuccess: async (data: any) => {
                  if (!data[0]) {
                    return;
                  }
                  this.rehire = true;
                  this.inputCc = '';
                  const { startTime, emailCc, password, contactPerson, contactNumber,
                    workingLocation } = candidateFormDefault();
                  const { email, firstName, middleName, lastName, phone, startDate, company, division,
                    center, department, unit, team, positionLevel, positionCode, startLocation } = data[0];
                  lodash.assign(this.form, { email, firstName, middleName, lastName, phone, startDate,
                    startTime, emailCc, password, contactPerson, contactNumber, startLocation, workingLocation });
                  if (this.authUser && !this.form.emailCc.length) {
                    this.form.emailCc.push(this.authUser.email);
                    this.ccDict[this.authUser.email] = 1;
                  }
                  const temp = {
                    company,
                    division,
                    center,
                    department,
                    unit,
                    team,
                    level: positionLevel,
                    position: positionCode,
                  };
                  await this.firstLoad(temp);

                  return resolve(false);
                },
                onFailure: (error) => {
                  reject(error);
                }
              });
            }
          });
        })
        .catch ((error) => {
          reject(error);
        });
    });
  }

  private validateForm() {
    const { email, firstName, lastName, phone, startDate, startTime, positionCode,
      contactPerson, contactNumber, startLocation, workingLocation } = this.form;
    if (!email || !firstName || !lastName || !contactPerson || !phone || !startTime ||
      !startDate || !positionCode || !contactNumber || !startLocation || !workingLocation) {
      this.$message.error(this.$t('required_fields_cannot_be_empty').toString());

      return false;
    }
    // tslint:disable-next-line: max-line-length
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!pattern.test(email)) {
      this.$message.error(this.$t('invalid_email').toString());

      return false;
    }
    pattern = /^[0-9]+\s*$/;
    if (phone && (phone.length !== 10 || !pattern.test(phone))
      || contactNumber && (contactNumber.length !== 10 || !pattern.test(contactNumber))) {
      this.$message.error(this.$t('invalid_phone_number').toString());

      return false;
    }

    return true;
  }

  private query(value: string, index: number) {
    const arr = value.split('.');
    const key = this.tree[index];
    let superior: string = '';
    for (let i = index - 1; i >= 0; --i) {
      if (!this.form[this.tree[i]] || this.form[this.tree[i]] === '-') {
        continue;
      }
      superior = this.form[this.tree[i]].split('_')[1];
      break;
    }

    return new Promise((resolve, reject) => {
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            code: arr[index],
            superior: superior ? superior : 'None',
            tableName: ReferenceTableName[`${key}Table`]
          },
          onSuccess: async (data: any) => {
            if (!data[0]) {
              return;
            }
            this.form['locked'] = true;
            this.form[key] = `${data[0].id}_${data[0].name}`;
            await this.valueChange(`${data[0].id}_${data[0].name}`, index, true);
            resolve();
          },
          onFailure: (err) => {
            reject(err);
          }
        }
      });
    });
  }
}
