import Vue from 'vue';
import Component from 'vue-class-component';

import { Form } from 'element-ui';
import { FooterGuest } from 'root/components';
import { ruleCompare, ruleEmail, ruleMinLength, ruleRequired } from 'root/helpers';
import { RouterDictionaryName } from 'root/models';
import { HPAuth, IResetPasswordForm } from 'root/services';
import { Route } from 'vue-router';

@Component({
  template: require('./view.html'),
  components: {
    'footer-guest': FooterGuest
  },
  beforeRouteLeave: async (to: Route, _, next) => {
    const isRequired = !to.meta.isGuest
      && (to.name !== RouterDictionaryName.SignUp.toString()
          && to.name !== RouterDictionaryName.ForgotPassword.toString()),
      isAuth = await HPAuth.getAccessToken();

    if (isRequired && !isAuth) {
      return next('/forgot-password');
    }

    return next();
  }
})

export class ForgotPassword extends Vue {
  public $refs: {
    form: Form
  };
  public loading: boolean = false;
  public confirmStep: boolean = false;
  public form: IResetPasswordForm = {
    email: '',
    code: '',
    newPassword: '',
    retypePassword: ''
  };
  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail(),
      min: ruleMinLength(8),
      compare: ruleCompare(this.form.newPassword)
    };
  }

  public submit() {
    // this.$refs.form.validate(async (valid) => {
    //   if (valid) {
    //     this.loading = true;
    //     const form = cloneDeep(this.form);
    //     try {
    //       if (!this.confirmStep) {
    //         const data = await HPAuth.forgotPassword(form.email);
    //         if (data) {
    //           this.loading = false;
    //           this.confirmStep = true;

    //           return;
    //         }
    //       } else {
    //         await HPAuth.resetPassword(form);
    //         this.loading = false;
    //         window.alert('Change password successfully!');
    //         this.$router.push('/login');
    //       }
    //     } catch (error) {
    //       this.loading = false;
    //       this.$store.dispatch(ActionType.CatchException, error);
    //     }
    //   }

    //   return;
    // });
  }
}
