import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown, HPPage } from 'root/components';
import { confirmAction } from 'root/helpers';
import { IReference } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeReference } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.reference.data,
      pagination: (state: IState) => state.reference.pagination,
      loading: (state: IState) => state.reference.loading
    })
  }
})
export class ReferenceTable extends Vue {
  public data: IReference[];
  public pagination: IPaginationParams;
  public listOption: number = 10;
  public listOptions: any[] = [
    {
      value: '10 kết quả mỗi trang',
      key: 10
    },
    {
      value: '20 kết quả mỗi trang',
      key: 20
    },
    {
      value: '50 kết quả mỗi trang',
      key: 50
    }
  ];
  public filters: any = {
    status: ''
  };

  public actionOptions: any[] = [
    {
      value: 'Xoá tham chiếu',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');

    if (actions[2] === 'filter') {
      this.listOption = parseInt(actions[0], 10);
      this.$store.dispatch(ActionTypeReference.ReferenceFilterNoCache, {
        params: {
          pagingParams: {
            limit: this.listOption,
            page: 1
          }
        }
      });

      return;
    }
    const index = actions[1];
    const id = this.data[index].id;

    switch (actions[0]) {
    case 'detail':
      this.$router.push(`/references/edit/${id}`);
      break;
    case 'delete':
      confirmAction(this, {
        title: 'Xoá tham chiếu',
        message: `Xác nhận xoá tham chiếu ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeReference.ReferenceDelete, {id})
            .then(() => {
              this.$message.success(this.$t('reference_deleted_successfully').toString());
            })
            .catch(() => {
              this.$message.error(this.$t('cannot_delete_reference').toString());
            });
        }
      });
      break;
    default:
      break;
    }
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeReference.ReferenceFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }

  public mounted() {
    this.$nextTick(() => {
      this.$store.dispatch(ActionTypeReference.ReferenceFilterNoCache, {
        params: {
          pagingParams: {
            limit: 10,
            page: 1
          }
        }
      });
    });
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public convertTime(time: string) {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };

    return new Date(time).toLocaleDateString('vi-VN', options);
  }
}
