// import { PhoneNumberUtil } from 'google-libphonenumber';
import { isNil } from 'lodash';
import { i18n } from 'root/locales';

export interface IMessage {
  text: string;
}

function parseMessage(message: string | IMessage, callback) {
  const result = typeof message === 'string' ?
    i18n().t(message).toString() : message.text;

  return callback(new Error(result));
}

function wrapRule(rule) {
  return { validator: rule, trigger: ['blur', 'change'] };
}

export function ruleRequired(message = 'field_required') {
  return wrapRule((_rule, value, callback) => {
    if (value === null || value === undefined) {
      return parseMessage(message, callback);
    }

    return (typeof value === 'string' && value.trim() && callback()
      || Object.prototype.toString.call(value) === '[object Date]' && value && callback())
      || (Array.isArray(value) ? value.length > 0 && callback() : (value ? callback() : false))
      || parseMessage(message, callback);
  });
}

export function ruleEmail(message = 'invalid_email') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // tslint:enable

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function ruleNumber(message = 'invalid_number') {
  return wrapRule((_rule, value, callback) => {
    const pattern = /^[0-9]*$/;
    if (value === null || value === undefined) {
      return false;
    }

    return pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function ruleText(message = 'invalid_text') {
  return wrapRule((_rule, value, callback) => {
    const pattern = /^[a-zA-Z ]*$/;
    if (value === null || value === undefined) {
      return false;
    }
    value = value.toLowerCase();
    value = value.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    value = value.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    value = value.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    value = value.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    value = value.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    value = value.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    value = value.replace(/đ/g, 'd');

    return pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function ruleID(message = 'invalid_id') {
  return wrapRule((_rule, value, callback) => {
    const pattern = /^[0-9]*$/;
    // tslint:enable
    if (value.length !== 9 && value.length !== 12) {
      return parseMessage(message, callback);
    }

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}

export function ruleDob(message = 'invalid_dob') {
  return wrapRule((_rule, value, callback) => {
    const date = new Date(value);
    date.setFullYear(date.getFullYear() + 15);
    const now = new Date();

    return !value || date.getTime() <= now.getTime() || parseMessage(message, callback);
  });
}

export function ruleCompare(targetValue: string, message = 'value_not_compare') {
  return wrapRule((_rule: any, value: string, callback: any) => {
    if (isNil(value)) {
      return parseMessage(message, callback);
    }

    return (typeof value === 'string' && value === targetValue && callback())
      || parseMessage(message, callback);
  });
}

export function rulePhoneNumber(message?: string) {
  message = message || 'invalid_phone_number';
  // const util = PhoneNumberUtil.getInstance();
  const pattern = /^[0-9]+\s*$/;

  return wrapRule((_rule, value, callback) => {

    if (value && (value.length !== 10 || !pattern.test(value))) {
      return parseMessage(message, callback);
    }

    // const num = util.parse(value, countryCode);

    // return util.isValidNumber(num) || parseMessage(message, callback);
    return true;
  });
}

export function ruleMinLength(min: number, message?: string) {

  message = message || `At_least_${min}_characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min) || parseMessage(message, callback);
  });
}

export function ruleMaxLength(max: number, message?: string) {

  message = message || `Maximum ${max} characters`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length <= max) || parseMessage(message, callback);
  });
}

export function ruleBetween(min: number, max: number, message?: string) {

  message = message || `Character length must be between ${min} and ${max}`;

  return wrapRule((_rule, value, callback) => {
    return (value && value.length >= min && value.length <= max) || parseMessage(message, callback);
  });
}

export function matchYoutubeUrl(message = 'youtube_link_invalid') {
  return wrapRule((_rule, value, callback) => {
    // tslint:disable
    const pattern = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    // tslint:enable

    return !value || pattern.test(value.trim()) || parseMessage(message, callback);
  });
}
