import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    steps: Boolean,
    page: Number,
    totalPages: Number
  },
  watch: {
    page(val) {
      this.inputPage = `${val}`;
    }
  }
})
export class HPPage extends Vue {
  public inputPage: string = '1';
  public goToPage(num: number) {
    this.$emit('goPage', num);
  }
}
