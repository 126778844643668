import Vue from 'vue';
import Component from 'vue-class-component';
import { ContractTable } from './ContractTable';
import { ShortcutTable } from './ShortcutTable';
import { SignatoryTable } from './SignatoryTable';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    formActive: String
  },
  components: {
    'contract-table': ContractTable,
    'signatory-table': SignatoryTable,
    'shortcut-table': ShortcutTable
  },
  watch: {
    formIsActive(val) {
      this.$emit('activeForm', val);
    }
  }
})
export class ContractManager extends Vue {
  public formIsActive: string = '';

  public mounted() {
    this.formIsActive = this.$props.formActive;
  }
}
