import { HPAuth } from 'root/services/';
import { GraphQLClient } from './client';

const gqlClient = GraphQLClient({
  httpURL: process.env.GRAPHQL_HOST,
  wsURL: process.env.GRAPHQL_WS_HOST,
  authenticator: HPAuth
});

export {
  gqlClient
};

export * from './utils';
export * from './crud';
