import { IHttpServiceClientOptions } from '../Core';

export interface IGlobalHttpService {
  getConfiguration(): Promise<any>;
}

export function GlobalHttpService(options: IHttpServiceClientOptions): IGlobalHttpService {

  const url = options.httpService.getUrl('/global/configuration', options.mock);
  // const url = options.httpService.getUrl('/global/configuration', true);

  function getConfiguration(): Promise<any> {
    return options.httpService.get(url);
  }

  return {
    getConfiguration
  };
}
