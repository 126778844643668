import { ruleRequired } from 'root/helpers';
import { ReferenceTableName } from 'root/models';
import { signatoryFormDefault, ISignatory } from 'root/models/Contract';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeSignatory } from '../../SignatoryStore';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.signatory.data,
      authUser: (state: IState) => state.global.authUser
    })
  },
  beforeRouteLeave(_, __, next) {
    if (this.noWarning) {
      window.removeEventListener('beforeunload', this.listener);
      next();

      return;
    }
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  },
})
export class SignatoryCreateForm extends Vue {
  public data: any;
  public authUser: any;
  public loading: boolean = false;
  public form: ISignatory = signatoryFormDefault();
  public noWarning: boolean = false;
  public thisId: string = '';
  public pagingParams: any = {
    limit: 240,
    page: 1
  };
  public referenceGroups: any[] = [
    { key: 'poiOptions', name: ReferenceTableName.IDLocation },
    { key: 'genderOptions', name: ReferenceTableName.Gender },
    { key: 'levelOptions', name: ReferenceTableName.levelTable }
  ];
  public poiOptions = [];
  public levelOptions = [];
  public genderOptions = [];
  public get rules() {
    return {
      required: ruleRequired()
    };
  }

  public mounted() {
    this.$nextTick(async () => {
      window.addEventListener('beforeunload', this.listener);
      if (this.$route.path !== '/contracts/new/signatory') {
        await this.$store.dispatch(ActionTypeSignatory.SignatoryFindById, {
          id: this.$route.params.id,
          onSuccess: (data) => {
            if (!data[0]) {
              return;
            }
            const { authLetter, candidateLevels, fullName, idNo, positionLevel,
              gender, positionName, idDoi, dob, idPoi, permAddr } = data[0];
            this.thisId = data[0].id;
            this.form = {
              authLetter,
              candidateLevels,
              idNo,
              idDoi,
              dob,
              idPoi,
              permAddr,
              gender,
              positionName,
              positionLevel,
              fullName
            };
          }
        });
      }
      const levelName = {};
      this.referenceGroups.forEach((reference) => {
        this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
          params: {
            distinctOn: reference.key === 'levelOptions' ?
              'name' : null,
            orderParams: {
              name: 'asc'
            },
            pagingParams: this.pagingParams,
            filterParams: {
              tableName: reference.name
            },
            onSuccess: (data: any) => {
              data.forEach((record: any) => {
                if (reference.key === 'levelOptions') {
                  if (levelName[record.name.toLowerCase()]) {
                    return;
                  }
                  levelName[record.name.toLowerCase()] = 1;
                  this[`${reference.key}`].push({
                    label: record.name.toLowerCase(),
                    value: record.name.toLowerCase()
                  });
                } else {
                  this[`${reference.key}`].push({
                    label: record.name,
                    value: record.name
                  });
                }
              });
            }
          }
        });
      });
    });
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public cancelForm() {
    this.$router.push('/contracts');
  }

  public async submitForm() {
    for (const key of Object.keys(this.form)) {
      if (this.form[key]) {
        this.form[key] = typeof this.form[key] === 'string' && this.form[key] ? this.form[key].trim() : this.form[key];
        continue;
      }
      this.$set(this.form, key, '');
      this.$message.error(this.$t('required_fields_cannot_be_empty').toString());

      return;
    }

    if (!await this.validateForm()) {
      return;
    }

    if (this.$route.path === '/contracts/new/signatory') {
      this.$store.dispatch(ActionTypeSignatory.SignatoryCreate, {
        form: {
          ...this.form
        },
        onSuccess: () => {
          this.$message.success(this.$t('signatory_created_successfully').toString());
          this.noWarning = true;
          this.$router.push('/contracts');
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    } else {
      this.$store.dispatch(ActionTypeSignatory.SignatoryUpdate, {
        id: this.data[0].id,
        form: {
          ...this.form
        },
        onSuccess: () => {
          this.$message.success(this.$t('signatory_updated_successfully').toString());
        },
        onFailure: (error) => {
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    }
  }

  public validateForm() {
    return new Promise((resolve, reject) => {
      this.$store.dispatch(ActionTypeSignatory.SignatoryFilterNoCache, {
        params: {
          filterParams: {
            _or: [
              {
                candidateLevels: {
                  _has_keys_any: this.form.candidateLevels
                }
              }
            ]
          },
          onSuccess: (res) => {
            if (!res[0] || res[0].id === this.thisId && res.length === 1) {
              return resolve(true);
            }
            let existedLevels = [];
            res.map((item) => {
              if (item.id === this.thisId) {
                return;
              }
              existedLevels = existedLevels.concat(item.candidateLevels);
            });
            existedLevels =
              this.form.candidateLevels.map((l) => existedLevels.find((item) => item === l)).filter((e) => e);
            this.$message.error(`Đã có người ký cho các chức vụ ${existedLevels.join(', ')}`);

            return resolve(false);
          },
          onFailure: (error) => {
            this.$message.error(this.$t(error.message.substring(15)).toString());

            return reject(false);
          }
        }
      });
    });
  }
}
