import { ruleEmail, ruleRequired } from 'root/helpers';
import { ReferenceTableName } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    form: Object
  },
  computed: {
    ...mapState({
      loading: (state: IState) => state.user.loading
    })
  },
  watch: {
    ratio(val) {
      if (!this.form.salaryTotal) {
        return;
      }
      let temp = parseInt(this.form.salaryTotal.replace(/\,/g, ''), 10) * val;
      if (val === 0.3) {
        temp = temp < this.baseSalary ? this.baseSalary : temp;
      }
      this.form.salaryHrisTotal = `${temp}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.form.salaryHrisBase = this.form.salaryHrisTotal;
      temp = parseInt(this.form.salaryTotal.replace(/\,/g, ''), 10) - temp;
      temp = temp < 0 ? 0 : temp;
      this.salaryBonus = `${temp}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    form: {
      handler(val) {
        if (val.positionCode !== this.positionCode) {
          this.positionCode = val.positionCode;
          this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
            id: val.positionCode,
            onSuccess: (data: any) => {
              if (!data[0]) {
                return;
              }
              if (data[0].superior.split('_')[0].toLowerCase() === '*nhân viên dịch vụ') {
                this.ratio = 1;
                this.$props.form.noBonus = true;
              } else {
                this.ratio = 0.3;
              }
            },
            onFailure: () => {
              return;
            }
          });

          return;
        }
        val.bonusMax = val.bonusMax ? `${parseInt(val.bonusMax.replace(/\,/g, ''), 10)}`
          .replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
        const result = val.salaryTotal ? `${parseInt(val.salaryTotal.replace(/\,/g, ''), 10)}`
          .replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
        if (!result) {
          this.form.salaryHrisBase = '';
          this.form.salaryHrisTotal = '';

          return;
        }
        this.form.salaryTotal = result;
        let temp = parseInt(result.replace(/\,/g, ''), 10) * this.ratio;
        if (this.ratio === 0.3) {
          temp = temp < this.baseSalary ? this.baseSalary : temp;
        }
        this.form.salaryHrisTotal = `${temp}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.form.salaryHrisBase = this.form.salaryHrisTotal;
        temp = parseInt(result.replace(/\,/g, ''), 10) - temp;
        temp = temp < 0 ? 0 : temp;
        this.salaryBonus = `${temp}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      deep: true,
    }
  }
})
export class ProfileSalaryForm extends Vue {
  public ratio: number = 0.3;
  public salaryBonus: string = '';
  public positionCode: string = '';
  public baseSalary: number = 0;
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };

  public get rules() {
    return {
      required: ruleRequired(),
      email: ruleEmail()
    };
  }

  public referenceGroups: any = {
    [ReferenceTableName.BaseSalary]: 'baseSalary',
    [ReferenceTableName.SalaryUnit]: 'salaryUnitOptions',
    [ReferenceTableName.SalaryCurrency]: 'salaryCurrencyOptions'
  };
  public salaryUnitOptions: any[] = [];
  public salaryCurrencyOptions: any[] = [];

  public mounted() {
    this.$nextTick(() => {
      let temp = parseInt((this.$props.form.salaryTotal || '0').replace(/\,/g, ''), 10) -
        parseInt((this.$props.form.salaryHrisTotal || '0').replace(/\,/g, ''), 10);
      temp = temp < 0 ? 0 : temp;
      this.salaryBonus = `${temp}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const filter = [];
      if (this.$props.form.positionCode) {
        this.positionCode = this.$props.form.positionCode;
        filter.push({
          id: { _eq: this.$props.form.positionCode }
        });
      }
      Object.keys(this.referenceGroups).forEach((key) => {
        filter.push({
          tableName: { _eq: key }
        });
      });
      this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
        params: {
          pagingParams: this.pagingParams,
          filterParams: {
            _or: filter
          },
          onSuccess: (data: any) => {
            data.forEach((record: any) => {
              if (record.tableName === ReferenceTableName.BaseSalary) {
                this.baseSalary = parseInt(`${parseInt(record.name.replace(/\,/g, ''), 10)}`, 10);

                return;
              }
              if (record.id !== this.$props.form.positionCode) {
                if (record.tableName === ReferenceTableName.SalaryUnit &&
                  record.name === 'Tháng') {
                  this.$props.form.salaryUnit = record.id;
                }
                if (record.tableName === ReferenceTableName.SalaryCurrency &&
                  record.name === 'VND') {
                  this.$props.form.salaryCurrency = record.id;
                }
                this[`${this.referenceGroups[record.tableName]}`].push({
                  label: record.name,
                  value: record.id
                });

                return;
              }
              if (record.superior.split('_')[0].toLowerCase() === '*nhân viên dịch vụ') {
                this.ratio = 1;
                this.$props.form.noBonus = true;
              } else {
                this.ratio = 0.3;
              }
            });
          }
        }
      });
    });
  }
}
