import { cloneDeep } from 'lodash';
import { getFullName } from 'root/helpers';
import {
  bonusFormDefault, contractFormDefault, publicCandidateFormDefault,
  salaryFormDefault, workingPositionFormDefault, EmployeeStatus
} from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { getFirebaseApp, uploadToFirebase } from 'root/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ActionTypeProfile } from '../../ProfileStore';
import { ActionTypeUser } from '../../Store';
import { PublicCandidateForm } from '../PublicCandidateForm';
import { ProfileBonusForm } from './profileBonusForm';
import { ProfileContractForm } from './profileContractForm';
import { ProfileDocumentViewer } from './profileDocumentViewer';
import { ProfileSalaryForm } from './profileSalaryForm';
import { ProfileWorkForm } from './profileWorkForm';
import './styles.scss';

@Component({
  template: require('./view.html'),
  data() {
    return {
      publicForm: publicCandidateFormDefault(),
      workForm: workingPositionFormDefault(),
      contractForm: contractFormDefault(),
      salaryForm: salaryFormDefault(),
      bonusForm: bonusFormDefault(),
      contractStatus: {
        generated: false,
        signed: 1
      }
    };
  },
  props: {
    visible: Boolean
  },
  components: {
    'public-user-info-form': PublicCandidateForm,
    'profile-contract-form': ProfileContractForm,
    'profile-work-form': ProfileWorkForm,
    'profile-salary-form': ProfileSalaryForm,
    'profile-bonus-form': ProfileBonusForm,
    'profile-document-viewer': ProfileDocumentViewer,
  },
  beforeRouteLeave(_, __, next) {
    const res = confirm('Chuyển trang ngay bây giờ? Lưu ý mất dữ liệu!');
    if (res) {
      window.removeEventListener('beforeunload', this.listener);
      next();
    } else {
      next(false);
    }
  }
})
export class CandidateEditForm extends Vue {
  public formIsActive: string = '1';
  public profileId: string = '';
  public progress: any = {};
  public loading: boolean = false;
  public status: string = '';
  public startDate: string = '';
  public storageRef = getFirebaseApp().storage().ref();
  public tree: string[] = ['company', 'division', 'center', 'department',
    'unit', 'team', 'level', 'position'];

  public mounted() {
    this.$nextTick(() => {
      window.addEventListener('beforeunload', this.listener);
      this.$store.dispatch(ActionTypeProfile.ProfileFilterNoCache, {
        params: {
          filterParams: {
            userId: this.$route.params.id
          },
          onSuccess: (res) => {
            res[0].sameAddr = false;
            document.title = `FE CREDIT - ${getFullName(res[0])}`;
            this.profileId = res[0].id;
            this.startDate = res[0].startDate;
            this.progress = { ...res[0].progress };
            this.status = res[0].employeeStatus;
            const forms = ['publicForm', 'workForm', 'contractForm', 'salaryForm', 'bonusForm'];
            forms.forEach((name) => {
              Object.keys(this.$data[name]).forEach((key) => {
                if ((res[0][key] || typeof res[0][key] === 'boolean')
                  && res[0][key] !== this.$data[name][key]) {
                  this.$set(this.$data[name], key, res[0][key]);
                }
              });
            });
          }
        }
      });
      this.$store.dispatch(ActionTypeUser.UserFilterChange, {
        params: {
          id: this.$route.params.id
        }
      });
    });
  }

  public listener(e) {
    const confirmationMessage = 'You have unsaved changes';

    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  }

  public async saveForm() {
    try {
      let submitForm: any;
      this.loading = true;
      switch (this.formIsActive) {
      case '1':
        // tslint:disable-next-line: max-line-length
        let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.$data.publicForm.email && !pattern.test(this.$data.publicForm.email)) {
          this.$message.error(this.$t('invalid_email').toString());
          this.loading = false;

          return;
        }
        pattern = /^[0-9]*$/;
        if (this.$data.publicForm.idNo && this.$data.publicForm.idNo.length !== 9
          && this.$data.publicForm.idNo.length !== 12 || this.$data.publicForm.oldIdNo
          && this.$data.publicForm.oldIdNo.length !== 9 && this.$data.publicForm.oldIdNo.length !== 12
          || this.$data.publicForm.idNo && !pattern.test(this.$data.publicForm.idNo)
          || this.$data.publicForm.oldIdNo && !pattern.test(this.$data.publicForm.oldIdNo)) {
          this.$message.error(this.$t('invalid_id').toString());
          this.loading = false;

          return;
        }
        if (this.$data.publicForm.bhxhPaperNo && !pattern.test(this.$data.publicForm.bhxhPaperNo)) {
          this.$message.error(this.$t('invalid_bhxh_paper_no').toString());
          this.loading = false;

          return;
        }
        pattern = /^[0-9]+\s*$/;
        if (this.$data.publicForm.phone && (this.$data.publicForm.phone.length !== 10
          || !pattern.test(this.$data.publicForm.phone)) || this.$data.publicForm.homePhone &&
          (this.$data.publicForm.homePhone.length !== 10 || !pattern.test(this.$data.publicForm.homePhone))
          || this.$data.publicForm.urgentContactPhone &&
          (this.$data.publicForm.urgentContactPhone.length !== 10 ||
          !pattern.test(this.$data.publicForm.urgentContactPhone))) {
          this.$message.error(this.$t('invalid_phone_number').toString());
          this.loading = false;

          return;
        }
        const date = new Date(this.$data.publicForm.dob);
        date.setFullYear(date.getFullYear() + 15);
        const now = new Date();
        if (this.$data.publicForm.dob && date.getTime() > now.getTime()) {
          this.$message.error(this.$t('invalid_dob').toString());
          this.loading = false;

          return;
        }
        const check = await this.checkEmailExisted();
        if (!check) {
          this.loading = false;
          this.$message.error(this.$t('email_existed').toString());
          this.loading = false;

          return;
        }
        for (const rec of this.$data.publicForm.familyMembers) {
          pattern = /^[0-9]*$/;
          if (!pattern.test(rec['dob'].trim())) {
            this.$message.error(this.$t('Năm sinh người thân chỉ được nhập số').toString());
            this.loading = false;

            return;
          }
          pattern = /^[0-9]+\s*$/;
          // tslint:disable-next-line: early-exit
          if (rec['phone'].trim().length !== 10 || !pattern.test(rec['phone'].trim())) {
            this.$message.error(this.$t('Số điện thoại người thân không hợp lệ').toString());
            this.loading = false;

            return;
          }
        }
        const s1: string[] = ['gender', 'dob', 'pob', 'nationality', 'maritalStatus', 'religion',
          'ethnic', 'email', 'phone', 'academicLevel', 'localExpat', 'qualifications',
          'urgentContactName', 'urgentContactPhone', 'urgentContactRelationship'];
        submitForm = cloneDeep(this.$data.publicForm);
        s1.forEach((field) => {
          if (!submitForm[field]) {
            submitForm.progress.personalInfo = false;
          }
        });
        submitForm.familyMembers.forEach((item) => delete item.focus);
        submitForm.degrees.forEach((item) => delete item.focus);
        submitForm.certificates.forEach((item) => delete item.focus);
        submitForm.jobs.forEach((item) => delete item.focus);
        submitForm.referrers.forEach((item) => delete item.focus);
        if (submitForm.sameAddr) {
          submitForm.tCity = submitForm.pCity;
          submitForm.tDistrict = submitForm.pDistrict;
          submitForm.tWard = submitForm.pWard;
          submitForm.tempAddr = submitForm.permAddr;
        }
        if (submitForm.oldAvatar) {
          await new Promise((resolve) => {
            getFirebaseApp().storage().refFromURL(submitForm.oldAvatar).delete()
              .then(() => {
                resolve();
              })
              .catch((err) => {
                console.log(err);
                resolve();
              });
          });
          delete submitForm.oldAvatar;
          this.$data.publicForm.oldAvatar = '';
        }
        if (submitForm.avatar && submitForm.avatar.name) {
          const url = await uploadToFirebase(submitForm.avatar, `${this.$route.params.id}/images/`,
            null, 'Hinh_dai_dien');
          submitForm.avatar = url;
          this.$set(this.$data.publicForm, 'avatar', url);
        }
        if (submitForm.oldIdImg) {
          await this.deleteOnFirebase(submitForm, 'oldIdImg');
          delete submitForm.oldIdImg;
          this.$data.publicForm.oldIdImg = [];
        }
        if (submitForm.rawIdImg) {
          const dict = await this.getAvailableNames(submitForm, 'IdImg', 2, 'CMND');
          await this.uploadImages(submitForm, 'IdImg', 'rawIdImg', dict);
          this.$set(this.$data.publicForm, 'IdImg', submitForm.IdImg);
          delete submitForm.rawIdImg;
          this.$data.publicForm.rawIdImg = {};
        }
        if (submitForm.oldhouseHoldImg) {
          await this.deleteOnFirebase(submitForm, 'oldhouseHoldImg');
          delete submitForm.oldhouseHoldImg;
          this.$data.publicForm.oldhouseHoldImg = [];
        }
        if (submitForm.rawhouseHoldImg) {
          const dict = await this.getAvailableNames(submitForm, 'houseHoldImg', 20, 'Ho_khau');
          await this.uploadImages(submitForm, 'houseHoldImg', 'rawhouseHoldImg', dict);
          this.$set(this.$data.publicForm, 'houseHoldImg', submitForm.houseHoldImg);
          delete submitForm.rawhouseHoldImg;
          this.$data.publicForm.rawhouseHoldImg = {};
        }
        if (submitForm.olddegreeImg) {
          await this.deleteOnFirebase(submitForm, 'olddegreeImg');
          delete submitForm.olddegreeImg;
          this.$data.publicForm.olddegreeImg = [];
        }
        if (submitForm.rawdegreeImg) {
          const dict = await this.getAvailableNames(submitForm, 'degreeImg', 5, 'Bang_cap');
          await this.uploadImages(submitForm, 'degreeImg', 'rawdegreeImg', dict);
          this.$set(this.$data.publicForm, 'degreeImg', submitForm.degreeImg);
          delete submitForm.rawdegreeImg;
          this.$data.publicForm.rawdegreeImg = {};
        }
        if (submitForm.oldcertificateImg) {
          await this.deleteOnFirebase(submitForm, 'oldcertificateImg');
          delete submitForm.oldcertificateImg;
          this.$data.publicForm.oldcertificateImg = [];
        }
        if (submitForm.rawcertificateImg) {
          const dict = await this.getAvailableNames(submitForm, 'certificateImg', 5, 'Chung_chi');
          await this.uploadImages(submitForm, 'certificateImg', 'rawcertificateImg', dict);
          this.$set(this.$data.publicForm, 'certificateImg', submitForm.certificateImg);
          delete submitForm.rawcertificateImg;
          this.$data.publicForm.rawcertificateImg = {};
        }
        if (submitForm.oldhealthImg) {
          await this.deleteOnFirebase(submitForm, 'oldhealthImg');
          delete submitForm.oldhealthImg;
          this.$data.publicForm.oldhealthImg = [];
        }
        // tslint:disable-next-line: early-exit
        if (submitForm.rawhealthImg) {
          const dict = await this.getAvailableNames(submitForm, 'healthImg', 20, 'Giay_kham_suc_khoe');
          await this.uploadImages(submitForm, 'healthImg', 'rawhealthImg', dict);
          this.$set(this.$data.publicForm, 'healthImg', submitForm.healthImg);
          delete submitForm.rawhealthImg;
          this.$data.publicForm.rawhealthImg = {};
        }
        break;
      case '2':
        submitForm = cloneDeep(this.$data.workForm);
        delete submitForm.locked;
        delete submitForm.tempObj;
        delete submitForm.loading;
        delete submitForm.level;
        delete submitForm.position;
        submitForm.company = submitForm.company.split('_')[0];
        submitForm.division = submitForm.division ? submitForm.division.split('_')[0] : '';
        submitForm.center = submitForm.center ? submitForm.center.split('_')[0] : '';
        submitForm.department = submitForm.department ? submitForm.department.split('_')[0] : '';
        submitForm.unit = submitForm.unit ? submitForm.unit.split('_')[0] : '';
        submitForm.team = submitForm.team ? submitForm.team.split('_')[0] : '';
        submitForm.positionLevel = submitForm.positionLevel ? submitForm.positionLevel.split('_')[0] : '';
        let required: string[] = ['startDate', 'company', 'changeType', 'pow',
          'positionCode', 'employeeType'];
        let flag = true;
        required.forEach((field) => {
          if (!submitForm[field]) {
            flag = false;
          }
        });
        if (this.startDate !== submitForm.startDate) {
          submitForm.signDate = submitForm.startDate;
          submitForm.effectiveDate = submitForm.startDate;
          this.$data.contractForm.effectiveDate = submitForm.startDate;
          submitForm.effDate = submitForm.startDate;
          this.$data.salaryForm.effDate = submitForm.startDate;
          submitForm.actualDate = submitForm.startDate;
          this.$data.salaryForm.actualDate = submitForm.startDate;
          if (!this.$data.contractForm.contractTemplate) {
            submitForm.dop = submitForm.startDate;
            this.$data.contractForm.dop = submitForm.startDate;
          } else {
            const contractTypeNames: string[] = [
              'TT_HDLDCTH_Sales_LamCa_KTV',
              'TT_HDLDKXD_KTV',
              'TT_HDLDTV',
              'TT_HDLDTV_Sales_Lamca',
              'TT_HDLDXD_Expat_KTV',
              'TT_HDLDXD_KTV',
              'TT_HDLDXD_Sales_LamCa_KTV',
              'TT_HDDV_VanPhong_CongNhat',
              'TT_HDDV_VanPhong',
              'TT_HDDVBH_CoDinh',
              'TT_HDDVBH_KhaBien'
            ];
            await new Promise((resolve, reject) => {
              this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFindById, {
                id: this.$data.contractForm.contractType,
                onSuccess: async (data: any) => {
                  if (!data[0]) {
                    return resolve();
                  }
                  if (!contractTypeNames.includes(data[0].name)) {
                    submitForm.dop = submitForm.startDate;
                    this.$data.contractForm.dop = submitForm.startDate;
                  }

                  return resolve();
                },
                onFailure: () => {
                  return reject();
                }
              });
            });
          }
        }
        if (submitForm.noBonus) {
          this.progress.bonus = true;
        } else {
          this.progress.bonus = this.$data.salaryForm.salaryTotal === '4,730,000' ||
            this.$data.bonusForm.bonuses.length;
        }
        delete submitForm.noBonus;
        this.progress.workingPosition = flag;
        submitForm['progress'] = this.progress;
        break;
      case '3':
        submitForm = cloneDeep(this.$data.contractForm);
        submitForm.probationPeriod = submitForm.probationPeriod || 0;
        required = submitForm.noExpiryDate ? ['contractType', 'effectiveDate'] :
          ['contractType', 'effectiveDate', 'expiryDate'];
        flag = true;
        required.forEach((field) => {
          if (!submitForm[field]) {
            flag = false;
          }
        });
        delete submitForm.noExpiryDate;
        this.progress.contract = flag;
        submitForm['progress'] = this.progress;
        break;
      case '4':
        submitForm = cloneDeep(this.$data.salaryForm);
        required = ['effDate', 'actualDate', 'salaryUnit', 'salaryTotal', 'salaryCurrency',
          'salaryHrisTotal', 'salaryHrisBase'];
        flag = true;
        required.forEach((field) => {
          if (!submitForm[field]) {
            flag = false;
          }
        });
        // tslint:disable-next-line: prefer-conditional-expression
        if (submitForm.salaryTotal === '4,730,000') {
          this.progress.bonus = true;
        } else {
          this.progress.bonus = submitForm.noBonus || this.$data.bonusForm.bonuses.length;
        }
        delete submitForm.noBonus;
        this.progress.salary = flag;
        submitForm['progress'] = this.progress;
        break;
      case '5':
        submitForm = cloneDeep(this.$data.bonusForm);
        submitForm.bonuses.forEach((item) => {
          delete item.focus;
          item.effectiveDate = item.signDate;
          item.group = item.group.split('_')[0];
        });
        this.progress.bonus = submitForm.bonuses.length ? true : false;
        if (submitForm.noBonusLevel || submitForm.noBonusSalary) {
          this.progress.bonus = true;
        }
        delete submitForm.positionCode;
        delete submitForm.noBonusSalary;
        delete submitForm.noBonusLevel;
        delete submitForm.salaryTotal;
        submitForm['progress'] = this.progress;
        break;
      case '6':
        this.progress.contractSigned = this.$data.contractStatus.signed === 1 ? false : true;
        submitForm = {
          progress: {
            ...this.progress
          },
          employeeStatus: this.progress.contractSigned ? EmployeeStatus.Active : this.status
        };
        break;
      default:
        break;
      }
      Object.keys(submitForm).forEach((key) => {
        submitForm[key] = typeof submitForm[key] === 'string' && submitForm[key] ?
          submitForm[key].trim() : submitForm[key];

        if (!['jobs', 'referrers', 'familyMembers', 'certificates', 'degrees', 'bonuses'].includes(key)) {
          return;
        }
        const newItems = [];
        submitForm[key].forEach((item) => {
          for (const k of Object.keys(item)) {
            if (k === 'focus' || !item[k]) {
              continue;
            }
            newItems.push(item);
            break;
          }
        });
        submitForm[key] = newItems;
      });

      this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
        id: this.profileId,
        form: {
          ...submitForm
        },
        onSuccess: (res) => {
          this.$set(this.$data.bonusForm, 'salaryTotal', res.salaryTotal);
          if (res.positionCode && res.positionCode !== this.$data.salaryForm.positionCode) {
            this.$set(this.$data.salaryForm, 'positionCode', res.positionCode);
            this.$set(this.$data.bonusForm, 'positionCode', res.positionCode);
          }
          if (res.positionLevel && res.positionLevel !== this.$data.publicForm.positionLevel) {
            this.$set(this.$data.publicForm, 'positionLevel', res.positionLevel);
          }
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id: this.$route.params.id,
            form: {
              fullName: getFullName(res),
              email: res.email,
              updatedAt: res.updatedAt
            },
            onSuccess: () => {
              this.loading = false;
              this.$message.success(this.$t('profile_saved_successfully').toString());
            },
            onFailure: (error) => {
              this.loading = false;
              this.$message.error(this.$t(error.message.substring(15)).toString());
            }
          });
        },
        onFailure: (error) => {
          this.loading = false;
          this.$message.error(this.$t(error.message.substring(15)).toString());
        }
      });
    } catch (error) {
      this.$message.error(this.$t(error.message.substring(15)).toString());
    }
  }

  private checkEmailExisted() {
    return new Promise((resolve, reject) => {
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          filterParams: {
            email: this.$data.publicForm.email
          },
          onSuccess: (res) => {
            if (!res[0] || res[0].profile === this.profileId) {
              return resolve(true);
            }

            return resolve(false);
          },
          onFailure: (error) => {
            reject(error);
          }
        }
      });
    });
  }

  private async deleteOnFirebase(form: any, field: string) {
    for (const img of form[field]) {
      await new Promise((resolve) => {
        getFirebaseApp().storage().refFromURL(img).delete()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.log(err);
            resolve();
          });
      });
    }
  }

  private async uploadImages(form: any, field: string, rawField: string, nameDict: string[]) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < form[field].length; ++i) {
      if (form[field][i][0] !== 'b') {
        continue;
      }
      const name = nameDict.pop();
      const url = await uploadToFirebase(form[rawField][form[field][i]],
        `${this.$route.params.id}/images/`, null, name);
      form[field][i] = url;
    }
  }

  private async getAvailableNames(form: any, field: string, limit: number, name: string): Promise<string[]> {
    const dict: string[] = [];
    const temp = {};
    for (const item of form[field]) {
      if (item[0] === 'b') {
        continue;
      }
      const metadata = await getFirebaseApp().storage().refFromURL(item).getMetadata();
      temp[metadata.name.split('.')[0]] = 1;
    }
    for (let i = limit; i > 0; --i) {
      if (!temp[`${name}_${i}`]) {
        dict.push(`${name}_${i}`);
      }
    }

    return dict;
  }
}
