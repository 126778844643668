import { IContractTemplate, ISignatory } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      signatory: (state: IState) => state.signatory.data[0],
      contract: (state: IState) => state.contractTemplate.data[0]
    })
  },
  watch: {
    $route(to, from) {
      this.active = from.path.split('/')[3] === 'signatory' ? '2' : '1';
      this.showForm = to.path.split('/')[2] === 'new' ? true : false;
    }
  }
})
export class ContractList extends Vue {
  public signatory: ISignatory;
  public contract: IContractTemplate;
  public showForm: boolean = false;
  public active: string = '1';
  public activeForm: string = '1';
  public tabNames: any = {
    1: 'Hợp đồng mẫu',
    template: 'Hợp đồng mẫu',
    2: 'Người ký',
    signatory: 'Người ký',
    3: '',
    'terms-conditions': 'Điều khoản hợp đồng',
    4: 'Điều khoản hợp đồng'
  };

  public createNew() {
    switch (this.activeForm) {
    case '1':
      this.$router.push('/contracts/new/template');
      break;
    case '2':
      this.$router.push('/contracts/new/signatory');
      break;
    case '4':
      this.$router.push('/contracts/new/terms-conditions');
      break;
    default:
      break;
    }
  }

  public getActiveForm(num: string) {
    this.activeForm = num;
  }
}
