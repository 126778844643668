export interface IReferenceRecord {
  id: string;
  name: string;
  code: string;
  tableName: string;
  superior: string;
  status: string;
  note: string;
}

export enum ReferenceTableName {
  DocumentType = 'Loại giấy tờ',
  RelationshipType = 'Mối quan hệ với chủ hộ',
  Gender = 'Giới tính',
  EmployeeType = 'Loại nhân viên',
  ChangeType = 'Hình thức thay đổi',
  WorkLocation = 'Địa điểm làm việc',
  BirthLocation = 'Nơi sinh',
  IDLocation = 'Nơi cấp CMND',
  Religion = 'Tôn giáo',
  MarriageStatus = 'Tình trạng hôn nhân',
  Nationality = 'Quốc tịch',
  Ethnicity = 'Dân tộc',
  LocalExpat = 'Local/ Expat',
  Literacy = 'Trình độ học vấn',
  CurrentContractType = 'Loại HĐ hiện tại',
  SalaryUnit = 'Đơn vị tính lương (Nhập lương)',
  SalaryCurrency = 'Loại tiền tệ (Nhập lương)',
  BonusCurrency = 'Đơn vị tính (Phụ cấp - Trợ cấp)',
  BonusGroup = 'Nhóm khoảng thêm (Phụ cấp - Trợ cấp)',
  BonusType = 'Mã khoản thêm lương',
  companyTable = 'MÃ CÔNG TY (HRIS)',
  divisionTable = 'MÃ KHỐI',
  centerTable = 'MÃ TRUNG TÂM',
  departmentTable = 'MÃ PHÒNG',
  unitTable = 'MÃ BỘ PHẬN',
  teamTable = 'MÃ NHÓM HRIS',
  positionTable = 'MÃ CHỨC DANH',
  WorkingTime = 'Thời gian làm việc',
  City = 'Tỉnh / Thành',
  AcademicLevel = 'Trình độ học vấn',
  Qualifications = 'Trình độ chuyên môn',
  levelTable = 'Level',
  ContractTemplate = 'Loại hợp đồng',
  BaseSalary = 'Mức lương sàn CB'
}
