import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { isArray } from 'lodash';
import moment from 'moment';
import { IProfile } from 'root/models';
import { ActionTypeReferenceRecord } from 'root/pages/Reference/RecordStore';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../../ProfileStore';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    userId: String
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.profile.data[0]
    })
  },
  watch: {
    data(val) {
      if (!this.firstLoad) {
        return;
      }
      this.parseData(val);
    }
  }
})

export class CandidateExportForm extends Vue {
  public data: IProfile;
  public $refs: {
    nodeToRenderAsPDF: HTMLDivElement,
    nodeToRenderAsPDFPageTwo: HTMLDivElement,
    nodeToRenderAsPDFPageThree: HTMLDivElement
  };
  public agree1 = false;
  public agree2 = false;
  public famLength = 0;
  public jobLength = 0;
  public refLength = 0;
  public page3: boolean = false;
  public firstLoad: boolean = true;
  public loading: boolean = false;
  public qualityRender: number = 1.5;
  public qualityImage: number = 0.5;
  public pagingParams: any = {
    limit: 10000,
    page: 1
  };
  public qualityOptions: any = [
    {
      value: 1.5,
      label: 'Chất lượng tiêu chuẩn'
    },
    {
      value: 2,
      label: 'Chất lượng cao'
    }
  ];

  public mounted() {
    this.$nextTick(() => {
      const userId = this.$route.params.id || this.$props.userId;
      this.$store.dispatch(ActionTypeProfile.ProfileFilterNoCache, {
        params: {
          filterParams: {
            userId
          },
          onSuccess: (res) => {
            if (!res[0]) {
              return;
            }
            document.title = `FE CREDIT - ${this.getFullName(res[0])}`;
          }
        }
      });
    });
  }

  public async handleExportPDF() {
    const filename = `Ho_so_ung_vien_${this.getFullName(this.data, '_')}.pdf`;
    window.scrollTo(0, 0);
    try {
      this.loading = true;
      const page1 = await html2canvas(this.$refs.nodeToRenderAsPDF, {
        scale: this.qualityRender,
        svgRendering: true,
        removeContainer: true
      });
      const pdf = new jsPDF('p', 'in', 'a4');
      const { width, height } = pdf.internal.pageSize;
      pdf.addImage(page1.toDataURL('image/jpeg', this.qualityImage), 'JPEG', 0, 0, width, height);

      // page 2
      pdf.addPage();
      const page2 = await html2canvas(this.$refs.nodeToRenderAsPDFPageTwo, {
        scale: this.qualityRender,
        allowTaint: true,
        letterRendering: true
      });
      pdf.addImage(page2.toDataURL('image/jpeg', this.qualityImage), 'JPEG', 0, 0, width, height);
      if (this.page3) {
        // page 3
        pdf.addPage();
        const page3 = await html2canvas(this.$refs.nodeToRenderAsPDFPageThree, {
          scale: this.qualityRender,
          allowTaint: true,
          letterRendering: true
        });
        pdf.addImage(page3.toDataURL('image/jpeg', this.qualityImage), 'JPEG', 0, 0, width, height);
      }

      await pdf.save(filename, {
        returnPromise: true
      });
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  public getFullName(obj: any, del: string = ' ') {
    if (!obj) {
      return '';
    }
    if (!obj.middleName) {
      return `${obj.lastName}${del}${obj.firstName}`;
    }

    return `${obj.lastName}${del}${obj.middleName}${del}${obj.firstName}`;
  }

  public getDate(date: string, isFull: boolean = true) {
    if (!date) {
      return '';
    }
    if (!isFull) {
      return moment(new Date(date)).format('MM/YYYY');
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  public getYear(date: string) {
    const tmp = new Date(date);

    return tmp.getFullYear();
  }

  public getAddress(obj: any, perm: boolean) {
    if (!obj) {
      return;
    }
    if (perm && !(obj.pCity && obj.pDistrict && obj.pWard)) {
      return;
    }
    if (!perm && !(obj.tCity && obj.tDistrict && obj.tWard)) {
      return;
    }
    if (perm) {
      const addr1 = obj.permAddr ? `${obj.permAddr}, ` : '';

      return addr1 + `${obj.pWard}, ${obj.pDistrict}, ${obj.pCity}`;
    }
    const addr = obj.tempAddr ? `${obj.tempAddr}, ` : '';

    return addr + `${obj.tWard}, ${obj.tDistrict}, ${obj.tCity}`;
  }

  public parseData(val) {
    this.famLength = val.familyMembers.length || 0;
    this.jobLength = val.jobs.length || 0;
    this.refLength = val.referrers.length || 0;
    this.firstLoad = false;
    const ids = [];
    Object.keys(val).forEach((key) => {
      if (!(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(val[key]))) {
        return;
      }
      ids.push(val[key]);
    });
    if (!ids.length) {
      return;
    }
    this.$store.dispatch(ActionTypeReferenceRecord.ReferenceRecordFilterNoCache, {
      params: {
        pagingParams: this.pagingParams,
        filterParams: {
          id: ids
        },
        onSuccess: (res) => {
          if (!res[0]) {
            return;
          }
          const dict = {};
          res.map((r) => { dict[r.id] = r.name; });
          Object.keys(val).forEach((key) => {
            val[key] = dict[val[key]] ? dict[val[key]] : val[key];
          });
          this.pageBreak();
        }
      }
    });
  }

  private pageBreak() {
    for (let i = 0; i < 50; ++i) {
      const prefix = i < this.jobLength ? 'T' : 'P';
      if (!this.$refs[`${prefix}2${i}`]) {
        continue;
      }
      const el = isArray(this.$refs[`${prefix}2${i}`]) ? this.$refs[`${prefix}2${i}`][0] : this.$refs[`${prefix}2${i}`];
      const el2 = isArray(this.$refs[`${prefix}3${i}`]) ? this.$refs[`${prefix}3${i}`][0] :
        this.$refs[`${prefix}3${i}`];
      if (el.getBoundingClientRect().top - this.$refs['topPage'].getBoundingClientRect().top < 1000) {
        el2.parentNode.removeChild(el2);
        continue;
      }
      this.page3 = true;
      el.parentNode.removeChild(el);
    }
  }
}
