import { IProfile } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';
// import { MutationTypeProfile } from './types';

export interface IProfileState extends ICRUDState<IProfile> {
}

export const defaultState: IProfileState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('profile')
};
