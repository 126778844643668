import { IShortcut } from 'root/models';
import { crudMutations, CRUDState, ICRUDState } from 'root/store/helpers';

export interface IShortcutState extends ICRUDState<IShortcut> {
}

export const defaultState: IShortcutState = {
  ...CRUDState(),
};

export const mutations = {
  ...crudMutations('shortcut')
};
