import { parseParamUrl } from 'root/helpers';
import { IPagingParams, IPagingResult } from 'root/models';
import { IHttpService, IHttpServiceClientOptions } from './base';

export interface ICRUDHttpService<M, F = any, C = any, U = any> {
  filter(params?: F, pagingParams?: IPagingParams, opts?: IOptionsHTTP): Promise<IPagingResult<M>>;
  getById(id: string | number, opts?: IOptionsHTTP): Promise<M>;
  create(form: C, opts?: IOptionsHTTP): Promise<M>;
  update(id: string | number, form: U, opts?: IOptionsHTTP): Promise<M>;
  deleteById(id: string | number, opts?: IOptionsHTTP): Promise<any>;
  list(opts?: IOptionsHTTP): Promise<any>;
}

export interface IOptionsHTTP {
  url: object;
}

export interface ICRUDHttpServiceOptions extends IHttpServiceClientOptions {
  basePath: string;
  httpService: IHttpService;
}

export function CRUDHttpService<M, F = any, C = any, U = any>(
  options: ICRUDHttpServiceOptions
): ICRUDHttpService<M, F, C, U> {

  const { basePath, httpService, mock } = options;
  const baseURL = httpService.getUrl(basePath, mock);

  function filter(params?: F, pagingParams?: IPagingParams, opts?: IOptionsHTTP): Promise<IPagingResult<M>> {
    return httpService.get(parseParamUrl(baseURL, opts ? opts.url : null), {
      params: {
        ...<any> params,
        ...pagingParams,
        order: !pagingParams || !pagingParams.order ? undefined
          : (pagingParams.descending ? '-' + pagingParams.order : pagingParams.order)
      }
    });
  }

  function getById(id: string | number, opts?: IOptionsHTTP): Promise<M> {
    return httpService.get(`${parseParamUrl(baseURL, opts ? opts.url : null)}/${id}`);
  }

  function list(opts?: IOptionsHTTP): Promise<M[]> {
    return httpService.get(parseParamUrl(baseURL, opts ? opts.url : null));
  }

  function create(form: C, opts?: IOptionsHTTP): Promise<M> {
    return httpService.post(parseParamUrl(baseURL, opts ? opts.url : null), form);
  }

  function update(id: string | number, form: U, opts?: IOptionsHTTP): Promise<M> {
    return httpService.put(`${parseParamUrl(baseURL, opts ? opts.url : null)}/${id}`, form);
  }

  function deleteById(id: string | number, opts?: IOptionsHTTP): Promise<any> {
    return httpService.del(`${parseParamUrl(baseURL, opts ? opts.url : null)}/${id}`);
  }

  return {
    filter,
    getById,
    create,
    update,
    deleteById,
    list
  };
}
