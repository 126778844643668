import { HPDropdown, HPSearch } from 'root/components';
import { IUser, Permissions } from 'root/models';
import { IState } from 'root/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeUser } from '../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  components: {
    'hp-search': HPSearch,
    'hp-dropdown': HPDropdown
  },
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data[0],
      pagination: (state: IState) => state.user.pagination
    })
  },
  watch: {
    $route(to) {
      this.addNew = to.path === '/users/new' ? true : false;
      this.modify = to.path.split('/')[2] === 'edit' ? true : false;
    }
  }
})
export class StaffList extends Vue {
  public addNew: boolean = false;
  public modify: boolean = false;
  public data: IUser;

  public filters: any = {
    status: 'all',
    permission: 'all'
  };
  public statuses: any[] = [
    {
      value: 'Tất cả',
      key: 'all'
    },
    {
      value: 'Hoạt động',
      key: 'active'
    },
    {
      value: 'Đã khóa',
      key: 'locked'
    }
  ];
  public permissions: any[] = [
    {
      value: 'Tất cả',
      key: 'all'
    },
    {
      value: 'Quản trị viên',
      key: 'adminuser'
    },
    {
      value: 'Biên tập viên',
      key: 'user'
    }
  ];

  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    this.filters[actions[4]] = actions[0];

    return;
  }

  public handleFilter() {
    const filter1: any = this.filters.permission !== 'all' ?
      { role: { _eq: this.filters.permission } } : {};
    const filter2: any = this.filters.status !== 'all' ?
      (this.filters.status === 'active' ?
      { lockedAccess: { _eq: false } } :
      { lockedAccess: { _eq: true } }) : {};
    this.$store.dispatch(ActionTypeUser.UserFilterChange, {
      params: {
        _and: [
          { _or: [
              { role: { _eq: Permissions.Admin }},
              { role: { _eq: Permissions.Staff }}
          ]},
          { ...filter1 },
          { ...filter2 }
        ]
      }
    });
  }

  public handleSearch(keyWord: string) {
    this.$store.dispatch(ActionTypeUser.UserFilterChange, {
      params: {
        fullName: {
          searchMode: true,
          text: keyWord
        }
      }
    });
  }

  public createNewSystemUser() {
    this.$router.push('/users/new');
  }
}
