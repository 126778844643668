import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { IPaginationParams } from 'root/api/graphql/Core';
import { HPDropdown } from 'root/components';
import { HPPage } from 'root/components/HPPage';
import { confirmAction, ruleRequired } from 'root/helpers';
import { EmployeeStatus, IUser, Permissions } from 'root/models';
import { getFirebaseApp } from 'root/services';
import { IState } from 'root/store';
import saveAs from 'save-as';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import { ActionTypeProfile } from '../../ProfileStore';
import { ActionTypeUser } from '../../Store';
import './styles.scss';

@Component({
  template: require('./view.html'),
  computed: {
    ...mapState({
      data: (state: IState) => state.user.data,
      loading: (state: IState) => state.user.loading,
      pagination: (state: IState) => state.user.pagination,
      authUser: (state: IState) => state.global.authUser
    })
  },
  watch: {
    authUser(val) {
      const filter: any = {
        role: 'guest',
      };
      if (val.role !== Permissions.SuperAdmin &&
        val.role !== Permissions.Admin) {
        filter.createdBy = val.id;
      }
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          pagingParams: {
            limit: 10,
            page: 1
          },
          filterParams: filter
        }
      });
    }
  },
  components: {
    'hp-dropdown': HPDropdown,
    'hp-page': HPPage
  }
})

export class CandidateTable extends Vue {
  public authUser: any;
  public pagination: IPaginationParams;
  public data: IUser[];
  public width: number = window.screen.width;
  public form: any = { newStatus: '' };
  public showForm: boolean = false;
  public changeId: number = 0;
  public listOption: number = 10;
  public listOptions: any[] = [
    { value: '10 kết quả mỗi trang', key: 10 },
    { value: '20 kết quả mỗi trang', key: 20 },
    { value: '50 kết quả mỗi trang', key: 50 }
  ];
  public statusOptions: any[] = [
    { label: 'Đã từ chối', value: EmployeeStatus.Rejected },
    { label: 'Đã nghỉ việc', value: EmployeeStatus.Quit }
  ];
  public get rules() {
    return {
      required: ruleRequired()
    };
  }
  public tags = {
    [EmployeeStatus.Active]: { name: 'Hoạt động', type: 'success' },
    [EmployeeStatus.New]: { name: 'Mới', type: '' },
    [EmployeeStatus.Quit]: { name: 'Đã nghỉ việc', type: 'info' },
    [EmployeeStatus.Rejected]: { name: 'Đã từ chối', type: 'danger' },
    [EmployeeStatus.Rehire]: { name: 'Ứng tuyển lại', type: 'warning' }
  };
  public actionOptions: any[] = [
    {
      value: 'Xem ứng viên',
      key: 'detail',
      icon: 'el-icon-view'
    },
    {
      value: 'Liên kết công khai',
      key: 'public',
      icon: 'el-icon-link'
    },
    {
      value: 'Tải hình ảnh',
      key: 'download',
      icon: 'el-icon-download'
    },
    {
      value: 'Tải hợp đồng',
      key: 'contract',
      icon: 'el-icon-document-checked'
    },
    {
      value: 'Xuất PDF',
      key: 'export',
      icon: 'el-icon-document'
    },
    {
      value: 'Xoá ứng viên',
      key: 'delete',
      icon: 'el-icon-circle-close'
    }
  ];

  public getActionOptions(candidate: any) {
    if (!candidate.profileOfUser) {
      return [];
    }
    const options = [ ...this.actionOptions ];
    if (!candidate.lockedAccess) {
      options.push({
        value: 'Khoá truy cập',
        key: 'lock',
        icon: 'el-icon-lock'
      });
    } else {
      options.push({
        value: 'Mở khoá truy cập',
        key: 'unlock',
        icon: 'el-icon-unlock'
      });
    }
    if ([EmployeeStatus.Rejected, EmployeeStatus.Quit].includes(candidate.profileOfUser.employeeStatus) &&
      !candidate.profileOfUser.isRehired) {
      options.push({
        value: 'Ứng tuyển lại',
        key: 'rehire',
        icon: 'el-icon-s-flag'
      });
    } else {
      options.push({
        value: 'Chuyển trạng thái',
        key: 'change',
        icon: 'el-icon-refresh'
      });
    }
    // tslint:disable-next-line: early-exit
    if (candidate.profileOfUser && candidate.profileOfUser.progress.personalInfo &&
      !candidate.profileOfUser.progress.hrisImported) {
      options.push({
        value: 'Mở chỉnh sửa',
        key: 'edit',
        icon: 'el-icon-edit'
      });
    }

    return options;
  }

  public mounted() {
    window.addEventListener('resize', () => {
      this.width = window.screen.width;
    });
    if (!this.$store.state.global.authUser) {
      return;
    }
    const filter: any = {
      role: 'guest',
    };
    if (this.$store.state.global.authUser.role !== Permissions.SuperAdmin
      && this.$store.state.global.authUser.role !== Permissions.Admin) {
      filter.createdBy = this.$store.state.global.authUser.id;
    }
    this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
      params: {
        pagingParams: {
          limit: 10,
          page: 1
        },
        filterParams: filter
      }
    });
  }

  public formatDate(date: string) {
    if (!date) {
      return '';
    }

    return moment(new Date(date)).format('DD/MM/YYYY');
  }

  public get fields(): string[] {
    const form = cloneDeep(this.data[0]);

    return Object.keys(form);
  }

  public changeIndex(id: number) {
    return (this.pagination.page - 1) * this.pagination.limit + id + 1;
  }

  public async handleCommand(model: string) {
    const actions: string[] = model.split('_');
    if (actions[2] === 'filter') {
      this.listOption = parseInt(actions[0], 10);
      this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
        params: {
          pagingParams: {
            limit: this.listOption,
            page: 1
          }
        }
      });

      return;
    }
    const index = actions[1];
    const id = this.data[index].id;
    const profileId = this.data[index].profile;

    switch (actions[0]) {
    case 'public':
      const routeData = this.$router.resolve({
        path: `/public?token=${this.data[index].publicToken}`
      });
      window.open(routeData.href, '_blank');
      break;
    case 'rehire':
      confirmAction(this, {
        title: 'Tạo lại hồ sơ ứng tuyển',
        message: `Xác nhận tạo lại hồ sơ ứng tuyển cho ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$router.push(`/candidates/rehire/${profileId}`);
        }
      });
      break;
    case 'change':
      this.changeId = parseInt(index, 10);
      this.showForm = true;
      break;
    case 'edit':
      confirmAction(this, {
        title: 'Mở chỉnh sửa ứng viên',
        message: `Xác nhận mở chỉnh sửa cho ứng viên ${this.data[index].fullName}?`,
        handleFunction: () => {
          const temp = this.data[index];
          temp.profileOfUser.progress.personalInfo = false;
          this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
            id: profileId,
            form: {
              progress: temp.profileOfUser.progress
            },
            onSuccess: () => {
              this.$message.success(this.$t('reopen_successfully').toString());
              this.data.splice(parseInt(index, 10), 1, temp);
            },
            onFailure: () => {
              this.$message.error(this.$t('cannot_reopen').toString());
            }
          });
        }
      });
      break;
    case 'delete':
      confirmAction(this, {
        title: 'Xoá ứng viên',
        message: `Xác nhận xoá ứng viên ${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeUser.UserDelete, {
            id,
            onSuccess: () => {
              this.$store.dispatch(ActionTypeProfile.ProfileDelete, {
                id: profileId,
                onSuccess: () => {
                  this.$message.success(this.$t('candidate_deleted_successfully').toString());
                },
                onFailure: () => {
                  this.$message.error(this.$t('cannot_delete_candidate_profile').toString());
                }
              });
            },
            onFailure: () => {
              this.$message.error(this.$t('cannot_delete_candidate').toString());
            }
          });
        }
      });
      break;
    case 'lock':
      confirmAction(this, {
        title: 'Khoá truy cập',
        message: `Xác nhận khoá liên kết công khai của người dùng ` +
          `${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id,
            form: {
              lockedAccess: true
            },
            onSuccess: () => {
              this.$message.success(this.$t('access_locked_successfully').toString());
            },
            onFailure: () => {
              this.$message.error(this.$t('cannot_lock_access').toString());
            }
          });
        }
      });
      break;
    case 'unlock':
      confirmAction(this, {
        title: 'Mở khoá truy cập',
        message: `Xác nhận mở khoá liên kết công khai của người dùng ` +
          `${this.data[index].fullName}?`,
        handleFunction: () => {
          this.$store.dispatch(ActionTypeUser.UserUpdate, {
            id,
            form: {
              lockedAccess: false
            },
            onSuccess: () => {
              this.$message.success(this.$t('access_unlocked_successfully').toString());
            },
            onFailure: () => {
              this.$message.error(this.$t('cannot_unlock_access').toString());
            }
          });
        }
      });
      break;
    case 'download':
      this.$message.success(this.$t('preparing_images').toString());
      let zip = new JSZip();
      let links = [];
      let count = 0;
      let zipFilename = `${this.data[index].fullName.replace(/ /g, '_')}.zip`;
      let storageRef = getFirebaseApp().storage().ref();
      let refs = await storageRef.child(`${id}/images`).listAll();
      for (const imageRef of refs.items) {
        const url = await imageRef.getDownloadURL();
        links.push({
          url,
          name: imageRef.name
        });
      }
      if (!links.length) {
        this.$message.error(this.$t('no_image').toString());

        return;
      }
      links.forEach((link) => {
        JSZipUtils.getBinaryContent(link.url, (err, data) => {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(link.name, data, { binary: true });
          count++;
          if (count !== links.length) {
            return;
          }
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, zipFilename);
            this.$message.success(this.$t('downloaded_images_successfully').toString());
          });
        });
      });
      break;
    case 'contract':
      this.$message.success(this.$t('preparing_contracts').toString());
      zip = new JSZip();
      links = [];
      count = 0;
      zipFilename = `${this.data[index].fullName.replace(/ /g, '_')}_HĐ_QĐ_BMTT.zip`;
      storageRef = getFirebaseApp().storage().ref();
      refs = await storageRef.child(`${id}/contracts`).listAll();
      for (const imageRef of refs.items) {
        const url = await imageRef.getDownloadURL();
        links.push({
          url,
          name: imageRef.name
        });
      }
      if (!links.length) {
        this.$message.error(this.$t('no_file').toString());

        return;
      }
      links.forEach((link) => {
        JSZipUtils.getBinaryContent(link.url, (err, data) => {
          if (err) {
            throw err; // or handle the error
          }
          zip.file(link.name, data, { binary: true });
          count++;
          if (count !== links.length) {
            return;
          }
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, zipFilename);
            this.$message.success(this.$t('downloaded_files_successfully').toString());
          });
        });
      });
      break;
    case 'export':
      this.$router.push(`/candidates/export/${id}`);
      break;
    case 'detail':
      const routeDetail = this.$router.resolve({
        path: `/candidates/edit/${id}`
      });
      window.open(routeDetail.href, '_blank');
      break;
    default:
      break;
    }
  }

  public goToPage(num: number) {
    this.$store.dispatch(ActionTypeUser.UserFilterNoCache, {
      params: {
        pagingParams: {
          limit: this.pagination.limit,
          page: num
        }
      }
    });
  }

  public sortTable(params: any) {
    switch (params.prop) {
    case 'startDate':
      this.$store.dispatch(ActionTypeUser.UserOrderChange, {
        params: {
          profileOfUser: {
            startDate: params.order === 'descending' ? 'desc' : 'asc'
          }
        }
      });
      break;
    default:
      break;
    }
  }

  public submit() {
    this.$store.dispatch(ActionTypeProfile.ProfileUpdate, {
      id: this.data[this.changeId].profile,
      form: {
        employeeStatus: this.form.newStatus
      },
      onSuccess: (res) => {
        this.$message.success(this.$t('status_changed_successfully').toString());
        const temp = this.data[this.changeId];
        temp['profileOfUser'].employeeStatus = res.employeeStatus;
        this.data.splice(this.changeId, 1, temp);
        this.form.newStatus = '';
        this.showForm = false;
      },
      onFailure: (error) => {
        console.log(error);
        this.$message.error(this.$t('cannot_change_status').toString());
      }
    });
  }

  public getFullName(obj: any) {
    if (!obj) {
      return '';
    }
    if (!obj.middleName) {
      return `${obj.lastName} ${obj.firstName}`;
    }

    return `${obj.lastName} ${obj.middleName} ${obj.firstName}`;
  }
}
