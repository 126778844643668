import Vue from 'vue';
import Component from 'vue-class-component';
import './styles.scss';

@Component({
  template: require('./view.html'),
  props: {
    catagory: String,
    action: String,
    title: String,
    items: Array,
    id: String,
    scale: Boolean
  }
})
export class HPDropdown extends Vue {
  public titletxt = '';
  public width: number = window.screen.width;
  public mounted() {
    window.addEventListener('resize', () => {
      this.width = window.screen.width;
    });
  }
  public handleCommand(model: string) {
    const actions: string[] = model.split('_');
    if (actions[2] === 'filter') {
      this.titletxt = actions[3];
    }
    this.$emit('handleCommand', model);
  }
}
